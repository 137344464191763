import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import BranchForm from "../../Branch/Component/Form";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import {BootstrapTabContainer, BootstrapTabContent,} from "../../../../../CommonElements/BootstrapTabs";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import {Breadcrumbs, H5, H6, LI, P, UL} from "../../../../../AbstractElements";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import {Mail, Map, Phone} from "react-feather";
import ProfilePicture from "../../../../CommonComponents/MultiMedia/ProfilePicture";
import ContactList from "../../../../SystemCore/Contact/Component/ContactList";
import {BranchType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";
import BranchCenters from "../Component/BranchCenters";
import BranchEmployees from "../Component/BranchEmployees";

interface branchShowProps {
}

const BranchShow = ({}: branchShowProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [branch, setBranch] = useState<BranchType>();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setLoaderVisibility(true);
        const config = {};
        axiosConfig
            .get(`${getApplicationRoute("branch")}/${params.branch}`, {config})
            .then((response: { data: { data: BranchType } }) => {
                if (response?.data?.data) {
                    setBranch(response.data.data);
                } else {
                    navigate(getApplicationRoute("branch"));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
                navigate(getApplicationRoute("branch"));
            });
    }, []);

    const deleteBranch = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`${getApplicationRoute("branch")}/${id}`, {})
            .then((response: { data: { data: BranchType } }) => {
                if (response?.data?.data) {
                    navigate(getApplicationRoute("branch"));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    return (
        <div>
            <Breadcrumbs
                mainTitle="Branch Profile"
                parent="Application"
                subParentLink={`${getApplicationRoute("branch")}`}
                subParent={"Branches"}
                title={branch?.branch_name}
            />
            <Container fluid={true}>
                <div className="user-profile">
                    <Loader visibility={loaderVisibility}/>
                    <Row>
                        <Col sm='12' lg='3'>
                            <Card className='hovercard text-center'>
                                <CardHeader className='social-profile'>
                                    <div className='d-flex align-items-center gap-2'>
                                    </div>
                                </CardHeader>
                                <div className='info'>
                                    <Row className='step3' data-intro='This is the your details'>
                                        <Col sm='12' lg='12' className='order-sm-0 order-xl-1'>
                                            <div className='user-designation'>
                                                <div className='title'>
                                                    <a>
                                                        {branch?.branch_name}
                                                    </a>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm='12' lg='12' className='order-sm-1 order-xl-0'>
                                            <Row>
                                                <Col>
                                                    <div className='ttl-info text-center'>
                                                        <H6>
                                                            {branch?.code}
                                                        </H6>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody>
                                    <div className='social-network theme-form'>
                                        <span className='f-w-600 text-danger'>Danger Zone</span>
                                        <ButtonIcon confirm={true} onclick={() => deleteBranch()} type={'delete'}/>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <div className='collection-filter-block'>
                                        <UL attrUL={{className: 'pro-services'}}>
                                            <LI attrLI={{className: 'border-0'}}>
                                                <div className='media'>
                                                    <Phone/>
                                                    <div className='media-body'>
                                                        <H5>{'Phone'}</H5>
                                                        <P>{branch?.phone ? branch?.phone : '-'}</P>
                                                    </div>
                                                </div>
                                            </LI>
                                            <LI attrLI={{className: 'border-0'}}>
                                                <div className='media'>
                                                    <Map/>
                                                    <div className='media-body'>
                                                        <H5>{'Address'}</H5>
                                                        <P>{branch?.address ? branch?.address : '-'}</P>
                                                    </div>
                                                </div>
                                            </LI>
                                            <LI attrLI={{className: 'border-0'}}>
                                                <div className='media'>
                                                    <Mail/>
                                                    <div className='media-body'>
                                                        <H5>{'Email'}</H5>
                                                        <P>{branch?.email ? branch.email : '-'}</P>
                                                    </div>
                                                </div>
                                            </LI>
                                        </UL>
                                    </div>
                                </CardBody>
                            </Card>
                            {
                                branch?.manager ? <Card className='hovercard text-center'>
                                    <CardHeader className='social-profile'>
                                        <div className='d-flex align-items-center gap-2'>
                                        </div>
                                    </CardHeader>
                                    <ProfilePicture/>
                                    <div className='info'>
                                        <Row>
                                            <Col sm='12' lg='12' className='order-sm-1 order-xl-0'>
                                                <Row>
                                                    <Col>
                                                        <div className='ttl-info text-center'>
                                                            <H6>
                                                                {branch?.manager?.name}
                                                            </H6>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card> : null
                            }

                        </Col>
                        <Col sm="12" lg="9">
                            <Card className='hovercard'>
                                <CardBody>
                                    <BootstrapTabContainer>
                                        <BootstrapTabContent title={'Dashboard'} icon={"icofont icofont-dashboard"}>
                                            This is Dashboard
                                        </BootstrapTabContent>

                                        <BootstrapTabContent title={'Center'} icon={'icofont icofont-home'}>
                                            <BranchCenters branch={branch}/>
                                        </BootstrapTabContent>

                                        <BootstrapTabContent title={'Employees'}
                                                             icon={'icofont icofont-ui-user'}>
                                            <BranchEmployees branch={branch}/>
                                        </BootstrapTabContent>
                                        <BootstrapTabContent title={'Contacts'}
                                                             icon={'icofont icofont-phone'}>
                                            <ContactList relatedKey={'branch'} relatedID={branch?.id}/>
                                        </BootstrapTabContent>

                                        <BootstrapTabContent title={'Update'} icon={'icofont icofont-ui-edit'}>
                                            <Row className={'justify-content-center'}>
                                                <Col md={6}> <BranchForm
                                                    branch={branch}
                                                    callBack={(branch: BranchType) => {
                                                        setBranch(branch);
                                                    }}
                                                />
                                                </Col>
                                            </Row>
                                        </BootstrapTabContent>
                                        <BootstrapTabContent title={'Activity Log'} icon={'icofont icofont-list'}>
                                            <h6>Activity log</h6>
                                        </BootstrapTabContent>

                                    </BootstrapTabContainer>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default BranchShow;
