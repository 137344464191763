import React, {Fragment, useEffect, useState} from 'react';
import {Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {Btn} from "../../../../../AbstractElements";
import Loader from "../../../../../Layout/Loader";
import ExtraFieldForm from "./Form";
import {useForm} from "react-hook-form";
import TextInput from "../../../../../CommonElements/Input/TextInput";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";

interface extraFieldShowProps {
    extraFieldModel: string
}

const ExtraFieldShow = ({extraFieldModel}: extraFieldShowProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState<boolean>(false);
    const [extraFields, setExtraFields] = useState<Array<any>>([]);
    const [selectedExtraField, setSelectedExtraField] = useState<any>();
    const [extraFieldModalVisibility, setExtraFieldModalVisibility] = useState<boolean>(false);

    const extraFieldModalToggle = () => {
        setExtraFieldModalVisibility(!extraFieldModalVisibility);
    }

    const {
        register,
    } = useForm();

    const getExtraFields = () => {
        setLoaderVisibility(true);
        const config = {params: {model: extraFieldModel}};
        axiosConfig
            .get(`/system-core/extra-field`, config)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    setExtraFields(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    const handleExtraFieldEdit = (extraField: any) => {
        setSelectedExtraField(extraField);
        extraFieldModalToggle();
    }

    const deleteExtraField = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`/system-core/extra-field/${id}`, {})
            .then((response: { data: { data: { id: any; }; }; }) => {
                if (response?.data?.data) {
                    setExtraFields(current => current.filter(extraField => {
                        return extraField.id !== response?.data?.data?.id;
                    }));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    const updateExtraField = (extraField: { id: any; }) => {
        const extraFieldList = [...extraFields];
        const index = extraFieldList.findIndex(extraFieldItem => extraFieldItem.id === extraField.id);
        if (index !== -1) {
            extraFieldList[index] = extraField;
            setExtraFields(extraFieldList);
        }
        setSelectedExtraField(undefined);
        extraFieldModalToggle();
    }

    useEffect(() => {
        getExtraFields();
    }, [extraFieldModel])

    return (<Fragment>
        <Loader visibility={loaderVisibility}/>
        <Container fluid={true}>
            <Row className={'justify-content-center'}>
                <Col md={8}>
                    {extraFields.map((extraField, key) => {
                        return <Row key={key}>
                            <Col sm={8}><TextInput name={extraField.key} type={extraField.field_type.name}
                                                   label={extraField.label}
                                                   register={register}
                                                   options={{required: extraField.required}}
                                                   placeholder={extraField.placeholder}/></Col>
                            <Col sm={4} style={{position: 'relative'}}>
                                {extraField?.used ? null :
                                    <div className='d-flex align-items-right gap-2 align-items-baseline'
                                         style={{position: "absolute", bottom: 17}}>
                                        <ButtonIcon size={'small'}
                                                    attrBtn={{onClick: () => handleExtraFieldEdit(extraField)}}
                                                    type={'edit'}/>
                                        <ButtonIcon size={'small'}
                                                    onclick={() => deleteExtraField(extraField.id)}
                                                    type={'delete'}/>
                                    </div>}
                            </Col>
                        </Row>
                    })}
                </Col>
            </Row>
            <Modal isOpen={extraFieldModalVisibility} toggle={extraFieldModalToggle} centered>
                <ModalHeader>
                    {selectedExtraField ? 'Update' : 'Create'} ExtraField
                </ModalHeader>
                <ModalBody>
                    <ExtraFieldForm extraFieldID={selectedExtraField?.id} callBack={updateExtraField}
                                    extraField={selectedExtraField}/>
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{color: 'primary', onClick: extraFieldModalToggle}}>Close</Btn>
                </ModalFooter>
            </Modal>
        </Container>
    </Fragment>)
}

export default ExtraFieldShow;
