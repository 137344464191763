import {Breadcrumbs, H6} from "../../../../../AbstractElements";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import Loader from "../../../../../Layout/Loader";
import {BootstrapTabContainer, BootstrapTabContent} from "../../../../../CommonElements/BootstrapTabs";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import UpdateLoanForm from "../Component/UpdateLoanForm";
import InstallmentTable from "../../Installment/Component/InstallmentTable";
import {LoanType} from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";
import {currencyConfig} from "../../../../../Config/ApplicationConfig";
import MemberTable from "../../../Employee/Member/Component/MemberTable";
import LoanDashboard from "../Component/LoanDashboard";

interface loanShowProps {

}

const LoanShow = ({}: loanShowProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [loan, setLoan] = useState<LoanType>();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setLoaderVisibility(true);
        const config = {};
        axiosConfig
            .get(`${getApplicationRoute('loan')}/${params.loan}`, {config})
            .then((response: { data: { data: LoanType }; }) => {
                if (response?.data?.data) {
                    setLoan(response.data.data);
                } else {
                    navigate(getApplicationRoute('loan'));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
                navigate(getApplicationRoute('loan'));
            });
    }, [params.loan]);

    const deleteLoan = () => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`${getApplicationRoute('loan')}/${loan?.id}`, {})
            .then((response: { data: { data: LoanType; }; }) => {
                if (response?.data?.data) {
                    navigate(getApplicationRoute('loan'));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    return (
        <div>
            <Breadcrumbs mainTitle="Loan" parent="Application"
                         subParentLink={`${getApplicationRoute('loan')}`}
                         subParent={'Loans'} title={loan?.id}/>
            <Container fluid={true}>
                <div className="user-profile">
                    <Loader visibility={loaderVisibility}/>
                    <Row>
                        <Col sm='12' lg='3'>
                            <Card className='hovercard text-center'>
                                <CardHeader className='social-profile'>
                                    <div className='d-flex align-items-center gap-2'>
                                    </div>
                                </CardHeader>
                                <div className='info'>
                                    <Row className='step3' data-intro='This is the loan details'>
                                        <Col sm='12' lg='12' className='order-sm-0 order-xl-1'>
                                            <div className='user-designation'>
                                                <div className='title'>
                                                    <a>{(loan?.amount + loan?.interest).toLocaleString('en-LK', currencyConfig)}
                                                    </a>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm='6' lg='12' className='order-sm-1 order-xl-0'>
                                            <Row>
                                                <Col>
                                                    <div className='ttl-info text-center'>
                                                        <H6>
                                                            <span
                                                                className='f-w-600 text-success'>  {loan?.paid?.toLocaleString('en-LK', currencyConfig)}</span>
                                                        </H6>
                                                        <H6>
                                                            <span
                                                                className='f-w-600 text-danger'>   {loan?.pending_to_pay?.toLocaleString('en-LK', currencyConfig)}</span>
                                                        </H6>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>

                                <CardBody>
                                    <div className='social-network theme-form m-t-20'>
                                        <span className='f-w-600 text-danger'>Danger Zone</span>
                                        <ButtonIcon confirm={true} onclick={() => deleteLoan()} type={'delete'}/></div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm='12' lg='9'>
                            <Card className='hovercard'>
                                <CardBody>
                                    <BootstrapTabContainer>
                                        <BootstrapTabContent title={'Dashboard'} icon={"icofont icofont-dashboard"}>
                                            <LoanDashboard loanID={loan?.id}/>
                                        </BootstrapTabContent>

                                        <BootstrapTabContent title={'Installment'} icon={'icofont icofont-ui-currency'}>
                                            <InstallmentTable requestParams={{loan_id: loan?.id}}/>
                                        </BootstrapTabContent>

                                        <BootstrapTabContent title={'Guarantors'}
                                                             icon={'icofont icofont-ui-user-group'}>
                                            <MemberTable requestParams={{guarantor_loan_id: loan?.id}}
                                                         tableOptions={{view: true}}/>
                                        </BootstrapTabContent>
                                        <BootstrapTabContent title={'Update'} icon={'icofont icofont-ui-edit'}>
                                            <UpdateLoanForm loanData={loan?.id}/>
                                        </BootstrapTabContent>
                                        <BootstrapTabContent title={'Activity Log'} icon={'icofont icofont-list'}>
                                            <h6>Activity log</h6>
                                        </BootstrapTabContent>

                                    </BootstrapTabContainer>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>);
}

export default LoanShow;
