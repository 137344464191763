import React, {useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import axiosConfig from '../../../../Config/axios';
import {ErrorHandler} from '../../../../_helper/SystemCore/SystemCoreHelpers';
import {getSystemCoreRoute} from "../../../../Route/RouteIndex";
import Loader from "../../../../Layout/Loader";

interface contactListProps {
    relatedKey: string,
    relatedID: number,
}

const ContactList = ({relatedKey, relatedID}: contactListProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [contacts, setContacts] = useState([]);

    const getContacts = (params: any) => {
        setLoaderVisibility(true);
        const config = {params: {...params, related_key: relatedKey, related_id: relatedID}};
        axiosConfig
            .get(getSystemCoreRoute('contact'), config)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    setContacts(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };


    useEffect(() => {
        getContacts({});
    }, []);


    const contactTableColumns = [
        {
            name: 'Contact Type',
            selector: (contact: { name: any; }) => contact?.contact_type?.name,
            sortable: true,
        },
        {
            name: 'Contact Details',
            selector: (contact: { contact: any; }) => contact?.contact,
            sortable: true,
        }
    ];

    return (
        <div>
            <Loader visibility={loaderVisibility}/>
            <DataTable pagination paginationServer columns={contactTableColumns} data={contacts}
                       highlightOnHover={true}
                       striped={true} responsive={true}/>
        </div>
    );
};

export default ContactList;
