import React from "react";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {H5} from "../../../../../AbstractElements";
import ReactApexChart from "react-apexcharts";
import {currencyConfig} from "../../../../../Config/ApplicationConfig";
import {useLoanDashboard} from "./useLoanDashboard";

interface loanDashboardProps {
    loanID: number;
}

const LoanDashboard = ({loanID}: loanDashboardProps) => {
    const {loan, loaderVisibility} = useLoanDashboard(loanID);


    return (<Row>
        <Col md={6}>
            <Card>
                <CardHeader className='card-no-border'>
                    <H5>Loan Installments</H5>
                    <span className='f-light f-w-500 f-14'>Installment Variant</span>
                </CardHeader>
                <CardBody className='pt-0'>
                    <div className='monthly-profit'>
                        <ReactApexChart type='donut' height={300}
                                        series={[loan?.paid, loan?.pending_to_pay, loan?.extended]}
                                        options={{
                                            labels: ["Paid", "Pending", "Extended"],
                                            chart: {
                                                type: "donut",
                                                height: 300,
                                            },
                                            dataLabels: {
                                                enabled: true,
                                            },
                                            plotOptions: {
                                                pie: {
                                                    expandOnClick: true,
                                                    donut: {
                                                        size: "83%",
                                                        labels: {
                                                            show: true,
                                                            name: {
                                                                offsetY: 4,
                                                            },
                                                            total: {
                                                                show: true,
                                                                fontSize: "20px",
                                                                fontFamily: "Rubik, sans-serif",
                                                                fontWeight: 500,
                                                                label: (loan?.amount + loan?.interest).toLocaleString('en-LK', currencyConfig),
                                                                formatter: () => "Loan Amount",
                                                            },
                                                        },
                                                    },
                                                },
                                            },
                                            colors: ["#54BA4A", "#16C7F9FF", "#FFA941"],
                                        }}/>
                    </div>
                </CardBody>
            </Card>
        </Col>
    </Row>);
}

export default LoanDashboard;