// dashbaord
import Crypto from "../Components/Dashboard/Crypto";
import Dashboard from "../Dashboard/Screen";
import Ecommerce from "../Components/Dashboard/Ecommerce";
import OnlineCourse from "../Components/Dashboard/OnlineCourse";
import Social from "../Components/Dashboard/Social";

// //widgets
import Chart from "../Components/Widgets/Chart";
import General from "../Components/Widgets/General";

// //page-layout
// import FooterLight from '../Components/PageLayout/FooterLight';
// import FooterDark from '../Components/PageLayout/FooterDark';
// //ui-kits
import Typography from "../Components/UiKits/Typography";
import Avatars from "../Components/UiKits/Avtars";
import HelperClasses from "../Components/UiKits/HelperClass";
import Grid from "../Components/UiKits/Grid";
import TagAndPills from "../Components/UiKits/TagAndPills";
import Progressbars from "../Components/UiKits/Progress";
import Modals from "../Components/UiKits/Modals";
import Alert from "../Components/UiKits/Alert";
import Popover from "../Components/UiKits/Popover";
import Tooltips from "../Components/UiKits/Tooltip";
import Spinners from "../Components/UiKits/Spinners";
import Dropdowns from "../Components/UiKits/Dropdown";
import Accordian from "../Components/UiKits/Accordian";
import TabBootstrap from "../Components/UiKits/Tabs/BootstrapTab";
import TabLine from "../Components/UiKits/Tabs/LineTab";
import Navs from "../Components/UiKits/Navs";
import Shadow from "../Components/UiKits/Shadows";
import List from "../Components/UiKits/Lists";

// //Bonus-ui
import Scrollable from "../Components/Bonus-Ui/Scrollable";
import TreeView from "../Components/Bonus-Ui/TreeView";
import BootstrapNotify from "../Components/Bonus-Ui/BootstrapNotify";
import RatingClass from "../Components/Bonus-Ui/Rating";
import Dropzones from "../Components/Bonus-Ui/Dropzones";
import Tours from "../Components/Bonus-Ui/Tour";
import SweetAlerts from "../Components/Bonus-Ui/SweetAlert";
import Carousels from "../Components/Bonus-Ui/Carousel";
import Ribbons from "../Components/Bonus-Ui/Ribbons";
import Paginations from "../Components/Bonus-Ui/Pagination";
import Steps from "../Components/Bonus-Ui/Steps";
import BreadcrumbClass from "../Components/Bonus-Ui/Breadcrumb";
import RangeSlider from "../Components/Bonus-Ui/RangeSlider";
import Imagecropper from "../Components/Bonus-Ui/ImageCropper";
import Sticky from "../Components/Bonus-Ui/Sticky";
import DragAndDrop from "../Components/Bonus-Ui/DragAndDrop";
import UploadImage from "../Components/Bonus-Ui/ImageUpload";
import BasicCards from "../Components/Bonus-Ui/Cards/BasicCards/index";
import CreativeCards from "../Components/Bonus-Ui/Cards/CreativeCards";
import TabCard from "../Components/Bonus-Ui/Cards/TabCard";
import Timeline1 from "../Components/Bonus-Ui/Timeline/Timeline1";
// // Icons
import WeatherIcon from "../Components/Icons/WeatherIcon";
import FeatherIcon from "../Components/Icons/FeatherIcon";
import ThemifyIcon from "../Components/Icons/ThemifyIcon";
import IcoIcon from "../Components/Icons/IcoIcon";
import FontawesomeIcon from "../Components/Icons/FontawesomeIcon";
import FlagIcon from "../Components/Icons/FlagIcon";

// // Buttons
import DefaultButton from "../Components/Buttons/Default";
import FlatButton from "../Components/Buttons/FlatBtn/index";
import EdgeButton from "../Components/Buttons/Edge/index";
import RaisedButton from "../Components/Buttons/RaisedBtn/index";
import GroupButton from "../Components/Buttons/GroupBtn/index";

// //chart
import ApexCharts from "../Components/Charts/ApexCharts";
import ChartJs from "../Components/Charts/ChartsJs";
import GoogleCharts from "../Components/Charts/GoogleCharts";

// //forms
import FormValidation from "../Components/Forms/FormControl/FormValidation";
import BaseInput from "../Components/Forms/FormControl/BaseInput";
import CheckboxandRadio from "../Components/Forms/FormControl/CheckRadio";
import InputGroups from "../Components/Forms/FormControl/InputGroups";
import MegaOption from "../Components/Forms/FormControl/MegaOption";
import Datepicker from "../Components/Forms/FormWidget/DatePicker";
import TypeaheadComp from "../Components/Forms/FormWidget/TypeaheadComponent/Typeahead";
import FormDefault from "../Components/Forms/FormLayout/Default";
import FormWizard from "../Components/Forms/FormLayout/FormWizard1/FormWizard1";
import BasicTables from "../Components/Tables/Reactstrap/BasicTable";

import DataTables from "../Components/Tables/DataTable";

// //Application
import Newproject from "../Components/Application/Project/Newproject";
import Project from "../Components/Application/Project/Project";
import Chat from "../Components/Application/Chat/ChatApp";
import VideoChat from "../Components/Application/Chat/VideoChat";

import Contact from "../Components/Application/Contact/ContactApp";
import Task from "../Components/Application/Task";
import TodoContain from "../Components/Application/Todo";

// //Gallary
import ImageGallery from "../Components/Gallery/ImageGallery";
import MesonryDesc from "../Components/Gallery/MesonryDesc";
import MasonryGallery from "../Components/Gallery/MesonryGallery";
import ImageWithDesc from "../Components/Gallery/ImageWithDesc";
import ImageHover from "../Components/Gallery/ImageHover";

// //Blog
import BlogDetail from "../Components/Blog/BlogDetail";
import BlogPost from "../Components/Blog/BlogPost";
import BlogSingle from "../Components/Blog/BlogSingle";

// //Pages
import SamplePage from "../Components/Pages/Sample";
// // import SearchPage from '../Components/Application/SearchResult'
// //Book mark
import BookmarksContain from "../Components/Application/BookMarks";

// //User app
import UsersCardssContain from "../Components/Application/Users/UsersCards";
import UsersEditContain from "../Components/Application/Users/UsersEdit";
import UsersProfileContain from "../Components/Application/Users/UsersProfile";

// //social app
import SocialAppContain from "../Components/Application/SocialApp";

// //calender
// import CalenderContain from '../Components/Application/Calender/Index';
// //Email
import MailInboxContain from "../Components/Application/Email/MailInbox";

// //File Manager
import FileManagerContain from "../Components/Application/FileManager";
import SearchResultContain from "../Components/Application/SearchResult";

// //E-commerce
import Products from "../Components/Application/Ecommerce/Products";
import ProductCart from "../Components/Application/Ecommerce/ProductCart";
import WishList from "../Components/Application/Ecommerce/Wishlist";
import CheckOut from "../Components/Application/Ecommerce/CheckOut";
import Invoice from "../Components/Application/Ecommerce/Invoice";
import OrderHistory from "../Components/Application/Ecommerce/OrderHistory";
import ProductPage from "../Components/Application/Ecommerce/ProductPage";
import PricingMemberShip from "../Components/Application/Ecommerce/PricingMemberShip";
import PaymentDetails from "../Components/Application/Ecommerce/PaymentDetails";
import ProductListContain from "../Components/Application/Ecommerce/ProductList";
import DraggableContain from "../Components/Application/Calender/DraggableCalender";

// //Job Search
import JobCards from "../Components/JobSearch/JobCardView";
import ListView from "../Components/JobSearch/ListView";
import JobDetails from "../Components/JobSearch/JobDetail";
import Apply from "../Components/JobSearch/Apply";

// //Learning
import LearningList from "../Components/Learning/LearningList";
import DetailCourse from "../Components/Learning/DetailCourse";

// //Map
import GoogleMaps from "../Components/Map/GoogleMap";
import PigeonMap from "../Components/Map/Pigeon Map";

// //Editor
import CkEditorContain from "../Components/Editor/CkEditor";
import MdeEDitorContain from "../Components/Editor/MDEEditor/MDEEditor";
import ACEEditorContain from "../Components/Editor/AceCodeEditor/ACECodeEditor";

import KnowledgeBaseContain from "../Components/Knowledgebase/KnowledgeBase";
import KnowledgeCategoryContain from "../Components/Knowledgebase/KnowledgeCategory";
import KnowledgeDetailContain from "../Components/Knowledgebase/KnowledgeDetails";
import SupportTickitContain from "../Components/SupportTicket";
import DateTimeContain from "../Components/Forms/FormWidget/DateandTime";
import FormDateRangepickerContain from "../Components/Forms/FormWidget/FormDateRangePicker";
import FormSelectContain from "../Components/Forms/FormWidget/FormSelect2";
import FormSwitchContain from "../Components/Forms/FormWidget/FormSwitch";
import FormTouchspinContain from "../Components/Forms/FormWidget/FormTouchspin";
import FormClipboardContain from "../Components/Forms/FormWidget/FormClipboard";
import FAQContent from "../Components/FAQ";
import UserIndex from "../Application/Privilege/User/Screen";
import UserShow from "../Application/Privilege/User/Screen/UserShow";
import RoleIndex from "../Application/Privilege/Role/Screen";
import RoleShow from "../Application/Privilege/Role/Screen/RoleShow";
import PermissionShow from "../Application/Privilege/Permission/Screen/PermissoinShow";
import PermissionIndex from "../Application/Privilege/Permission/Screen";
import Settings from "../Application/SystemCore/Setting/Setting/Screen";
// import BoardIndex from "../Application/Application/Board/Board/Screen";
// import CollectorIndex from "../Application/Application/Collector/Collector/Screen";
// import ParticipantIndex from "../Application/Application/Participant/Participant/Screen";
import ExtraFieldIndex from "../Application/SystemCore/ExtraField/ExtraField/Screen";
import ConfigurationShow from "../Application/SystemCore/Setting/Setting/Screen/ConfigurationShow";
import {getApplicationRoute, getPrivilegeRoute, getSystemCoreRoute} from "./RouteIndex";
// import BoardShow from "../Application/Application/Board/Board/Screen/BoardShow";
//GroupMember

//Group

//Branch
import BranchIndex from "../Application/Application/Organization/Branch/Screen";
import BranchShow from "../Application/Application/Organization/Branch/Screen/BranchShow";

//Center
import CenterIndex from "../Application/Application/Organization/Center/Screen";
import CenterShow from "../Application/Application/Organization/Center/Screen/CenterShow";

//CreditOfficer
import CreditOfficerShow from "../Application/Application/Organization/CreditOfficer/Screen/CreditOfficerShow";

//EntityLeader
import LoanIndex from "../Application/Application/Loan/Loan/Screen";
import LoanShow from "../Application/Application/Loan/Loan/Screen/LoanShow";
import InstallmentCollectorIndex from "../Application/Application/Loan/InstallmentCollector/Screen";


import MemberIndex from "../Application/Application/Employee/Member/Screen";
import MemberShow from "../Application/Application/Employee/Member/Screen/MemberShow";
import EmployeeIndex from "../Application/Application/Employee/Employee/Screen";
import EmployeeShow from "../Application/Application/Employee/Employee/Screen/EmployeeShow";
import LoanForm from "../Application/Application/Loan/Loan/Screen/LoanForm";
import InstallmentCollectionIndex from "../Application/Application/Loan/InstallmentCollection/Screen";
import CreditOfficerIndex from "../Application/Application/Organization/CreditOfficer/Screen";
import LoanReportIndex from "../Application/Application/Report/Loan/Screen";
import MemberReportIndex from "../Application/Application/Report/Member/Screen";
import CenterReportIndex from "../Application/Application/Report/Center/Screen";
import LoanReportWithoutInterestIndex from "../Application/Application/Report/LoanWithoutInterest/Screen";
import PaymentCollectionIndex from "../Application/Application/Report/PaymentCollection/Screen";
import CompletedLoanReportIndex from "../Application/Application/Report/CompletedLoan/Screen";
import UnpaidLoanReportIndex from "../Application/Application/Report/UnpaidLoan/Screen";


export const routes = [
    //System Core

    //CreditOfficer
    {path: getApplicationRoute('center-collector', true), Component: <CreditOfficerIndex/>},
    {path: `${getApplicationRoute('center-collector', true)}/:centerCollector`, Component: <CreditOfficerShow/>},

    //Center
    {path: getApplicationRoute('center', true), Component: <CenterIndex/>},
    {path: `${getApplicationRoute('center', true)}/:center`, Component: <CenterShow/>},

    //Branch
    {path: getApplicationRoute('branch', true), Component: <BranchIndex/>},
    {path: `${getApplicationRoute('branch', true)}/:branch`, Component: <BranchShow/>},

    {path: getApplicationRoute('employee', true), Component: <EmployeeIndex/>},
    {path: `${getApplicationRoute('employee', true)}/:employee`, Component: <EmployeeShow/>},

    {path: getApplicationRoute('customer', true), Component: <MemberIndex/>},
    {path: `${getApplicationRoute('customer', true)}/:member`, Component: <MemberShow/>},


    //Loan
    {path: getApplicationRoute('loan', true), Component: <LoanIndex/>},
    {path: `${getApplicationRoute('loan', true)}/:loan`, Component: <LoanShow/>},
    {path: `${getApplicationRoute('loan', true)}/create`, Component: <LoanForm/>},


    //Installment Collection
    {path: getApplicationRoute('installment-collection', true), Component: <InstallmentCollectionIndex/>},

    //InstallmentCollector
    {path: getApplicationRoute('installment-collector', true), Component: <InstallmentCollectorIndex/>},

    //Report
    {path: getApplicationRoute('loan-report-download', true), Component: <LoanReportIndex/>},
    {path: getApplicationRoute('loan-report-without-Interest-download', true), Component: <LoanReportWithoutInterestIndex/>},
    {path: getApplicationRoute('completed-loan-report-download', true), Component: <CompletedLoanReportIndex/>},
    {path: getApplicationRoute('unpaid-loan-report-download', true), Component: <UnpaidLoanReportIndex/>},
    {path: getApplicationRoute('customer-report-download', true), Component: <MemberReportIndex/>},
    {path: getApplicationRoute('center-report-download', true), Component: <CenterReportIndex/>},
    {path: getApplicationRoute('payment-collection-report-download', true), Component: <PaymentCollectionIndex/>},

    //Common
    {path: `${process.env.PUBLIC_URL}`, Component: <Dashboard/>},
    {path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Dashboard/>},
    //Privilege
    {path: getPrivilegeRoute('user', true), Component: <UserIndex/>},
    {path: `${getPrivilegeRoute('user', true)}/:user`, Component: <UserShow/>},
    {path: getPrivilegeRoute('role', true), Component: <RoleIndex/>},
    {path: `${getPrivilegeRoute('role', true)}/:role`, Component: <RoleShow/>},
    {path: getPrivilegeRoute('permission', true), Component: <PermissionIndex/>},
    {path: `${getPrivilegeRoute('permission', true)}/:permission`, Component: <PermissionShow/>},
    // Settings
    {path: getSystemCoreRoute('setting', true), Component: <Settings/>},
    {path: getSystemCoreRoute('configuration', true), Component: <ConfigurationShow/>},

    {path: getSystemCoreRoute('extra-field', true), Component: <ExtraFieldIndex/>},
    {path: `${getSystemCoreRoute('setting', true)}/?:setting`, Component: <Settings/>},
    // {path: getApplicationRoute('board', true), Component: <BoardIndex/>},
    // {path: `${getApplicationRoute('board', true)}/:board`, Component: <BoardShow/>},
    // {path: getApplicationRoute('collector', true), Component: <CollectorIndex/>},
    // {path: getApplicationRoute('participant', true), Component: <ParticipantIndex/>},

    {path: `${process.env.PUBLIC_URL}/dashboard/e-commerce`, Component: <Ecommerce/>},
    {path: `${process.env.PUBLIC_URL}/dashboard/online-course`, Component: <OnlineCourse/>},
    {path: `${process.env.PUBLIC_URL}/dashboard/crypto`, Component: <Crypto/>},
    {path: `${process.env.PUBLIC_URL}/dashboard/social`, Component: <Social/>},

    {path: `${process.env.PUBLIC_URL}/widgets/general`, Component: <General/>},
    {path: `${process.env.PUBLIC_URL}/widgets/chart`, Component: <Chart/>},

    {path: `${process.env.PUBLIC_URL}/ui-kits/typography`, Component: <Typography/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/avatar`, Component: <Avatars/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/helper-class`, Component: <HelperClasses/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/grids`, Component: <Grid/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/tag-pills`, Component: <TagAndPills/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/progress`, Component: <Progressbars/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/modal`, Component: <Modals/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/alert`, Component: <Alert/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/popover`, Component: <Popover/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/tooltips`, Component: <Tooltips/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/spinner`, Component: <Spinners/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/dropdown`, Component: <Dropdowns/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/accordion`, Component: <Accordian/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/tabs/bootstrap`, Component: <TabBootstrap/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/tabs/line`, Component: <TabLine/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/navs`, Component: <Navs/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/shadow`, Component: <Shadow/>},
    {path: `${process.env.PUBLIC_URL}/ui-kits/list`, Component: <List/>},

    {path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable`, Component: <Scrollable/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/tree-view`, Component: <TreeView/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify`, Component: <BootstrapNotify/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/rating`, Component: <RatingClass/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone`, Component: <Dropzones/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/tour`, Component: <Tours/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/sweet-alert`, Component: <SweetAlerts/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/carousel`, Component: <Carousels/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons`, Component: <Ribbons/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/pagination`, Component: <Paginations/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/steps`, Component: <Steps/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb`, Component: <BreadcrumbClass/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/rangeslider`, Component: <RangeSlider/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/imagecropper`, Component: <Imagecropper/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/stickynotes`, Component: <Sticky/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/drag_and_drop`, Component: <DragAndDrop/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload`, Component: <UploadImage/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/card/basiccards`, Component: <BasicCards/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativecards`, Component: <CreativeCards/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabcard`, Component: <TabCard/>},
    {path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline1`, Component: <Timeline1/>},

    {path: `${process.env.PUBLIC_URL}/icons/flag_icons`, Component: <FlagIcon/>},
    {path: `${process.env.PUBLIC_URL}/icons/fontawesome_icon`, Component: <FontawesomeIcon/>},
    {path: `${process.env.PUBLIC_URL}/icons/ico_icon`, Component: <IcoIcon/>},
    {path: `${process.env.PUBLIC_URL}/icons/themify_icons`, Component: <ThemifyIcon/>},
    {path: `${process.env.PUBLIC_URL}/icons/feather_icons`, Component: <FeatherIcon/>},
    {path: `${process.env.PUBLIC_URL}/icons/weather_icons`, Component: <WeatherIcon/>},

    {path: `${process.env.PUBLIC_URL}/buttons/simplebutton`, Component: <DefaultButton/>},
    {path: `${process.env.PUBLIC_URL}/buttons/flat`, Component: <FlatButton/>},
    {path: `${process.env.PUBLIC_URL}/buttons/edge`, Component: <EdgeButton/>},
    {path: `${process.env.PUBLIC_URL}/buttons/raised`, Component: <RaisedButton/>},
    {path: `${process.env.PUBLIC_URL}/buttons/group`, Component: <GroupButton/>},

    {path: `${process.env.PUBLIC_URL}/charts/apex`, Component: <ApexCharts/>},
    {path: `${process.env.PUBLIC_URL}/charts/chartJs`, Component: <ChartJs/>},
    {path: `${process.env.PUBLIC_URL}/charts/google`, Component: <GoogleCharts/>},

    {path: `${process.env.PUBLIC_URL}/forms/controls/validation`, Component: <FormValidation/>},
    {path: `${process.env.PUBLIC_URL}/forms/controls/input`, Component: <BaseInput/>},
    {path: `${process.env.PUBLIC_URL}/forms/controls/radio-checkbox`, Component: <CheckboxandRadio/>},
    {path: `${process.env.PUBLIC_URL}/forms/controls/group`, Component: <InputGroups/>},
    {path: `${process.env.PUBLIC_URL}/forms/controls/megaoption`, Component: <MegaOption/>},
    {path: `${process.env.PUBLIC_URL}/forms/widget/datepicker`, Component: <Datepicker/>},
    {path: `${process.env.PUBLIC_URL}/forms/widget/typeahead`, Component: <TypeaheadComp/>},
    {path: `${process.env.PUBLIC_URL}/forms/widget/datetimepicker`, Component: <DateTimeContain/>},
    {path: `${process.env.PUBLIC_URL}/forms/widget/rangepicker`, Component: <FormDateRangepickerContain/>},
    {path: `${process.env.PUBLIC_URL}/forms/widget/select2`, Component: <FormSelectContain/>},
    {path: `${process.env.PUBLIC_URL}/forms/widget/switch`, Component: <FormSwitchContain/>},
    {path: `${process.env.PUBLIC_URL}/forms/widget/touchspin`, Component: <FormTouchspinContain/>},
    {path: `${process.env.PUBLIC_URL}/forms/widget/clipboard`, Component: <FormClipboardContain/>},

    {path: `${process.env.PUBLIC_URL}/forms/layout/formDefault`, Component: <FormDefault/>},
    {path: `${process.env.PUBLIC_URL}/forms/layout/formWizard`, Component: <FormWizard/>},

    // //Tables
    {path: `${process.env.PUBLIC_URL}/table/reactstraptable/basictable`, Component: <BasicTables/>},
    {path: `${process.env.PUBLIC_URL}/table/datatable`, Component: <DataTables/>},

    // //Applicatiion
    {path: `${process.env.PUBLIC_URL}/app/project/project-list`, Component: <Project/>},
    {path: `${process.env.PUBLIC_URL}/app/project/new-project`, Component: <Newproject/>},
    {path: `${process.env.PUBLIC_URL}/app/chat-app/chats`, Component: <Chat/>},
    {path: `${process.env.PUBLIC_URL}/app/chat-app/chat-video-app`, Component: <VideoChat/>},
    {path: `${process.env.PUBLIC_URL}/system-core/contact`, Component: <Contact/>},

    {path: `${process.env.PUBLIC_URL}/app/task`, Component: <Task/>},
    {path: `${process.env.PUBLIC_URL}/app/bookmark`, Component: <BookmarksContain/>},
    {path: `${process.env.PUBLIC_URL}/app/todo-app/todo`, Component: <TodoContain/>},

    {path: `${process.env.PUBLIC_URL}/app/users/profile`, Component: <UsersProfileContain/>},
    {path: `${process.env.PUBLIC_URL}/app/users/edit`, Component: <UsersEditContain/>},
    {path: `${process.env.PUBLIC_URL}/app/users/cards`, Component: <UsersCardssContain/>},
    {path: `${process.env.PUBLIC_URL}/app/social-app`, Component: <SocialAppContain/>},

    {path: `${process.env.PUBLIC_URL}/app/calendar/draggable-calendar`, Component: <DraggableContain/>},

    {path: `${process.env.PUBLIC_URL}/app/email-app`, Component: <MailInboxContain/>},
    {path: `${process.env.PUBLIC_URL}/app/file-manager`, Component: <FileManagerContain/>},
    {path: `${process.env.PUBLIC_URL}/app/search`, Component: <SearchResultContain/>},
    {path: `${process.env.PUBLIC_URL}/app/ecommerce/product`, Component: <Products/>},
    {path: `${process.env.PUBLIC_URL}/app/ecommerce/product-page/:id`, Component: <ProductPage/>},
    {path: `${process.env.PUBLIC_URL}/app/ecommerce/payment-details`, Component: <PaymentDetails/>},
    {path: `${process.env.PUBLIC_URL}/app/ecommerce/orderhistory`, Component: <OrderHistory/>},
    {path: `${process.env.PUBLIC_URL}/app/ecommerce/pricing`, Component: <PricingMemberShip/>},
    {path: `${process.env.PUBLIC_URL}/app/ecommerce/invoice`, Component: <Invoice/>},
    {path: `${process.env.PUBLIC_URL}/app/ecommerce/cart`, Component: <ProductCart/>},
    {path: `${process.env.PUBLIC_URL}/app/ecommerce/wishlist`, Component: <WishList/>},
    {path: `${process.env.PUBLIC_URL}/app/ecommerce/checkout`, Component: <CheckOut/>},
    {path: `${process.env.PUBLIC_URL}/app/ecommerce/product-list`, Component: <ProductListContain/>},
    // //Gallery
    {path: `${process.env.PUBLIC_URL}/app/gallery/grids`, Component: <ImageGallery/>},
    {path: `${process.env.PUBLIC_URL}/app/gallery/griddesc`, Component: <ImageWithDesc/>},
    {path: `${process.env.PUBLIC_URL}/app/gallery/masonrys`, Component: <MasonryGallery/>},
    {path: `${process.env.PUBLIC_URL}/app/gallery/masonrydesc`, Component: <MesonryDesc/>},
    {path: `${process.env.PUBLIC_URL}/app/gallery/hover_effect`, Component: <ImageHover/>},

    // //page
    {path: `${process.env.PUBLIC_URL}/pages/sample-page`, Component: <SamplePage/>},

    // //Blog
    {path: `${process.env.PUBLIC_URL}/app/blog/blogdetails`, Component: <BlogDetail/>},
    {path: `${process.env.PUBLIC_URL}/app/blog/blogsingle`, Component: <BlogSingle/>},
    {path: `${process.env.PUBLIC_URL}/app/blog/blogpost`, Component: <BlogPost/>},

    // //Job Search
    {path: `${process.env.PUBLIC_URL}/app/jobsearch/cardview`, Component: <JobCards/>},
    {path: `${process.env.PUBLIC_URL}/app/jobsearch/joblist`, Component: <ListView/>},
    {path: `${process.env.PUBLIC_URL}/app/jobsearch/jobdetail`, Component: <JobDetails/>},
    {path: `${process.env.PUBLIC_URL}/app/jobsearch/jobapply`, Component: <Apply/>},

    // //Learning
    {path: `${process.env.PUBLIC_URL}/app/learning/learninglist`, Component: <LearningList/>},
    {path: `${process.env.PUBLIC_URL}/app/learning/learningdetail`, Component: <DetailCourse/>},

    // //Map
    {path: `${process.env.PUBLIC_URL}/app/map/googlemap`, Component: <GoogleMaps/>},
    {path: `${process.env.PUBLIC_URL}/app/map/pigeonmap`, Component: <PigeonMap/>},

    // //Editor
    {path: `${process.env.PUBLIC_URL}/editor/ckeditor`, Component: <CkEditorContain/>},
    {path: `${process.env.PUBLIC_URL}/editor/mdeeditor`, Component: <MdeEDitorContain/>},
    {path: `${process.env.PUBLIC_URL}/editor/aceeditor`, Component: <ACEEditorContain/>},

    // //Knowledge
    {path: `${process.env.PUBLIC_URL}/app/knowledgebase`, Component: <KnowledgeBaseContain/>},
    {path: `${process.env.PUBLIC_URL}/app/knowledgebase-category`, Component: <KnowledgeCategoryContain/>},
    {path: `${process.env.PUBLIC_URL}/app/knowledgebase-detail`, Component: <KnowledgeDetailContain/>},

    // //Support Ticket
    {path: `${process.env.PUBLIC_URL}/app/supportticket`, Component: <SupportTickitContain/>},

    // //Faq
    {path: `${process.env.PUBLIC_URL}/app/faq`, Component: <FAQContent/>},
];
