import React, { Fragment, useEffect, useState } from "react";
import { Form, FormGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import { ErrorHandler } from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import { Btn } from "../../../../../AbstractElements";
import TextInput from "../../../../../CommonElements/Input/TextInput";
import Loader from "../../../../../Layout/Loader";
import axiosConfig from "../../../../../Config/axios";
import { getApplicationRoute } from "../../../../../Route/RouteIndex";
import BackendDropDown from "../../../../../CommonElements/DropDown/BackendDropDown";
import { BranchType } from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";

interface branchFormProps {
    branch?: BranchType;
    callBack?: (branch: BranchType) => void;
}

const BranchForm = ({ callBack, branch }: branchFormProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (branch) {
            setValue("branch_name", branch.branch_name);
            setValue("code", branch.code);
            setValue("contact_number", branch.phone);
            setValue("address", branch.address);
            setValue("email", branch.email);
            setValue("manager_id", branch.manager?.id);
        }
    }, [branch]);

    const onSubmit = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        branch ? updateBranch(data) : createBranch(data);
    };

    const createBranch = (data: any) => {
        axiosConfig
            .post(getApplicationRoute("branch"), data)
            .then((response: { data: { data: BranchType } }) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    };

    const updateBranch = (data: any) => {
        if (branch) {
            axiosConfig
                .put(`${getApplicationRoute("branch")}/${branch.id}`, data)
                .then((response: { data: { data: BranchType } }) => {
                    if (response?.data?.data) {
                        callBack && callBack(response?.data?.data);
                    }
                    setLoaderVisibility(false);
                })
                .catch((exception: any) => {
                    ErrorHandler(exception, setError);
                    setLoaderVisibility(false);
                });
        }
    };

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Loader visibility={loaderVisibility} />
                <TextInput
                    name={"branch_name"}
                    type={"text"}
                    label={"Branch Name"}
                    register={register}
                    errors={errors}
                    placeholder={"Branch Name"}
                    options={{ required: true }}
                />
                <TextInput
                    name={"code"}
                    type={"text"}
                    label={"Code"}
                    register={register}
                    errors={errors}
                    placeholder={"Code"}
                    options={{ required: true }}
                />
                <BackendDropDown
                    name={"manager_id"}
                    type={"select-url"}
                    label={"Manager"}
                    setValue={setValue}
                    nameColumn={"name"}
                    value={branch?.manager?.id}
                    errors={errors}
                    params={{ designation_key: "designation-type-manager" }}
                    url={"/application/employee"}
                    options={{ required: true }}
                />
                <TextInput
                    name={"contact_number"}
                    type={"text"}
                    label={"Contact Number"}
                    register={register}
                    errors={errors}
                    placeholder={"+94702298348"}
                    options={{
                        required: true,
                        pattern: {
                            value: /^[0-9]{10}$/,
                            message: "Contact number must be 10 digits long.",
                        },
                    }}
                />
                <TextInput
                    name={"email"}
                    type={"text"}
                    label={"Email"}
                    register={register}
                    errors={errors}
                    placeholder={"info@mlm.lk"}
                    options={{
                        required: true,
                        pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email format.",
                        },
                    }}
                />
                <TextInput
                    name={"address"}
                    type={"text"}
                    label={"Address"}
                    register={register}
                    errors={errors}
                    placeholder={"Balangoda"}
                />
                <FormGroup>
                    <Btn
                        attrBtn={{
                            className: "d-block w-100 mt-4",
                            color: "primary",
                            type: "submit",
                        }}
                    >
                        {branch ? "Update" : "Create"}
                    </Btn>
                </FormGroup>
            </Form>
        </Fragment>
    );
};

export default BranchForm;
