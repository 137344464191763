import React, {useEffect, useRef, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {Breadcrumbs, H5} from "../../../../../AbstractElements";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import SearchOptions from "../../../../CommonComponents/Search";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import CompletedLoanReportTable from "../Component/CompletedLoanReportTable";

const CompletedLoanReportIndex = () => {
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const loanTableRef = useRef<any>(null);
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const searchOptions = [
        {name: 'group_member', label: 'Group Member'},
        {name: 'center_collector', label: 'Credit Officer'},
        {name: 'nic', label: 'NIC'},
        {name: 'branch_name', label: 'Branch'},
        {name: 'center_name', label: 'Center'},
        {name: 'status', label: 'Status'},
    ];

    const getLoans = (params: any) => {
        if (loanTableRef.current) {
            loanTableRef.current.getLoans(params);
        }
    };


    const downloadCompletedLoans = (params: any) => {
        setLoaderVisibility(true);
        const config = {params: params, responseType: 'blob'};
        axiosConfig
            .get(("/report/completed-loans"), config)
            .then((response: { data: BlobPart; }) => {
                const blob = new Blob([response.data],
                    {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                );
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'completed_loans.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    useEffect(() => {
        getLoans({});
    }, []);

    return (
        <div>
            <Breadcrumbs mainTitle="Report" parent="Application" title="Completed Loans"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                <H5>Completed Loan</H5>
                                <div>
                                    <div className="d-flex align-items-center gap-2">
                                        <ButtonIcon
                                            attrBtn={{onClick: () => setSearchVisibility(!searchVisibility)}}
                                            type={"search"}
                                        />

                                        <ButtonIcon
                                            attrBtn={{onClick: getLoans}}
                                            type={"sync"}
                                        />

                                        <ButtonIcon
                                            attrBtn={{onClick: downloadCompletedLoans}}
                                            type={'download'}
                                        />
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {searchVisibility && (
                                    <SearchOptions
                                        callBack={(params) => getLoans(params)}
                                        searchOptions={searchOptions}
                                    />
                                )}
                                <CompletedLoanReportTable actionsToHide={{delete: true}} columnsToHide={{option: true}}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CompletedLoanReportIndex;