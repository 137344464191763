import React, {Fragment} from "react";
import {FormGroup, Label} from "reactstrap";
import CommonSelector from "../../Application/CommonComponents/DropDowns/CommonSelector";

interface backendDropDownProps {
    label: string;
    name: string;
    url: string;
    options: any;
    setValue: any;
    value?: any;
    errors?: any;
    params?: any;
    reload?: boolean,
    fixedValues?: any,
    nameColumn?: string;
    onChange?: (event: any) => void;
    getSelectedValue?: (selectedValue: any) => void;
}

const BackendDropDown = ({
                             label,
                             name,
                             url,
                             reload,
                             options,
                             value,
                             params,
                             setValue,
                             onChange,
                             getSelectedValue,
                             fixedValues,
                             errors = null,
                             nameColumn = undefined,
                         }: backendDropDownProps) => {

    const onChangeInput = (value: any) => {
        setValue(name, value?.value);
        onChange && onChange(value);
    }

    return (
        fixedValues && fixedValues[name] ? <Fragment/> :
            <FormGroup>
                <Label className={`col-form-label m-0 ${options?.required ? 'required' : null}`}>
                    {label ? label : 'Label'}
                </Label>
                {fixedValues && fixedValues[name] ? (fixedValues[name]?.text ? fixedValues[name]?.text : `${label} is Fixed`) :
                    <CommonSelector
                        reload={reload}
                        onChange={onChangeInput}
                        params={params}
                        options={options}
                        url={url}
                        value={value}
                        nameColumn={nameColumn}
                        getSelectedValue={getSelectedValue}
                    />}
                {errors && (
                    <span className='d-block invalid-feedback'>
          {errors[name] && (errors[name].message ? errors[name].message : `Please provide a valid ${name}.`)}
        </span>
                )}
            </FormGroup>
    );
};

export default BackendDropDown;
