import { useState, useEffect } from 'react';
import axiosConfig from "../../../../../Config/axios";
import { getApplicationRoute } from "../../../../../Route/RouteIndex";
import { ErrorHandler } from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import { LoanDashboardType } from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";

export const useLoanDashboard = (loanID: number) => {
  const [loaderVisibility, setLoaderVisibility] = useState(false);
  const [loan, setLoan] = useState<LoanDashboardType>();

  useEffect(() => {
    if (loanID) {
      setLoaderVisibility(true);
      const config = {};
      axiosConfig
        .get(`${getApplicationRoute('dashboard/loan')}/${loanID}`, { config })
        .then((response: { data: { data: LoanDashboardType } }) => {
          if (response?.data?.data) {
            setLoan(response.data.data);
          }
          setLoaderVisibility(false);
        })
        .catch((exception: any) => {
          ErrorHandler(exception);
          setLoaderVisibility(false);
        });
    }
  }, [loanID]);

  return { loan, loaderVisibility };
};
