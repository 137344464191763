import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {FileText, LogIn, Mail, User} from "react-feather";
import man from "../../../assets/images/dashboard/profile.png";

import {Image, LI, P, UL} from "../../../AbstractElements";
import {Account, Admin, Inbox, LogOut, Taskboard} from "../../../Constant";
import {capitalizeFirstLetter} from "../../../_helper/SystemCore/SystemCoreHelpers";

const UserHeader = () => {
    const history = useNavigate();
    const [profile, setProfile] = useState("");
    const [name, setName] = useState("Windraw User");
    const authenticated = !!localStorage.getItem("authenticated_user") && !!localStorage.getItem("authenticated_token");
    const user = JSON.parse(localStorage.getItem("authenticated_user"));

    useEffect(() => {
        setProfile(localStorage.getItem("profileURL") || man);
        setName(user?.name ? user?.name : name);
    }, []);

    const Logout = () => {
        localStorage.removeItem("authenticated_user");
        localStorage.removeItem("authenticated_token");
        document.location.href = '/';
    };

    const UserMenuRedirect = (redirect) => {
        history(redirect);
    };

    return (
        <li className="profile-nav onhover-dropdown pe-0 py-0">
            <div className="media profile-media">
                <Image
                    attrImage={{
                        className: "b-r-10 m-0",
                        src: `${authenticated ? user.picture : profile}`,
                        alt: "",
                    }}
                />
                <div className="media-body">
                    <span>{capitalizeFirstLetter(authenticated ? user.name : name)}</span>
                    <P attrPara={{className: "mb-0 font-roboto"}}>
                        {Admin} <i className="middle fa fa-angle-down"></i>
                    </P>
                </div>
            </div>
            <UL attrUL={{className: "simple-list profile-dropdown onhover-show-div"}}>
                <LI
                    attrLI={{
                        onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/privilege/user/${user.id}`),
                    }}>
                    <User/>
                    <span>{Account} </span>
                </LI>
                <LI
                    attrLI={{
                        onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/email-app`),
                    }}>
                    <Mail/>
                    <span>{Inbox}</span>
                </LI>
                <LI
                    attrLI={{
                        onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/todo-app/todo`),
                    }}>
                    <FileText/>
                    <span>{Taskboard}</span>
                </LI>
                <LI attrLI={{onClick: Logout}}>
                    <LogIn/>
                    <span>{LogOut}</span>
                </LI>
            </UL>
        </li>
    );
};

export default UserHeader;
