import React, {Fragment, useEffect, useState} from 'react';

import {Form, FormGroup} from 'reactstrap';

import {useForm} from "react-hook-form";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {Btn} from "../../../../../AbstractElements";
import TextInput from "../../../../../CommonElements/Input/TextInput";
import Loader from "../../../../../Layout/Loader";
import axiosConfig from "../../../../../Config/axios";

interface configurationFormProps {
    callBack?: (configuration: any) => void
    configuration?: any
    setting?: any
}

const ConfigurationForm = ({callBack, configuration, setting}: configurationFormProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);

    const {
        register, handleSubmit, setError, clearErrors, formState: {errors}, setValue, watch
    } = useForm();

    useEffect(() => {
        if (configuration) {
            setValue('setting_id', configuration.settings?.id);
            setValue('key', configuration.key);
            setValue('name', configuration.name);
            setValue('description', configuration.description);
        }
    }, [configuration])

    const onSubmit = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        configuration ? updateConfiguration(data) : createConfiguration(data);
    }

    const createConfiguration = (data: { setting_id: any; }) => {
        clearErrors();
        setLoaderVisibility(true);
        data.setting_id = setting.id;
        axiosConfig
            .post(`/system-core/configuration`, data)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    }

    const updateConfiguration = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        axiosConfig
            .put(`/system-core/configuration/${configuration.id}`, data)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    }

    useEffect(() => {
        watch('name') ? setValue('key', (`${setting.key} ${watch('name')}`)?.toLowerCase()
            .replace(/\s+/g, '-')) : setValue('key', '');
    }, [watch('name')])

    return (<Fragment>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Loader visibility={loaderVisibility}/>
            <TextInput name={'name'} type={'text'} label={'Name'} register={register}
                       errors={errors} placeholder={'Name'} options={{required: true}}/>
            <TextInput name={'key'} type={'text'} readOnly={true} label={'Key'} register={register}
                       errors={errors} placeholder={'Key'} options={{required: true}}/>
            <TextInput name={'description'} type={'text'} label={'Description'} register={register}
                       errors={errors} placeholder={'Description'} options={{required: true}}/>
            <FormGroup>
                <Btn attrBtn={{
                    className: 'd-block w-100 mt-4',
                    color: 'primary',
                    type: 'submit'
                }}>{configuration ? 'Update' : 'Create'}</Btn>
            </FormGroup>
        </Form>
    </Fragment>);
};

export default ConfigurationForm;