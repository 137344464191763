import {Col, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import React, {Children, Fragment, useState} from "react";
import {Row} from "react-bootstrap";

export const BootstrapTabContainer = ({children, horizontal = true}) => {
    const [activeTab, setActiveTab] = useState(0);
    const arrayChildren = Children.toArray(children);

    const getNavTabs = () => {
        return Children.map(arrayChildren, (child, index) => {
            return <NavItem>
                <NavLink className={activeTab === index ? 'active' : ''}
                         onClick={() => setActiveTab(index)}>
                    {child.props?.icon ? <i className={child.props?.icon}/> : null}
                    {child.props?.title}
                </NavLink>
            </NavItem>
        })
    }

    const getNavTabData = () => {
        return Children.map(arrayChildren, (child, index) => {
            return <TabPane className='fade show' tabId={index}>
                {activeTab === index && child.props?.children}
            </TabPane>
        })
    }
    return (
        horizontal ? <Fragment> <Nav className='border-tab' tabs style={{overflow: 'auto', scrollbarWidth: 'none'}}>
            {getNavTabs()}
        </Nav>
            <TabContent activeTab={activeTab}>
                {getNavTabData()}
            </TabContent></Fragment> : <Row> <Col md={3}><Nav className='nav flex-column nav-pills' tabs>
            {getNavTabs()}
        </Nav></Col>
            <Col md={9}><TabContent activeTab={activeTab}>
                {getNavTabData()}
            </TabContent></Col></Row>
    )
}
export const BootstrapTabContent = () => {
    return (<Fragment/>);
}
