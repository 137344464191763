import Select from "react-select";
import React, {useEffect, useState} from "react";
import axiosConfig from "../../../Config/axios";
import {ErrorHandler} from "../../../_helper/SystemCore/SystemCoreHelpers";

interface commonSelectorProps {
    url: string;
    options: any;
    value: any;
    onChange: (event: any) => void;
    reload?: boolean;
    idColumn?: string;
    nameColumn?: string | undefined;
    params?: any;
    getSelectedValue?: (selectedValue: any) => void;
}

const CommonSelector = ({
                            url,
                            options,
                            value,
                            onChange,
                            getSelectedValue,
                            reload,
                            params,
                            idColumn = "id",
                            nameColumn = "name",
                        }: commonSelectorProps) => {
    const [dropDownOptions, setDropDownOptions] = useState([]);
    const [selectedDropDownOptions, setSelectedDropDownOptions] = useState({});
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [dropDownData, setDropDownData] = useState<any[]>([]);

    useEffect(() => {
        setLoaderVisibility(true);
        const config = {
            params: params,
        };
        axiosConfig
            .get(url, config)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    setDropDownData(response?.data?.data);
                    setDropDownOptions(
                        response.data.data.map((data: any) => ({
                            value: data[idColumn],
                            label: nameColumn.split('.').reduce((o, i) => (o ? o[i] : ""), data),
                        }))
                    );
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }, [url, idColumn, nameColumn, reload]);

    const onChangeMethod = (value: any) => {
        setSelectedDropDownOptions(value);
        onChange(value);
        getSelectedValue && selectSelectedValue(value);
    };

    const selectSelectedValue = (value: any) => {
        const selectedValues = dropDownData.filter((object: any) => object[idColumn] == value.value);
        getSelectedValue && getSelectedValue(selectedValues.length ? selectedValues[0] : undefined);
    }

    useEffect(() => {
        value && setSelectedValue(value);
    }, [value, dropDownOptions]);

    const setSelectedValue = (selectedValue: any) => {
        const selectedValueArray = dropDownOptions.filter(
            (option:any) => option.value === selectedValue
        );
        selectedValueArray.length && setSelectedDropDownOptions(selectedValueArray[0]);
    };

    return (
        <Select
            value={selectedDropDownOptions}
            isLoading={loaderVisibility}
            options={dropDownOptions}
            onChange={onChangeMethod}
            className="js-example-basic-single col-sm-12"
            {...options}
        />
    );
};

export default CommonSelector;
