import {H4, LI, UL} from "../../../../../AbstractElements";
import {currencyConfig} from "../../../../../Config/ApplicationConfig";
import React from "react";
import {Col} from "reactstrap";

interface simpleLoanView {
    formData: any
    installmentType: any
}

const SimpleLoanView = ({formData, installmentType}: simpleLoanView) => {
    const {amount, installment_range, interest_rate_type_key, interest_rate,} = formData;
    const loanAmount = amount ? parseInt(amount) : 0;
    const installments = installment_range ? parseInt(installment_range) : 1;
    const installmentAmountWithoutInterest = ((loanAmount ? loanAmount : 1) / (installment_range ? installment_range : 1));
    const interestRate = interest_rate ? interest_rate : 0;
    const totalInterest = parseFloat(interest_rate_type_key === 'interest-rate-type-percentage' ? ((loanAmount ? loanAmount : 1) / 100) * interestRate : (interest_rate_type_key === 'interest-rate-type-fix-rate') ? (interestRate) : 0);
    const installmentInterestAmount = (totalInterest / installments);
    const installmentAmountWithInterest = (installmentInterestAmount + installmentAmountWithoutInterest);
    const totalPayable = (installmentAmountWithInterest * installments);
    return (
        <Col xl='12' sm='12'>
            <div className='checkout-details'>
                <div className='order-box'>
                    <div className='title-box'>
                        <div
                            className='checkbox-title'>
                            <H4>Loan Amount </H4>
                            <span>{loanAmount.toLocaleString('en-LK', currencyConfig)}</span>
                        </div>
                    </div>
                    <UL attrUL={{className: 'simple-list border-0 sub-total total'}}>
                        <LI attrLI={{className: 'border-0 bg-transparent'}}>
                            Number of
                            Installments <span
                            className='count'>{installments}</span>
                        </LI>
                    </UL>
                    <UL attrUL={{className: 'simple-list sub-total total'}}>
                        <LI attrLI={{className: 'border-0 bg-transparent '}}>
                            {`${installmentType ? installmentType : ''} Installment without interest`}
                            <span
                                className='count'>{installmentAmountWithoutInterest.toLocaleString('en-LK', currencyConfig)}</span>
                        </LI>
                    </UL>
                    <UL attrUL={{className: 'simple-list border-0 sub-total total'}}>
                        <LI attrLI={{className: 'border-0 bg-transparent'}}>
                            Total Interest <span
                            className='count'>{totalInterest.toLocaleString('en-LK', currencyConfig)}</span>
                        </LI>
                    </UL>
                    <UL attrUL={{className: 'simple-list border-0 sub-total total'}}>
                        <LI attrLI={{className: 'border-0 bg-transparent'}}>
                            {`${installmentType ? installmentType : ''} interest`} <span
                            className='count'>{installmentInterestAmount.toLocaleString('en-LK', currencyConfig)}</span>
                        </LI>
                    </UL>
                    <UL attrUL={{className: 'simple-list border-0 sub-total total'}}>
                        <LI attrLI={{className: 'border-0 bg-transparent'}}>
                            {`${installmentType ? installmentType : ''} Installment with interest`} <span
                            className='count'>{installmentAmountWithInterest.toLocaleString('en-LK', currencyConfig)}</span>
                        </LI>
                    </UL>
                    <UL attrUL={{className: 'simple-list border-0 sub-total total'}}>
                        <LI attrLI={{className: 'border-0 bg-transparent'}}>
                            {`Total payable amount`} <span
                            className='count'>{totalPayable.toLocaleString('en-LK', currencyConfig)}</span>
                        </LI>
                    </UL>
                </div>
            </div>
        </Col>
    )
}

export default SimpleLoanView;