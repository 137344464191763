import React, {useEffect, useRef, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import CenterForm from "../Components/Form";
import {Breadcrumbs, Btn, H5} from "../../../../../AbstractElements";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import SearchOptions from "../../../../CommonComponents/Search";
import CenterTable from "../Components/CenterTable";
import {CenterType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";

const CenterIndex = () => {
    const [centerModalVisibility, setCenterModalVisibility] = useState(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const centerTableRfe = useRef();

    const searchOptions = [
        {name: "name", label: "Center Name"},
        {name: "code", label: "Code"},
    ];

    const centerModalToggle = () => {
        setCenterModalVisibility(!centerModalVisibility);
    };

    const getCenters = (params: any) => {
        if (centerTableRfe.current) {
            centerTableRfe.current.getCenters(params);
        }
    };

    const addCenter = (center: CenterType) => {
        if (centerTableRfe.current) {
            centerTableRfe.current.addCenter(center);
        }
    };

    useEffect(() => {
        getCenters({});
    }, []);

    return (
        <div>
            <Breadcrumbs mainTitle="Center" parent="Application" title="Center"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                <H5>Center</H5>
                                <div>
                                    <div className="d-flex align-items-center gap-2">
                                        <ButtonIcon
                                            attrBtn={{onClick: () => setCenterModalVisibility(true)}}
                                            type={"add"}
                                        />

                                        <ButtonIcon
                                            attrBtn={{onClick: () => setSearchVisibility(!searchVisibility)}}
                                            type={"search"}
                                        />

                                        <ButtonIcon attrBtn={{onClick: getCenters}} type={"sync"}/>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {searchVisibility && (
                                    <SearchOptions
                                        callBack={(params) => getCenters(params)}
                                        searchOptions={searchOptions}
                                    />
                                )}
                                <CenterTable ref={centerTableRfe}/>
                            </CardBody>
                            <Modal isOpen={centerModalVisibility} toggle={centerModalToggle} centered>
                                <ModalHeader>Create Center</ModalHeader>
                                <ModalBody>
                                    <CenterForm callBack={(center) => {
                                        addCenter(center);
                                        centerModalToggle();
                                    }}/>
                                </ModalBody>
                                <ModalFooter>
                                    <Btn attrBtn={{color: "primary", onClick: centerModalToggle}}>
                                        Close
                                    </Btn>
                                </ModalFooter>
                            </Modal>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CenterIndex;
