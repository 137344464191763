import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, ListGroup, Row} from 'reactstrap';
import {Breadcrumbs, H6} from '../../../../AbstractElements';
import {useParams} from "react-router";
import axiosConfig from "../../../../Config/axios";
import {ErrorHandler} from "../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../Layout/Loader";
import {BootstrapTabContainer, BootstrapTabContent} from "../../../../CommonElements/BootstrapTabs";
import {getPrivilegeRoute} from "../../../../Route/RouteIndex";

const PermissionShow = () => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [permission, setPermission] = useState();
    const params = useParams();

    useEffect(() => {
        setLoaderVisibility(true);
        const config = {};
        config.params = {};
        axiosConfig
            .get(`/privilege/permission/${params.permission}`, {})
            .then(response => {
                if (response?.data?.data) {
                    setPermission(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch(exception => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }, [params.permission]);

    return (
        <div>
            <Breadcrumbs mainTitle={'Permission'} parent="Privilege" subParentLink={getPrivilegeRoute('permission')}
                         subParent={'Permissions'}
                         title={permission?.name}/>
            <Container fluid={true}>
                <Row>
                    <Col md={12}>
                        <div className="user-profile">
                            <Loader visibility={loaderVisibility}/>
                            <Row>
                                <Col sm='12' lg='3'>
                                    <Card className='hovercard text-center'>
                                        <CardHeader className='social-profile'>
                                        </CardHeader>
                                        <div className='info'>
                                            <Row className='step3' data-intro='This is the your details'>
                                                <Col sm='12' lg='12' className='order-sm-0 order-xl-1'>
                                                    <div className='user-designation'>
                                                        <div className='title'>
                                                            <a>
                                                                {permission?.display_name}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm='6' lg='12' className='order-sm-1 order-xl-0'>
                                                    <Row>
                                                        <Col>
                                                            <div className='ttl-info text-center'>
                                                                <H6>
                                                                    {permission?.name}
                                                                </H6>
                                                                <H6>
                                                                    {permission?.description}
                                                                </H6>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </Col>
                                <Col sm='8'>
                                    <Col col={12}>
                                        <Card className='hovercard'>
                                            <CardBody>
                                                <BootstrapTabContainer>
                                                    <BootstrapTabContent title={'Dashboard'}
                                                                         icon={'icofont icofont-dashboard'}>
                                                        This is Dashboard
                                                    </BootstrapTabContent>
                                                    <BootstrapTabContent title={'Users'}
                                                                         icon={'icofont icofont-ui-user'}>

                                                        <ListGroup>
                                                            <Row>
                                                                {permission?.users?.map((user, key) => (
                                                                    <Col sm="4" key={key}>
                                                                        <Card>
                                                                            <CardBody style={{height: '150px'}}
                                                                                      className="shadow-lg p-25 shadow-showcase align-items-center text-center">
                                                                                <h6>
                                                                                    {user.name}
                                                                                </h6>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </ListGroup>

                                                    </BootstrapTabContent>
                                                    <BootstrapTabContent title={'Roles'} icon={'icofont icofont-key'}>
                                                        <ListGroup>
                                                            <Row>
                                                                {permission?.roles?.map((roles, key) => (
                                                                    <Col sm="4" key={key}>
                                                                        <Card>
                                                                            <CardBody style={{height: '150px'}}
                                                                                      className="shadow-lg p-25 shadow-showcase align-items-center text-center">
                                                                                <h6>
                                                                                    {roles.display_name}
                                                                                </h6>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </ListGroup>
                                                    </BootstrapTabContent>
                                                </BootstrapTabContainer>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default PermissionShow;
