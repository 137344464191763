import React, {Fragment, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {Breadcrumbs, Btn, H5} from "../../../../../AbstractElements";
import Loader from "../../../../../Layout/Loader";
import DataTable from "react-data-table-component";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import SetSearchQuery from "../Component/Search";
import IconBtn from "../../../../../CommonElements/IconButton";
import SettingShow from "../Component/SettingShow";
import ConfigurationForm from "../../Configuration/Component/Form";


const ConfigurationShow = ({}) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [configuration, setConfiguration] = useState('');
    const [settings, setSettings] = useState([]);
    const [setting, setSetting] = useState([]);

    const [configurations, setConfigurations] = useState([]);
    const [selectedConfiguration, setSelectedConfiguration] = useState();
    const [configurationModalVisibility, setConfigurationModalVisibility] = useState(false);

    const configurationModalToggle = () => {
        setConfigurationModalVisibility(!configurationModalVisibility);
    }

    const getConfigurations = () => {
        setLoaderVisibility(true);
        const config = {};
        config.params = {setting_id: setting.id};
        axiosConfig
            .get(`/system-core/configuration`, config)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    setConfigurations(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    const addConfiguration = (configuration: { id: any; }) => {
        const configurationList = [...configurations];
        const existingConfigurationIndex = configurationList.findIndex(existingConfiguration => {
            return existingConfiguration.id === configuration.id;
        });
        if (existingConfigurationIndex !== -1) {
            configurationList[existingConfigurationIndex] = configuration;
        } else {
            configurationList.push(configuration);
        }
        setConfigurations(configurationList);
        configurationModalToggle();
    }

    const handleConfigurationEdit = (configuration: any) => {
        setSelectedConfiguration(configuration);
        configurationModalToggle();
    }

    const deleteConfiguration = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`/system-core/configuration/${id}`, {})
            .then((response: { data: { data: { id: any; }; }; }) => {
                if (response?.data?.data) {
                    setConfigurations(current => current.filter(user => {
                        return user.id !== response?.data?.data?.id;
                    }));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    useEffect(() => {
        setLoaderVisibility(true);
        const config = {};
        config.params = {configuration_id: configuration.id};
        axiosConfig
            .get(`/system-core/configuration`, config)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    setConfiguration(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    });

    useEffect(() => {
        getConfigurations();
    }, [setting.id])


    const configurationsTableColumns = [{
        name: 'ID', selector: (row: { id: any; }) => row?.id, sortable: false,
    }, {
        name: 'name', selector: (row: { name: any; }) => row?.name, sortable: false,
    }, {
        name: 'Key', selector: (row: { key: any; }) => row?.key, sortable: true, center: true,
    }, {
        name: 'description', selector: (row: { description: any; }) => row?.description, sortable: true, center: true,
    }, {
        name: 'Option', selector: (row: { id?: any; }) => (
            <div className='d-flex align-items-right gap-2'>
                {
                    row.system_defined ? null : <Fragment>
                        <IconBtn onClick={() => ConfigurationShow(row)} type={'view'}/>
                        <IconBtn onClick={() => handleConfigurationEdit(row)} type={'edit'}/>
                        <IconBtn confirm={true} onClick={() => deleteConfiguration(row?.id)} type={'delete'}/>
                    </Fragment>
                }
            </div>
        ), sortable: false, right: true,
    }];
    return (<Fragment>
        <Breadcrumbs mainTitle="Settings" parent='Configuration' title='Configuration Show'/>
        <Loader visibility={loaderVisibility}/>
        <Container fluid={true}>
            <div className="email-wrap bookmark-wrap">
                <CardHeader className='d-flex align-items-center justify-content-between'>
                    <H5>{configuration.name}</H5>
                    <div>
                        <div className='d-flex align-items-center gap-2'>
                            <ButtonIcon confirm={true} attrBtn={{
                                onClick: () => setConfigurationModalVisibility(true)
                            }} type={'search'}/>
                            <ButtonIcon confirm={true} attrBtn={{
                                onClick: () => SetSearchQuery(true)
                            }} type={'back'}/>
                            <ButtonIcon confirm={true} attrBtn={{
                                onClick: () => SettingShow(true)
                            }} type={'back'}/>
                        </div>
                    </div>
                </CardHeader>
                <Row>
                    <Fragment>
                        <Col xl='3' className='box-col-3 pe-0 file-spacing'>
                            <div className='md-sidebar job-sidebar'>
                                <div className={`md-sidebar-aside job-left-aside custom-scrollbar`}>
                                    <div className='file-sidebar'>
                                        <Card>
                                            <CardBody>
                                                <h5 className="board-title">Key</h5>
                                                <p className="board-text">{settings.key}</p>
                                                <h5 className="board-title">Name</h5>
                                                <p className="board-text">{settings.name}</p>
                                                <h5 className="board-title">Description</h5>
                                                <p className="board-text">{settings.description}</p>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <Card>
                                <CardBody>
                                    <DataTable pagination paginationServer columns={configurationsTableColumns}
                                               data={configurations}
                                               highlightOnHover={true}
                                               striped={true} responsive={true}/>

                                </CardBody>
                            </Card>
                        </Col>
                    </Fragment>
                </Row>
            </div>
            <Modal isOpen={configurationModalVisibility} toggle={configurationModalToggle} centered>
                <ModalHeader>
                    {selectedConfiguration ? 'Update' : 'Create'} Configuration
                </ModalHeader>
                <ModalBody>
                    <ConfigurationForm settingID={setting.id} callBack={addConfiguration}
                                       configuration={selectedConfiguration}/>
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{color: 'primary', onClick: configurationModalToggle}}>Close</Btn>
                </ModalFooter>
            </Modal>
        </Container>
    </Fragment>)
}

export default ConfigurationShow;
