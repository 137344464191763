import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Breadcrumbs, Btn, H5 } from '../../../../AbstractElements';
import DataTable from "react-data-table-component";
import axiosConfig from "../../../../Config/axios";
import IconBtn from "../../../../CommonElements/IconButton";
import ButtonIcon from "../../../../CommonElements/ButtonIcon";
import UserForm from "../Component/Form";
import { ErrorHandler } from "../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../Layout/Loader";
import { getPrivilegeRoute } from "../../../../Route/RouteIndex";
import SearchOptions from "../../../CommonComponents/Search";

const UserIndex = () => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [users, setUsers] = useState([]);
    const [userModalVisibility, setUserModalVisibility] = useState(false);
    const [searchVisibility, setSearchVisibility] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const searchOptions = [{ name: 'name' }, { name: 'email' }];

    const userModalToggle = () => {
        setUserModalVisibility(!userModalVisibility);
    };

    const getUsers = (params = {}) => {
        setLoaderVisibility(true);
        const config = {
            params: {
                ...params,
                page: params.page || currentPage,
                perPage: 10,
            }
        };
        axiosConfig
            .get(`/privilege/user`, config)
            .then(response => {
                if (response?.data?.data) {
                    setUsers(response.data.data);
                    setTotalRows(response.data.total);
                }
                setLoaderVisibility(false);
            })
            .catch(exception => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const deleteUser = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`/privilege/user/${id}`)
            .then(response => {
                if (response?.data?.data) {
                    setUsers(current => current.filter(user => user.id !== response.data.data.id));
                }
                setLoaderVisibility(false);
            })
            .catch(exception => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const addUser = (user: any) => {
        setUsers(current => [...current, user]);
        userModalToggle();
    };

    useEffect(() => {
        getUsers();
    }, [currentPage]);

    const handlePageChange = page => {
        setCurrentPage(page);
        getUsers({ page });
    };

    const userTableColumns = [{
        name: 'Name', selector: (row: { name: any; }) => row.name, sortable: false,
    }, {
        name: 'Email', selector:(row: { email: any; }) => row.email, sortable: true, center: true,
    }, {
        name: 'Option',
        selector: (row: { id: any; system_defined: any; })=> <div className='d-flex align-items-right gap-2'>
            <IconBtn link={`${getPrivilegeRoute('user')}/${row.id}`} type={'view'}/>
            {!row.system_defined && <IconBtn confirm={true} onclick={() => deleteUser(row.id)} type={'delete'}/>}
        </div>,
        sortable: false,
        right: true,
    }];

    return (
        <div>
            <Breadcrumbs mainTitle="Users" parent="Privilege" title="Users" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Loader visibility={loaderVisibility} />
                            <CardHeader className='d-flex align-items-center justify-content-between'>
                                <H5>Users</H5>
                                <div>
                                    <div className='d-flex align-items-center gap-2'>
                                        <ButtonIcon attrBtn={{
                                            onClick: () => setUserModalVisibility(true)
                                        }} type={'add'} />
                                        <ButtonIcon attrBtn={{
                                            onClick: () => getUsers({ page: currentPage })
                                        }} type={'sync'} />
                                        <ButtonIcon attrBtn={{
                                            onClick: () => setSearchVisibility(!searchVisibility)
                                        }} type={'search'} />
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {searchVisibility && <SearchOptions callBack={params => getUsers({ ...params, page: currentPage })} searchOptions={searchOptions} />}
                                <DataTable
                                    pagination
                                    paginationServer
                                    columns={userTableColumns}
                                    data={users}
                                    highlightOnHover
                                    striped
                                    responsive
                                    paginationTotalRows={totalRows}
                                    onChangePage={handlePageChange}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={userModalVisibility} toggle={userModalToggle} centered>
                <ModalHeader>
                    Create User
                </ModalHeader>
                <ModalBody>
                    <UserForm callBack={addUser} />
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{ color: 'primary', onClick: userModalToggle }}>Close</Btn>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default UserIndex;
