import React, {Fragment, useEffect, useState} from "react";
import {Form, FormGroup} from "reactstrap";
import {useForm} from "react-hook-form";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {Btn} from "../../../../../AbstractElements";
import Loader from "../../../../../Layout/Loader";
import axiosConfig from "../../../../../Config/axios";
import BackendDropDown from "../../../../../CommonElements/DropDown/BackendDropDown";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import {CreditOfficerType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes"

interface creditOfficerFormProps {
    callBack?: (creditOfficer: CreditOfficerType) => void;
    creditOfficer?: CreditOfficerType;
    fixedValues?: any,
}

const CreditOfficerForm = ({callBack, creditOfficer, fixedValues}: creditOfficerFormProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);

    const {
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        formState: {errors},
    } = useForm();

    useEffect(() => {
        if (creditOfficer) {
            setValue("id", creditOfficer.id);
            setValue("center_id", creditOfficer.center_id);
            setValue("collector_id", creditOfficer.collector_id);
            setValue("status", creditOfficer.status);
        }
    }, [creditOfficer]);

    const onSubmit = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        creditOfficer ? updateCreditOfficer(data) : createCreditOfficer(data);
    };

    const createCreditOfficer = (data: any) => {
        axiosConfig
            .post(getApplicationRoute("center-collector"), {...data, ...fixedValues})
            .then((response: { data: { data: CreditOfficerType; }; }) => {
                console.log(response?.data?.data);
                if (response?.data?.data) {
                    console.log('Im in');
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    };

    const updateCreditOfficer = (data: any) => {
        if (creditOfficer) {
            axiosConfig
                .put(`${getApplicationRoute("center-collector")}/${creditOfficer.id}`, {...data, ...fixedValues})
                .then((response: { data: { data: CreditOfficerType; }; }) => {
                    if (response?.data?.data) {
                        callBack && callBack(response?.data?.data);
                    }
                    setLoaderVisibility(false);
                })
                .catch((exception: any) => {
                    ErrorHandler(exception, setError);
                    setLoaderVisibility(false);
                });
        }
    };

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Loader visibility={loaderVisibility}/>
                <BackendDropDown
                    fixedValues={fixedValues}
                    name={"collector_id"}
                    label={"Credit Officer"}
                    setValue={setValue}
                    nameColumn={"name"}
                    errors={errors}
                    url={"/application/employee"}
                    params={{designation_key: 'designation-type-collector'}}
                    options={{required: true}}
                />
                <BackendDropDown
                    fixedValues={fixedValues}
                    name={"center_id"}
                    type={"select-url"}
                    label={"Center"}
                    nameColumn={"name"}
                    setValue={setValue}
                    errors={errors}
                    url={"application/center"}
                    options={{required: true}}
                />
                <FormGroup>
                    <Btn
                        attrBtn={{
                            className: "d-block w-100 mt-4",
                            color: "primary",
                            type: "submit",
                        }}
                    >
                        {creditOfficer ? "Update" : "Create"}
                    </Btn>
                </FormGroup>
            </Form>
        </Fragment>
    );
};

export default CreditOfficerForm;
