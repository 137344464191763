import React, { useEffect, useRef, useState } from "react";
import { Col, Label, Row } from "reactstrap";
import BackendDropDown from "../../../CommonElements/DropDown/BackendDropDown";

interface searchOptionsProps {
    searchOptions: Array<any>;
    callBack: (params: any) => void;
}

const SearchOptions = ({ searchOptions = [], callBack }: searchOptionsProps) => {
    const [params, setParams] = useState({});
    const timeoutRef = useRef(null);

    function capitalizeFirstLetter(string: string) {
        return string?.charAt(0).toUpperCase() + string.slice(1);
    }

    const onChangeData = (name, value) => {
        const param = { [name]: value };
        const paramsSet = { ...params, ...param };
        setParams(paramsSet);
    };

    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            if (Object.keys(params).length) {
                callBack(params);
            }
        }, 1000);
    }, [params]);

    return (
        <Row>
            {searchOptions.map((searchOption, key) => {
                return (
                    <Col key={key}>
                        {searchOption.type === 'dropdown' ? (
                            <BackendDropDown
                                label={searchOption.label}
                                name={searchOption.name}
                                url={searchOption.url}
                                setValue={onChangeData}
                            />
                        ) : (
                            <>
                                <Label className={`col-form-label m-0`}>
                                    {capitalizeFirstLetter(searchOption.label ? searchOption.label : searchOption.name)}
                                </Label>
                                <input
                                    className="form-control"
                                    name={searchOption.name}
                                    placeholder={`Search by ${searchOption.label ? searchOption.label : searchOption.name}`}
                                    onChange={(event) => onChangeData(event.target.name, event.target.value)}
                                />
                            </>
                        )}
                    </Col>
                );
            })}
        </Row>
    );
};

export default SearchOptions;
