import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";

import OverallBalance from "../Component/OverallBalance";
import GreetingCard from "../Component/GreetingCard";
import WidgetsWrapper from "../Component/WidgetsWraper";
import RecentOrders from "../Component/RecentOrders";
import ActivityCard from "../Component/ActivityCard";
import RecentSales from "../Component/RecentSales";
import TimelineCard from "../Component/TimelineCard";
import PreAccountCard from "../Component/PreAccountCard";
import TotalUserAndFollower from "../Component/TotalUserAndFollower";
import PaperNote from "../Component/PaperNote";

const Dashboard = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Default" parent="Dashboard" title="Default" />
      <Container fluid={true}>
        <Row className="widget-grid">
          <GreetingCard />
          <WidgetsWrapper />
          <OverallBalance />
          <RecentOrders />
          <ActivityCard />
          <RecentSales />
          <TimelineCard />
          <PreAccountCard />
          <TotalUserAndFollower />
          <PaperNote />
        </Row>
      </Container>
    </Fragment>
  );
};

export default Dashboard;
