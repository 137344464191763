import {Btn, H5} from "../../../../../AbstractElements";
import CenterTable from "../../Center/Components/CenterTable";
import CenterForm from "../../Center/Components/Form";
import React, {useEffect, useRef, useState} from "react";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import SearchOptions from "../../../../CommonComponents/Search";
import {
    BranchType,
    CenterType,
    GroupType
} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";
import GroupForm from "../../Group/Component/Form";

interface branchCenterProps {
    branch: BranchType
}

const BranchCenters = ({branch}: branchCenterProps) => {
    const [centerModalVisibility, setCenterModalVisibility] = useState(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const centerTableRfe = useRef();

    const searchOptions = [
        {name: "name", label: "Center Name"},
        {name: "code", label: "Code"},
    ];

    const centerModalToggle = () => {
        setCenterModalVisibility(!centerModalVisibility);
    };

    const getCenters = (params: any) => {
        if (centerTableRfe.current) {
            centerTableRfe.current.getCenters(params);
        }
    }

    const addCenter = (center: CenterType) => {
        if (centerTableRfe.current) {
            centerTableRfe.current.addCenter(center);
        }
    }


    useEffect(() => {
        getCenters({});
    }, []);

    return (
        <Row>
            <Col sm="12" className='d-flex align-items-center justify-content-between'>
                <H5>{branch.branch_name} Centers</H5>
                <div>
                    <div className="d-flex align-items-center gap-2">
                        <ButtonIcon
                            attrBtn={{
                                onClick: () => setCenterModalVisibility(true),
                            }}
                            type={"add"}
                        />

                        <ButtonIcon
                            attrBtn={{
                                onClick: () => setSearchVisibility(!searchVisibility),
                            }}
                            type={"search"}
                        />

                        <ButtonIcon
                            attrBtn={{
                                onClick: getCenters,
                            }}
                            type={"sync"}
                        />
                    </div>
                </div>
            </Col>
            <Col sm={12}>
                {searchVisibility && (
                    <SearchOptions
                        callBack={(params: any) => getCenters(params)}
                        searchOptions={searchOptions}
                    />
                )}
                <CenterTable ref={centerTableRfe} actionsToHide={{delete: true}} columnsToHide={{branch_name: true}}
                             requestParams={{branch_id: branch?.id}}/>
            </Col>
            <Modal isOpen={centerModalVisibility} toggle={centerModalToggle} centered>
                <ModalHeader>Create center for {branch?.branch_name} branch </ModalHeader>
                <ModalBody>
                    <CenterForm fixedValues={{branch_id: branch?.id}} callBack={(center: CenterType) => {
                        addCenter(center);
                        centerModalToggle();
                    }}/>
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{color: "primary", onClick: centerModalToggle}}>
                        Close
                    </Btn>
                </ModalFooter>
            </Modal>
        </Row>
    )
}

export default BranchCenters;