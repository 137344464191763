import React, {useState} from 'react';
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import {Btn} from "../../../../AbstractElements";
import Loader from "../../../../Layout/Loader";
import {useForm} from "react-hook-form";
import axios from "../../../../Config/axios";
import {ErrorHandler} from "../../../../_helper/SystemCore/SystemCoreHelpers";
import {toast} from "react-toastify";

const UpdatePasswordForm = ({userID}) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);

    const {
        handleSubmit,
        register,
        setError,
        formState: {errors},
    } = useForm();

    const onSubmit = async (data) => {
        try {
            setLoaderVisibility(true);
            axios.put(`/authentication/change-password/${userID}`, data)
                .then(() => {
                    toast.success(`Password Updated`, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    setLoaderVisibility(false)
                }).catch((exception) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false)
            });
        } catch (exception) {
            ErrorHandler(exception, setError);
            setLoaderVisibility(false)
        }
    };

    return (
        <div>
            <Loader visibility={loaderVisibility}/>
            <Row className={'justify-content-center'}>
                <Col md={6}>
                    <Form onSubmit={handleSubmit(onSubmit)}
                          className='theme-form login-form'>
                        <FormGroup className='position-relative'>
                            <Label className='col-form-label m-0'>Current Password</Label>
                            <div className='position-relative'>
                                <input className='form-control' type={togglePassword ? 'text' : 'password'}
                                       name={'current_password'} {...register('current_password', {required: true})}
                                       placeholder='*********'/>
                            </div>
                            <span
                                className='d-block invalid-feedback'>{errors.password && (errors.password.message ? errors.password.message : 'Please provide a valid password')}</span>
                        </FormGroup>
                        <FormGroup className='position-relative'>
                            <Label className='col-form-label m-0'>Password</Label>
                            <div className='position-relative'>
                                <input className='form-control' type={togglePassword ? 'text' : 'password'}
                                       name={'password'} {...register('password', {required: true})}
                                       placeholder='*********'/>
                                <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                                    <span className={togglePassword ? '' : 'show'}></span>
                                </div>
                            </div>
                            <span
                                className='d-block invalid-feedback'>{errors.password && (errors.password.message ? errors.password.message : 'Please provide a valid password')}</span>
                        </FormGroup>
                        {togglePassword ? null : <FormGroup className='position-relative'>
                            <Label className='col-form-label m-0'>Confirm Password</Label>
                            <div className='position-relative'>
                                <input className='form-control' type={togglePassword ? 'text' : 'password'}
                                       name={'password_confirmation'} {...register('password_confirmation', {required: true})}
                                       placeholder='*********'/>
                            </div>
                            <span
                                className='d-block invalid-feedback'>{errors.password && (errors.password.message ? errors.password.message : 'Please provide a valid password')}</span>
                        </FormGroup>}
                        <Btn attrBtn={{
                            className: 'd-block w-100 mt-4',
                            color: 'primary',
                            type: 'submit',
                        }}>Update</Btn>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default UpdatePasswordForm;
