import React, {Fragment, useEffect, useState} from 'react';

import {Form, FormGroup} from 'reactstrap';

import {useForm} from "react-hook-form";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import BackendDropDown from "../../../../../CommonElements/DropDown/BackendDropDown";
import TextInput from "../../../../../CommonElements/Input/TextInput";
import {Btn} from "../../../../../AbstractElements";
import axiosConfig from "../../../../../Config/axios";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import {MemberType} from "../../../../../Data/Types/Application/ApplicationEmployeeDataTypes";

interface memberFormProps {
    member?: MemberType,
    fixedValues?: {
        member_type: string;
        center_id: number;
    },
    callBack?: (member: MemberType) => void,
}

const MemberForm = ({callBack, member, fixedValues}: memberFormProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: {errors},
        setValue,
        watch
    } = useForm();

    useEffect(() => {
        if (fixedValues?.center_id) {
            setValue('center_id', fixedValues.center_id);
        }
        if (fixedValues?.member_type) {
            setValue('member_type_id', fixedValues.member_type);
        }
    }, [fixedValues])

    useEffect(() => {
        if (member) {
            setValue('user_id', member.user?.id);
            setValue('member_type_id', member.member_type?.id);
            setValue('center_id', member.center?.id);
            setValue('group_id', member?.group?.group_id);
            setValue('gender_id', member.gender?.id);
            setValue('nic', member.nic);
            setValue('contact_number', member.phone);
            setValue('address', member.address);
            setValue('name', member.user?.name);
        }
    }, [member])

    const onSubmit = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        member ? updateMember(data) : createMember(data);
    }

    const createMember = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        axiosConfig
            .post(getApplicationRoute('member'), {...data, ...fixedValues})
            .then((response: { data: { data: MemberType }; }) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    }

    const updateMember = (data: any) => {
        if (member) {
            axiosConfig
                .put(`${getApplicationRoute('member')}/${member.id}`, data)
                .then((response: { data: { data: MemberType }; }) => {
                    if (response?.data?.data) {
                        callBack && callBack(response?.data?.data);
                    }
                    setLoaderVisibility(false);
                })
                .catch((exception: any) => {
                    ErrorHandler(exception, setError);
                    setLoaderVisibility(false);
                });
        }
    }

    return (<Fragment>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Loader visibility={loaderVisibility}/>
            <BackendDropDown fixedValues={fixedValues} name={'member_type_id'} params={{setting_key: 'member-type'}}
                             setValue={setValue} type={'select-url'} label={'Customer Type'} nameColumn={'name'}
                             value={member?.member_type?.id}
                             errors={errors} url={'system-core/configuration'} options={{required: true}}/>
            <BackendDropDown fixedValues={fixedValues} name={'center_id'} type={'select-url'} label={'Center'}
                             nameColumn={'name'} value={member?.center?.id} setValue={setValue} errors={errors}
                             url={'application/center'} options={{required: true}}/>
            {watch('center_id') &&
                <BackendDropDown fixedValues={fixedValues} name={'group_id'} type={'select-url'} label={'Group'}
                                 value={member?.group?.group_id} setValue={setValue} errors={errors}
                                 url={`application/get_or_create_available_groups/${watch('center_id')}`}
                                 options={{required: true}}/>}
            <TextInput fixedValues={fixedValues} name={'name'} type={'text'} label={'Name'} register={register}
                       errors={errors} placeholder={'Name'} options={{required: true}}/>
            <BackendDropDown fixedValues={fixedValues} name={'gender_id'} params={{setting_key: 'gender-type'}}
                             type={'select-url'}
                             label={'Gender'} nameColumn={'name'}
                             value={member?.gender?.id}
                             setValue={setValue}
                             errors={errors} url={'system-core/configuration'} options={{required: true}}/>
            <TextInput name={'nic'} type={'text'} label={'NIC'} register={register}
                       errors={errors} placeholder={'NIC'} options={{required: true}}/>

            <TextInput name={'contact_number'} type={'text'} label={'Contact Number'} register={register}
                       errors={errors} placeholder={'Contact Number'} options={{required: true}}/>
            <TextInput name={'address'} type={'text'} label={'Address'} register={register}
                       errors={errors} placeholder={'Address'} options={{required: true}}/>
            <FormGroup>
                <Btn attrBtn={{
                    className: 'd-block w-100 mt-4',
                    color: 'primary',
                    type: 'submit'
                }}>{member ? 'Update' : 'Create'}</Btn>
            </FormGroup>
        </Form>
    </Fragment>);
};

export default MemberForm;