import {Image} from "../../../AbstractElements";
import React, {useState} from "react";

const ProfilePicture = () => {
    const [url, setUrl] = useState('');

    const readUrl = (event: { target: { files: string | any[]; }; }) => {
        if (event.target.files.length === 0) return;
        const mimeType = event.target.files[0].type;

        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
            setUrl(reader.result);
        };
    };

    return <div className='user-image'>
        <div className='avatar'>
            <Image attrImage={{
                className: 'step1',
                alt: 'logo',
                src: `${url ? url : require('../../../assets/images/user/7.jpg')}`
            }}/>
        </div>
        <div className='icon-wrapper step2' data-intro='Change Profile image here'>
            <i className='icofont icofont-pencil-alt-5' onChange={(e) => readUrl(e)}>
                <input className='upload' type='file' onChange={(e) => readUrl(e)}/>
            </i>
        </div>
    </div>
}

export default ProfilePicture;