import React, {useRef, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import MemberForm from "../Component/Form";
import {Breadcrumbs, Btn, H5} from "../../../../../AbstractElements";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import SearchOptions from "../../../../CommonComponents/Search";
import MemberTable from "../Component/MemberTable";
import {MemberType} from "../../../../../Data/Types/Application/ApplicationEmployeeDataTypes";
import Loader from "../../../../../Layout/Loader";

const MemberIndex = () => {
    const [memberModalVisibility, setMemberModalVisibility] = useState(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const memberTableRef = useRef<{ addMember: (member: any) => void; getMembers: (params: any) => void }>(null);
    const [loaderVisibility] = useState(false);

    const searchOptions = [
        {name: 'name', label: 'Name'},
        {name: 'memberType', label: 'Customer Type'},
        {name: 'center', label: 'Center'},
        {name: 'nic', label: 'NIC'}
    ];

    const memberModalToggle = () => {
        setMemberModalVisibility(!memberModalVisibility);
    }

    const addMember = (member: MemberType) => {
        if (memberTableRef.current) {
            memberTableRef.current.addMember(member);
        }
        memberModalToggle();
    };

    const getMembers = (params: any) => {
        if (memberTableRef.current) {
            memberTableRef.current.getMembers(params);
        }
    };
    
    return (
        <div>
            <Breadcrumbs mainTitle="Customers" parent="Application" title="Customers"/>
            <Container fluid={true}>
                <Loader visibility={loaderVisibility} />
                <Row>
                    <Col sm="12">

                        <Card>
                            <CardHeader className='d-flex align-items-center justify-content-between'>
                                <H5>Customers</H5>
                                <div>
                                    <div className='d-flex align-items-center gap-2'>
                                        <ButtonIcon attrBtn={{
                                            onClick: () => setMemberModalVisibility(true)
                                        }} type={'add'}/>

                                        <ButtonIcon attrBtn={{
                                            onClick: getMembers
                                        }} type={'sync'}/>

                                        <ButtonIcon attrBtn={{
                                            onClick: () => setSearchVisibility(!searchVisibility)
                                        }} type={'search'}/>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {searchVisibility && <SearchOptions callBack={(params: any) => getMembers(params)}
                                                                    searchOptions={searchOptions}/>}
                                <MemberTable ref={memberTableRef}/>
                            </CardBody>
                            <Modal isOpen={memberModalVisibility} toggle={memberModalToggle} centered>
                                <ModalHeader>
                                    Create Customer
                                </ModalHeader>
                                <ModalBody>
                                    <MemberForm callBack={addMember}/>
                                </ModalBody>
                                <ModalFooter>
                                    <Btn attrBtn={{color: 'primary', onClick: memberModalToggle}}>Close</Btn>
                                </ModalFooter>
                            </Modal>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MemberIndex;