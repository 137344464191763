import React, {Fragment, useState} from 'react';
import {Btn} from '../../../../AbstractElements';
import {Form, FormGroup, Label} from 'reactstrap';
import axiosConfig from '../../../../Config/axios';
import {useForm} from "react-hook-form";
import TextInput from "../../../../CommonElements/Input/TextInput";
import {ErrorHandler} from "../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../Layout/Loader";

const UserForm = ({callBack, isUpdate}) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: {errors},
        getValues,
    } = useForm({
        mode: 'onChange'
    });


    const onSubmit = (data) => {
        clearErrors();
        setLoaderVisibility(true);
        if (togglePassword) {
            data.password_confirmation = data.password;
        }
        axiosConfig
            .post(`/privilege/user`, data)
            .then(response => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch(exception => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    };

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Loader visibility={loaderVisibility}/>
                <TextInput name={'name'} type={'text'} label={'Name'} register={register}
                           errors={errors} placeholder={'Kasun Samanpriya'} options={{required: 'Name is required'}}/>
                <TextInput name={'email'} type={'email'} label={'Email Address'} register={register}
                           errors={errors} placeholder={'Test@gmail.com'} options={{
                    required: 'Email is required',
                    pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: 'Please enter a valid email address'
                    }
                }}/>
                <FormGroup className='position-relative'>
                    <Label className='col-form-label m-0'>Password</Label>
                    <div className='position-relative'>
                        <input className='form-control' type={togglePassword ? 'text' : 'password'}
                               name={'password'} {...register('password', {required: 'Password is required'})}
                               placeholder='*********'/>
                        <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                            <span className={togglePassword ? '' : 'show'}></span>
                        </div>
                    </div>
                    <span
                        className='d-block invalid-feedback'>{errors.password && (errors.password.message ? errors.password.message : 'Please provide a valid password')}</span>
                </FormGroup>
                {!togglePassword && (
                    <FormGroup className='position-relative'>
                        <Label className='col-form-label m-0'>Confirm Password</Label>
                        <div className='position-relative'>
                            <input className='form-control' type='password'
                                   name={'password_confirmation'} {...register('password_confirmation', {
                                required: 'Please confirm your password',
                                validate: value =>
                                    value === getValues('password') || 'Passwords do not match'
                            })}
                                   placeholder='*********'/>
                        </div>
                        <span
                            className='d-block invalid-feedback'>{errors.password_confirmation && (errors.password_confirmation.message ? errors.password_confirmation.message : 'Passwords do not match')}</span>
                    </FormGroup>
                )}
                <FormGroup>
                    <Btn attrBtn={{
                        className: 'd-block w-100 mt-4',
                        color: 'primary',
                        type: 'submit'
                    }}>{isUpdate ? 'Update' : 'Create'}</Btn>
                </FormGroup>
            </Form>
        </Fragment>
    );
};

export default UserForm;
