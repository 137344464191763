import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {Breadcrumbs, H5} from '../../../../AbstractElements';
import DataTable from 'react-data-table-component';
import axiosConfig from '../../../../Config/axios';
import IconBtn from '../../../../CommonElements/IconButton';
import ButtonIcon from '../../../../CommonElements/ButtonIcon';
import {ErrorHandler} from '../../../../_helper/SystemCore/SystemCoreHelpers';
import Loader from '../../../../Layout/Loader';
import SearchOptions from '../../../CommonComponents/Search';
import {getPrivilegeRoute} from '../../../../Route/RouteIndex';

const PermissionIndex = () => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);

    const searchOptions = [
        {name: 'name', label: 'Key'},
        {name: 'display_name', label: 'Name'},
        {name: 'description'}
    ];

    const getPermissions = (params: any = {}) => {
        setLoaderVisibility(true);
        const config = {
            params: {
                ...params,
                page: params.page || currentPage,
                perPage: 10
            }
        };
        axiosConfig
            .get(getPrivilegeRoute('permission'), config)
            .then((response: { data: { data: any; total: number; }; }) => {
                if (response?.data?.data) {
                    setPermissions(response.data.data);
                    setTotalRows(response.data.total);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const syncPermissions = () => {
        setLoaderVisibility(true);
        const config = {};
        axiosConfig
            .post(`/privilege/sync/permissions`, config)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    setPermissions(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    useEffect(() => {
        getPermissions();
    }, [currentPage]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        getPermissions({page});
    };

    const permissionTableColumns = [
        {name: 'Key', selector: (row: { name: any; }) => row.name, sortable: false},
        {name: 'Name', selector: (row: { display_name: any; }) => row.display_name, sortable: true, center: true},
        {name: 'Description', selector: (row: { description: any; }) => row.description, sortable: true, center: true},
        {
            name: 'Option',
            selector: (row: { id: any; }) => (
                <div className='d-flex align-items-right gap-2'>
                    <IconBtn link={`${getPrivilegeRoute('permission')}/${row.id}`} type={'view'}/>
                </div>
            ),
            sortable: false,
            right: true
        }
    ];

    return (
        <div>
            <Breadcrumbs mainTitle="Permissions" parent="Privilege" title="Permissions"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Loader visibility={loaderVisibility}/>
                            <CardHeader className='d-flex align-items-center justify-content-between'>
                                <H5>Permissions</H5>
                                <div>
                                    <div className='d-flex align-items-center gap-2'>
                                        <ButtonIcon attrBtn={{
                                            onClick: syncPermissions
                                        }} type={'sync'}/>
                                        <ButtonIcon attrBtn={{
                                            onClick: () => setSearchVisibility(!searchVisibility)
                                        }} type={'search'}/>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {searchVisibility && (
                                    <SearchOptions
                                        callBack={(params: any) => getPermissions(params)}
                                        searchOptions={searchOptions}
                                    />
                                )}
                                <DataTable
                                    pagination
                                    paginationServer
                                    columns={permissionTableColumns}
                                    data={permissions}
                                    highlightOnHover
                                    striped
                                    responsive
                                    paginationTotalRows={totalRows}
                                    onChangePage={handlePageChange}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default PermissionIndex;
