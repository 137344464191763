import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axiosConfig from "../../../../../Config/axios";
import { getApplicationRoute } from "../../../../../Route/RouteIndex";
import { EmployeeType } from "../../../../../Data/Types/Application/ApplicationEmployeeDataTypes";
import { ErrorHandler } from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import IconBtn from "../../../../../CommonElements/IconButton";
import { TableViewType } from "../../../../../Data/Types/UtilityTypes";

const EmployeeTable = React.forwardRef(({
                                            requestParams = {},
                                            actionsToHide = {},
                                            columnsToHide = {}
                                        }: TableViewType, ref) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [employees, setEmployees] = useState<Array<EmployeeType>>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);

    React.useImperativeHandle(ref, () => ({
        getEmployees,
        addEmployee
    }));

    useEffect(() => {
        getEmployees({ page: currentPage });
    }, [currentPage]);

    const getEmployees = (params: any) => {
        setLoaderVisibility(true);
        const config = {
            params: {
                ...params,
                ...requestParams,
                page: params.page || currentPage,
                perPage: 10
            }
        };
        axiosConfig
            .get(getApplicationRoute('employee'), config)
            .then((response: { data: { data: Array<EmployeeType>; total: number } }) => {
                if (response?.data?.data) {
                    setEmployees(response.data.data);
                    setTotalRows(response.data.total);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const deleteEmployee = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`${getApplicationRoute('employee')}/${id}`, {})
            .then((response: { data: { data: EmployeeType } }) => {
                if (response?.data?.data) {
                    setEmployees((current: EmployeeType[]) => current.filter((employee: EmployeeType) => employee.id !== response?.data?.data?.id));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const addEmployee = (employee: EmployeeType) => {
        const employeeList = [...employees];
        const existingEmployeeIndex = employeeList.findIndex((existingEmployee: EmployeeType) => existingEmployee.id === employee.id);
        if (existingEmployeeIndex !== -1) {
            employeeList[existingEmployeeIndex] = employee;
        } else {
            employeeList.push(employee);
        }
        setEmployees(employeeList);
    };

    const employeeTableColumns = [{
        name: 'Name',
        selector: (employee: EmployeeType) => employee?.user?.name,
        sortable: false,
    }, {
        name: 'Designation',
        selector: (employee: EmployeeType) => employee?.designation?.name,
        sortable: false,
    }, {
        name: 'Branch',
        selector: (employee: EmployeeType) => employee?.branch?.branch_name,
        sortable: false,
    }, {
        name: 'NIC',
        selector: (employee: EmployeeType) => employee?.nic,
        sortable: true,
    }, {
        name: 'Option',
        selector: (employee: EmployeeType) => (
            <div className='d-flex align-items-right gap-2'>
                {!actionsToHide.view &&
                    <IconBtn link={`${getApplicationRoute('employee')}/${employee.id}`} type={'view'}/>}
                {!actionsToHide?.delete &&
                    <IconBtn confirm={true} onclick={() => deleteEmployee(employee.id)} type={'delete'}/>}
            </div>
        ),
        sortable: false,
        right: true,
    }];

    const checkColumnVisibility = (columns: any[] = []) => {
        return columns.filter((column) => !columnsToHide[column.name.toLowerCase().replace(/\s+/g, '_')]);
    };

    return (
        <Fragment>
            <Loader visibility={loaderVisibility}/>
            <DataTable
                pagination
                paginationServer
                columns={checkColumnVisibility(employeeTableColumns)}
                data={employees}
                highlightOnHover={true}
                striped={true}
                responsive={true}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                paginationPerPage={10}
            />
        </Fragment>
    );
});

export default EmployeeTable;
