import {Breadcrumbs, H5} from "../../../../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import React, {Fragment, useEffect, useState} from "react";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import {BootstrapTabContainer, BootstrapTabContent} from "../../../../../CommonElements/BootstrapTabs";
import SettingShow from "../Component/SettingShow";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";

const Settings = () => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [settings, setSettings] = useState([]);

    const getSettings = () => {
        setLoaderVisibility(true);
        const config = {};
        axiosConfig
            .get(`/system-core/setting`, config)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    setSettings(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    const syncSettings = () => {
        setLoaderVisibility(true);
        const config = {};
        axiosConfig
            .get(`/system-core/sync-setting`, config)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    getSettings();
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    useEffect(() => {
        getSettings();
    }, [])

    return (
        <Fragment>
            <Breadcrumbs mainTitle={'Setting'} parent="Settings" title="Configuration"/>
            <Loader visibility={loaderVisibility}/>
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardHeader className='d-flex align-items-center justify-content-between'>
                                        <H5>Settings</H5>
                                        <div className='d-flex align-items-center gap-2'>
                                            <ButtonIcon attrBtn={{
                                                onClick: syncSettings
                                            }} type={'sync'}/>
                                        </div>
                                    </CardHeader>
                                    <BootstrapTabContainer horizontal={false}>
                                        {settings.map((setting, key) => {
                                            return <BootstrapTabContent key={key} title={setting.name}
                                                                        icon={'icofont icofont-setting'}>
                                                <SettingShow setting={setting}/>
                                            </BootstrapTabContent>
                                        })}
                                    </BootstrapTabContainer>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    )
}

export default Settings;