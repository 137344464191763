import {Card, CardBody, ListGroup, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import axiosConfig from "../../../../Config/axios";
import {ErrorHandler} from "../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../Layout/Loader";
import {Col} from "react-bootstrap";
import ButtonIcon from "../../../../CommonElements/ButtonIcon";
import SearchOptions from "../../../CommonComponents/Search";
import {H5} from "../../../../AbstractElements";

const RoleListView = ({userID}) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [roles, setRoles] = useState([]);
    const [currentRole, setCurrentRole] = useState([]);
    const [searchVisibility, setSearchVisibility] = useState(false);
    const searchOptions = [{name: 'name', label: 'Key'}, {name: 'display_name', label: 'Name'}];

    useEffect(() => {
        getCurrentUserRoles();
    }, [userID]);

    const getRoles = (params = {}, currentRoles = []) => {
        setLoaderVisibility(true);
        axiosConfig
            .get(`/privilege/role`, {params: {...params, bypass_pagination: true}})
            .then(response => {
                if (response?.data?.data) {
                    setRoleIsOn(response.data.data, currentRoles);
                }
                setLoaderVisibility(false);
            })
            .catch(exception => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const setRoleIsOn = (rolesList, userRolesList) => {
        const rolesData = rolesList.map(role => ({
            ...role, isOn: !!userRolesList.find((currentRole) => currentRole.id === role.id)
        }));
        setRoles(rolesData);
    };

    const getCurrentUserRoles = () => {
        setLoaderVisibility(true);
        axiosConfig
            .get(`/privilege/role`, {params: {user_id: userID, bypass_pagination: true}})
            .then(response => {
                setLoaderVisibility(false);
                setCurrentRole(response.data.data);
                getRoles({}, response.data.data);
            })
            .catch(exception => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const handleToggle = (roleId, event) => {
        setLoaderVisibility(true);
        axiosConfig
            .get(`privilege/user/${roleId}/role`, {
                params: {
                    'user_id': userID, 'type': event?.target?.checked ? 'attach' : 'detach'
                }
            })
            .then(response => {
                if (response?.data?.data) {
                    setCurrentRole(response.data.data);
                    setRoleIsOn(roles, response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch(exception => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    return (
        <div>
            <Loader visibility={loaderVisibility}/>
            <CardBody>
                <Row>
                    <Col md={12} className="d-flex align-items-center justify-content-between">
                        <H5>Roles</H5>
                        <div>
                            <div className="d-flex align-items-center gap-2">
                                <ButtonIcon attrBtn={{
                                    onClick: () => setSearchVisibility(!searchVisibility)
                                }} type={'search'}/>
                            </div>
                        </div>
                    </Col>
                    {searchVisibility &&
                        <SearchOptions callBack={(params) => getRoles(params, currentRole)}
                                       searchOptions={searchOptions}/>}
                </Row>
                <div className="job-filter">
                    <div className="faq-form">
                        <div className="categories">
                            <ListGroup>
                                <Row>
                                    {roles.map((role, key) => (
                                        <Col sm="4" key={key}>
                                            <Card>
                                                <CardBody style={{height: '150px'}}
                                                          className="shadow-lg p-25 shadow-showcase text-center">
                                                    <h6>{role.display_name}</h6>
                                                    <label className="switch form-label">
                                                        <input type="checkbox"
                                                               checked={role.isOn}
                                                               onChange={(event) => handleToggle(role.id, event)}/>
                                                        <span className="switch-state"></span>
                                                    </label>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </ListGroup>
                        </div>
                    </div>
                </div>
            </CardBody>
        </div>
    );
}

export default RoleListView;
