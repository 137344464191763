import React, {Fragment, useEffect, useState} from 'react';

import {Form, FormGroup} from 'reactstrap';

import {useForm} from "react-hook-form";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {Btn} from "../../../../../AbstractElements";
import TextInput from "../../../../../CommonElements/Input/TextInput";
import Loader from "../../../../../Layout/Loader";
import axiosConfig from "../../../../../Config/axios";
import BackendDropDown from "../../../../../CommonElements/DropDown/BackendDropDown";

const ExtraFieldForm = ({callBack, extraField}) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);

    const {
        register, handleSubmit, setError, clearErrors, formState: {errors}, setValue, watch
    } = useForm();

    useEffect(() => {
        if (extraField) {
            setValue('field_type_id', extraField.field_type?.id);
            setValue('model', extraField.model);
            setValue('key', extraField.key);
            setValue('label', extraField.label);
            setValue('placeholder', extraField.placeholder);
            setValue('required', extraField.required);
        }
    }, [extraField])

    const onSubmit = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        extraField ? updateExtraField(data) : createExtraField(data);
    }

    const createExtraField = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        axiosConfig
            .post(`/system-core/extra-field`, data)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    }

    const updateExtraField = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        axiosConfig
            .put(`/system-core/extra-field/${extraField.id}`, data)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    }

    useEffect(() => {
        watch('label') ? setValue('key', (`${watch('model')} ${watch('label')}`).toLowerCase()
            .replace(/\s+/g, '-')) : setValue('key', '');
    }, [watch('label')]);


    return (<Fragment>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Loader visibility={loaderVisibility}/>
            <BackendDropDown name={'model'} type={'select-url'} label={'Model'} setValue={setValue}
                             errors={errors} url={'system-core/get-model-list'} options={{required: true}}/>
            <BackendDropDown name={'field_type_id'} params={{setting_key: 'extra-field-type'}} type={'select-url'}
                             label={'Field Type'} setValue={setValue}
                             errors={errors} url={'system-core/configuration'} options={{required: true}}/>
            <TextInput name={'label'} type={'text'} label={'label'} register={register}
                       errors={errors} placeholder={'Label'} options={{required: true}}/>
            <TextInput name={'key'} type={'text'} label={'Key'} readOnly={true} register={register}
                       errors={errors} placeholder={'Key'} options={{required: true}}/>
            <TextInput name={'placeholder'} type={'text'} label={'Placeholder'} register={register}
                       errors={errors} placeholder={'Placeholder'} options={{required: false}}/>
            <TextInput name={'required'} type={'checkbox'} label={'Required'} register={register}
                       errors={errors} placeholder={'Required'} options={{required: false}}/>
            <FormGroup>
                <Btn attrBtn={{
                    className: 'd-block w-100 mt-4',
                    color: 'primary',
                    type: 'submit'
                }}>{extraField ? 'Update' : 'Create'}</Btn>
            </FormGroup>
        </Form>
    </Fragment>);
};

export default ExtraFieldForm;