import React, {useEffect, useState} from "react";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import {AiOutlineDollar, AiOutlineUser} from 'react-icons/ai';
import Loader from "../../../../../Layout/Loader";
import {LoanDashboardType} from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";
import {MemberType} from "../../../../../Data/Types/Application/ApplicationEmployeeDataTypes";
import IconBtn from "../../../../../CommonElements/ButtonIcon";
import SimpleMemberView from "../../../Employee/Member/Component/SimpleMemberView";
import {Col, Modal, ModalBody, Row} from "reactstrap";
import LoanChart from "../../../Employee/Member/Component/LoanChart";

interface SimpleMemberViewProps {
    groupMemberID: any;
}

const SimpleMemberLoanView = ({groupMemberID}: SimpleMemberViewProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [member, setMember] = useState<MemberType>();
    const [memberModalVisibility, setMemberModalVisibility] = useState<boolean>(false);
    const [memberLoanVisibility, setLoanModalVisibility] = useState<boolean>(false);
    const [loans, setLoans] = useState<LoanDashboardType[]>([]);

    useEffect(() => {
        if (groupMemberID) {
            setLoaderVisibility(true);
            const config = {
                params: {
                    group_member_id: groupMemberID,
                    per_page: 2,
                    resource: 'dashboard',
                }
            }
            axiosConfig
                .get(getApplicationRoute(`loan`), config)
                .then((response: { data: { data: LoanDashboardType[]; }; }) => {
                    if (response?.data?.data) {
                        if (response?.data?.data?.length) {
                            setLoans(response.data.data);
                            setMember(response.data.data[0].member);
                        }
                    }
                    setLoaderVisibility(false);
                })
                .catch((exception: any) => {
                    ErrorHandler(exception);
                    setLoaderVisibility(false);
                });

        }
    }, [groupMemberID]);

    return (
        <div>
            <Loader visibility={loaderVisibility}/>
            <div>
                <div className='d-flex gap-2'>
                    {member ? <IconBtn icon={<AiOutlineUser/>}
                                       attrBtn={{onClick: () => setMemberModalVisibility(true)}}/> : null}
                    {loans.length ? <IconBtn icon={<AiOutlineDollar/>}
                                             attrBtn={{
                                                 onClick: () => setLoanModalVisibility(true),
                                                 color: 'info'
                                             }}/> : null}
                </div>
            </div>
            <Modal isOpen={memberModalVisibility} toggle={() => setMemberModalVisibility(false)} centered>
                <ModalBody>
                    {member ? <SimpleMemberView member={member}/> : null}
                </ModalBody>
            </Modal>
            <Modal isOpen={memberLoanVisibility} toggle={() => setLoanModalVisibility(false)} centered size={'large'}>
                <ModalBody>
                    {loans.length ? <Row>
                        <Col >
                            {loans.map((loan:LoanDashboardType,key) => <LoanChart key={key} loan={loan}/>)}
                        </Col>
                    </Row> : null}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default SimpleMemberLoanView;
