import React from 'react';
import {Button} from 'reactstrap';
import {FaDownload, FaEdit, FaExpandArrowsAlt, FaPlus, FaSync, FaTrashAlt} from "react-icons/fa";
import SweetAlert from "sweetalert2";
import {Link} from "react-router-dom";

interface iconBtnProps {
    type: string,
    confirm?: any,
    attrBtn?: any,
    link?: string,
    icon?: any,
    onclick?: () => void,
}

const IconBtn = ({confirm, type, attrBtn, link, icon, onclick}: iconBtnProps) => {

    const openConfirmAlert = () => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: confirm.text ? confirm.text : 'Once done, you will not be able to recover this!',
            icon: confirm.icon ? confirm.icon : 'warning',
            showCancelButton: confirm.showCancelButton ? confirm.showCancelButton : true,
            reverseButtons: confirm.reverseButtons ? confirm.reverseButtons : true,
            confirmButtonText: confirm.confirmButtonText ? confirm.confirmButtonText : 'Ok',
            cancelButtonText: confirm.cancelButtonText ? confirm.cancelButtonText : 'cancel',
        })
            .then((result: { value: any; }) => {
                if (result.value) {
                    onclick && onclick();
                }
            })
    }
    switch (type) {
        case 'delete':
            return <Button color="link" {...attrBtn}
                           style={{cursor: "pointer", color: '#FC4438FF', padding: 0}}> <FaTrashAlt
                onClick={confirm ? openConfirmAlert : onclick && onclick()}
            /></Button>
        case 'edit':
            return <Button color="link" {...attrBtn} style={{cursor: "pointer", color: '#16C7F9FF', padding: 0}}>
                <FaEdit/></Button>;
        case 'add':
            return <Button color="link" {...attrBtn} style={{cursor: "pointer", color: '#7366FFFF', padding: 0}}>
                <FaPlus
                /></Button>;
        case 'sync':
            return <Button color="link" {...attrBtn} style={{cursor: "pointer", color: '#1C012A', padding: 0}}>
                <FaSync/></Button>;
        case 'download':
            return <Button color="link" {...attrBtn} style={{cursor: "pointer", color: '#1C012A', padding: 0}}>
                <FaDownload
                /></Button>;
        case 'view':
            return <Link to={link}><Button color="link" {...attrBtn}
                                           style={{color: '#54BA4AFF', padding: 0}}><FaExpandArrowsAlt
            /></Button></Link>;
        default:
            return <Button color="link" {...attrBtn} onClick={confirm ? openConfirmAlert : onclick && onclick()}
                           style={{padding: 0}}>{icon}</Button>;
    }

};

export default IconBtn;