import React, {useEffect, useRef, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {Breadcrumbs, H5} from '../../../../../AbstractElements';
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import InstallmentCollectionsTable from "../Component/InstallmentCollectionTable";
import SearchOptions from "../../../../CommonComponents/Search";

const InstallmentCollectionIndex = () => {
    const [searchVisibility, setSearchVisibility] = useState(false);
    const [filters, setFilters] = useState({});
    const installmentCollectionTableRef = useRef(null);

    const searchOptions = [
        {name: 'loanId', label: 'Loan ID'},
        {name: 'member', label: 'Customer', type: 'dropdown', url: 'application/member'},
        {name: 'center', label: 'Center', type: 'dropdown', url: 'application/center'},
        {name: 'collector', label: 'Collector', type: 'dropdown', url: 'application/center-collector'}
    ];

    useEffect(() => {
        getInstallmentCollections();
    }, [filters]);

    const getInstallmentCollections = () => {
        if (installmentCollectionTableRef.current) {
            installmentCollectionTableRef.current.getInstallments(filters);
        }
    };

    return (
        <div>
            <Breadcrumbs mainTitle="" parent="Application" title="Installment Collection"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className='d-flex align-items-center justify-content-between'>
                                <H5>Installment Collections</H5>
                                <div>
                                    <div className='d-flex align-items-center gap-2'>
                                        <ButtonIcon attrBtn={{
                                            onClick: () => setSearchVisibility(!searchVisibility)
                                        }} type={'search'}/>

                                        <ButtonIcon attrBtn={{
                                            onClick: getInstallmentCollections
                                        }} type={'sync'}/>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {searchVisibility &&
                                    <SearchOptions callBack={(params) => {
                                        setFilters(params);
                                    }}
                                                   searchOptions={searchOptions}/>}
                                <InstallmentCollectionsTable ref={installmentCollectionTableRef}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default InstallmentCollectionIndex;
