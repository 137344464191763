import React from 'react';
import ReactApexChart from 'react-apexcharts';
import {currencyConfig} from "../../../../../Config/ApplicationConfig";
import {LoanDashboardType} from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";

interface LoanChartProps {
    loan: LoanDashboardType;
}

const LoanChart = ({loan}: LoanChartProps) => {
    return (
        <div className='monthly-profit'>
            <ReactApexChart
                type='donut'
                height={300}
                series={[loan?.paid, loan?.pending_to_pay, loan?.extended]}
                options={{
                    labels: ["Paid", "Pending", "Extended"],
                    chart: {
                        type: "donut",
                        height: 300,
                    },
                    dataLabels: {
                        enabled: true,
                    },
                    plotOptions: {
                        pie: {
                            expandOnClick: true,
                            donut: {
                                size: "83%",
                                labels: {
                                    show: true,
                                    name: {
                                        offsetY: 4,
                                    },
                                    total: {
                                        show: true,
                                        fontSize: "20px",
                                        fontFamily: "Rubik, sans-serif",
                                        fontWeight: 500,
                                        label: (loan?.amount + loan?.interest)?.toLocaleString('en-LK', currencyConfig),
                                        formatter: () => "Loan Amount",
                                    },
                                },
                            },
                        },
                    },
                    colors: ["#54BA4A", "#16C7F9FF", "#FFA941"],
                }}
            />
        </div>
    );
};

export default LoanChart;
