import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {CenterType, GroupType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";
import {Btn, H5} from "../../../../../AbstractElements";
import React, {useEffect, useRef, useState} from "react";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import GroupTable from "../../Group/Component/GroupTable";
import SearchOptions from "../../../../CommonComponents/Search";
import GroupForm from "../../Group/Component/Form";

interface centerGroupsProps {
    center: CenterType
}

const CenterGroups = ({center}: centerGroupsProps) => {
    const [groupModalVisibility, setGroupModalVisibility] = useState(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const searchOptions = [
        {name: "name", label: "Group Name"},
    ];
    const groupTableRef = useRef();

    const getGroups = (params: any) => {
        if (groupTableRef.current) {
            groupTableRef.current.getGroups(params);
        }
    }

    const addGroup = (group: GroupType) => {
        if (groupTableRef.current) {
            groupTableRef.current.addGroup(group);
        }
    }

    const groupModalToggle = () => {
        setGroupModalVisibility(!groupModalVisibility);
    };

    useEffect(() => {
        getGroups({});
    }, []);

    return (
        <Row>
            <Col md={12} className="d-flex align-items-center justify-content-between">
                <H5>{center?.name} center groups</H5>
                <div>
                    <div className="d-flex align-items-center gap-2">
                        <ButtonIcon
                            attrBtn={{onClick: () => setGroupModalVisibility(true)}}
                            type={"add"}
                        />
                        <ButtonIcon
                            attrBtn={{
                                onClick: getGroups,
                            }}
                            type={"sync"}
                        />
                        <ButtonIcon
                            attrBtn={{
                                onClick: () => setSearchVisibility(!searchVisibility),
                            }}
                            type={"search"}
                        />
                    </div>
                </div>
            </Col>
            <Col md={12}>
                {searchVisibility && (
                    <SearchOptions
                        callBack={(params: any) => getGroups(params)}
                        searchOptions={searchOptions}
                    />
                )}
                <GroupTable requestParams={{center_id: center?.id}} columnsToHide={{center: true}}
                            actionsToHide={{delete: true}} ref={groupTableRef}/>
            </Col>
            <Modal isOpen={groupModalVisibility} toggle={groupModalToggle} centered>
                <ModalHeader>Create Group for {center?.name} center</ModalHeader>
                <ModalBody>
                    <GroupForm fixedValues={{center_id: center?.id}} callBack={(group: GroupType) => {
                        addGroup(group);
                        groupModalToggle();
                    }}/>
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{color: "primary", onClick: groupModalToggle}}>
                        Close
                    </Btn>
                </ModalFooter>
            </Modal>
        </Row>
    );
}

export default CenterGroups;