import {Breadcrumbs, Btn, H5} from "../../../../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import React, {Fragment, useEffect, useState} from "react";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import {BootstrapTabContainer, BootstrapTabContent} from "../../../../../CommonElements/BootstrapTabs";
import ExtraFieldShow from "../Component/ExtraFieldShow";
import ExtraFieldForm from "../Component/Form";

const ExtraField = () => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [extraFieldModels, setExtraFieldModels] = useState([]);
    const [selectedExtraField] = useState();
    const [extraFieldModalVisibility, setExtraFieldModalVisibility] = useState(false);

    const extraFieldModalToggle = () => {
        setExtraFieldModalVisibility(!setExtraFieldModalVisibility);
    }

    const getExtraFields = () => {
        setLoaderVisibility(true);
        const config = {};
        axiosConfig
            .get(`/system-core/extra-field-get-model-list`, config)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    const extraFieldModelsObjects = response?.data?.data;
                    const extraFieldModelsArray = [];
                    Object.keys(extraFieldModelsObjects).map((extraFieldModelsObject) =>
                        extraFieldModelsArray.push(extraFieldModelsObjects[extraFieldModelsObject])
                    )
                    setExtraFieldModels(extraFieldModelsArray);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    const addExtraField = (extraField: { model: any; }) => {
        const extraFieldModelsList = [...extraFieldModels];
        const existingExtraFieldIndex = extraFieldModelsList.findIndex(existingExtraField => {
            return existingExtraField === extraField.model;
        });
        if (existingExtraFieldIndex !== -1) {
            extraFieldModelsList[existingExtraFieldIndex] = extraField.model;
        } else {
            extraFieldModelsList.push(extraField.model);
        }
        setExtraFieldModels(extraFieldModelsList);
        extraFieldModalToggle();
    }

    useEffect(() => {
        getExtraFields();
    }, [])

    return (
        <Fragment>
            <Breadcrumbs mainTitle="ExtraField" parent="Settings" title="Extra Field"/>
            <Loader visibility={loaderVisibility}/>
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader className='d-flex align-items-center justify-content-between'>
                                    <H5>Extra Fields</H5>
                                    <div>
                                        <div className='d-flex align-items-center gap-2'>
                                            <ButtonIcon attrBtn={{
                                                onClick: () => setExtraFieldModalVisibility(true)
                                            }} type={'add'}/>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <BootstrapTabContainer horizontal={false}>
                                        {extraFieldModels.map((extraFieldModel, key) => {
                                            return <BootstrapTabContent title={extraFieldModel.name} key={key}
                                                                        icon={'icofont icofont-dashboard'}>
                                                <ExtraFieldShow extraFieldModel={extraFieldModel.key}/>
                                            </BootstrapTabContent>
                                        })}
                                    </BootstrapTabContainer>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal isOpen={extraFieldModalVisibility} toggle={extraFieldModalToggle} centered>
                    <ModalHeader>
                        {selectedExtraField ? 'Update' : 'Create'} ExtraField
                    </ModalHeader>
                    <ModalBody>
                        <ExtraFieldForm callBack={addExtraField} extraField={selectedExtraField}/>
                    </ModalBody>
                    <ModalFooter>
                        <Btn attrBtn={{color: 'primary', onClick: extraFieldModalToggle}}>Close</Btn>
                    </ModalFooter>
                </Modal>
            </Container>
        </Fragment>
    )
}

export default ExtraField;