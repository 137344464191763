import React, {useRef, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import axiosConfig from '../../../../../Config/axios';
import { ErrorHandler } from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {Breadcrumbs, H5} from "../../../../../AbstractElements";
import Loader from "../../../../../Layout/Loader";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import SearchOptions from "../../../../CommonComponents/Search";
import {LoanType} from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";
import LoanReportTable from "../Component/PaymentCollectionTable";
import PaymentCollectionTable from "../Component/PaymentCollectionTable";

const PaymentCollectionIndex = () => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const navigate = useNavigate();
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const loanTableRef = useRef<any>(null);
    const searchOptions = [
        {name: 'group_member', label: 'Group Member'},
        {name: 'center_collector', label: 'Credit Officer'},
        {name: 'nic', label: 'NIC'},
        {name: 'branch_name', label: 'Branch'},
        {name: 'center_name', label: 'Center'},
        {name: 'status', label: 'Status'},
    ];

    const downloadPaymentCollection = (params: any) => {
        setLoaderVisibility(true);
        const config = {params: params, responseType: 'blob'};
        axiosConfig
            .get(("/report/installments-payment-collection"), config)
            .then((response: { data: BlobPart; }) => {
                const blob = new Blob([response.data],
                    {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                );
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'payment_collection.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const getLoans = (params: any) => {
        if (loanTableRef.current) {
            loanTableRef.current.getLoans(params);
        }
    };

    return (
        <div>
            <Breadcrumbs mainTitle="Report" parent="Application" title="Payment Collection Report" />
            <Container fluid={true}>
                <Loader visibility={loaderVisibility} />
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className='d-flex align-items-center justify-content-between'>
                                <H5>Loans</H5>
                                <div>
                                    <div className='d-flex gap-2'>

                                        <ButtonIcon attrBtn={{
                                            onClick: () => setSearchVisibility(!searchVisibility)
                                        }} type={'search'}/>

                                        <ButtonIcon attrBtn={{
                                            onClick: getLoans
                                        }} type={'sync'}/>

                                        <ButtonIcon attrBtn={{
                                            onClick: downloadPaymentCollection
                                        }} type={'download'}/>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {searchVisibility && <SearchOptions callBack={(loan: LoanType) => getLoans(loan)}
                                                                    searchOptions={searchOptions}/>}

                                <PaymentCollectionTable actionsToHide={{delete: true}} columnsToHide={{option: true}}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default PaymentCollectionIndex;
