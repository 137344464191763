import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row,} from "reactstrap";
import DataTable from "react-data-table-component";
import BranchForm from "../Component/Form";
import IconBtn from "../../../../../CommonElements/IconButton";
import {Breadcrumbs, Btn, H5} from "../../../../../AbstractElements";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import axiosConfig from "../../../../../Config/axios";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import SearchOptions from "../../../../CommonComponents/Search";
import {BranchType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";

const BranchIndex = () => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [branchData, setBranchData] = useState<BranchType[]>([]);
    const [branchModalVisibility, setBranchModalVisibility] = useState(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const searchOptions = [
        {name: "branch_name", label: "Branch Name"},
        {name: "code", label: "Code"},
    ];

    const branchModalToggle = () => {
        setBranchModalVisibility(!branchModalVisibility);
    };

    const getBranches = (params: any = {}) => {
        const config = {
            params: {
                ...params,
                page: params.page || currentPage,
                perPage: 10,
            }
        };
        setLoaderVisibility(true);
        axiosConfig
            .get(getApplicationRoute("branch"), config)
            .then((response: { data: { data: BranchType[]; total: number; } }) => {
                if (response?.data?.data) {
                    setBranchData(response.data.data);
                    setTotalRows(response.data.total);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const deleteBranch = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`${getApplicationRoute("branch")}/${id}`, {})
            .then((response: { data: { data: any } }) => {
                if (response?.data?.data) {
                    setBranchData((current) =>
                        current.filter((branch) => branch.id !== response.data.data.id)
                    );
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const addBranch = (branch: BranchType) => {
        const branchDataList = [...branchData];
        const existingBranchIndex = branchDataList.findIndex((existingBranch) => existingBranch.id === branch.id);
        if (existingBranchIndex !== -1) {
            branchDataList[existingBranchIndex] = branch;
        } else {
            branchDataList.push(branch);
        }
        setBranchData(branchDataList);
        branchModalToggle();
    };

    useEffect(() => {
        getBranches({page: currentPage});
    }, [currentPage]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const branchTableColumns = [
        {
            name: "Branch Name",
            selector: (branch: BranchType) => branch?.branch_name,
            sortable: false,
        },
        {
            name: "Code",
            selector: (branch: BranchType) => branch?.code,
            sortable: true,
            center: true,
        },
        {
            name: "Option",
            selector: (row: BranchType) => (
                <div className="d-flex align-items-right gap-2">
                    <IconBtn
                        link={`${getApplicationRoute("branch")}/${row.id}`}
                        type={"view"}
                    />
                    <IconBtn
                        confirm={true}
                        onclick={() => deleteBranch(row.id)}
                        type={"delete"}
                    />
                </div>
            ),
            sortable: false,
            right: true,
        },
    ];

    return (
        <div>
            <Breadcrumbs mainTitle="Branch" parent="Application" title="Branch"/>
            <Container fluid={true}>
                <Loader visibility={loaderVisibility}/>
                <Row>
                    <Col sm="12" className={"m-b-20"}></Col>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                <H5>Branch</H5>
                                <div>
                                    <div className="d-flex align-items-center gap-2">
                                        <ButtonIcon
                                            attrBtn={{
                                                onClick: () => setBranchModalVisibility(true),
                                            }}
                                            type={"add"}
                                        />
                                        <ButtonIcon
                                            attrBtn={{
                                                onClick: () => setSearchVisibility(!searchVisibility),
                                            }}
                                            type={"search"}
                                        />
                                        <ButtonIcon
                                            attrBtn={{
                                                onClick: () => getBranches({page: currentPage}),
                                            }}
                                            type={"sync"}
                                        />
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {searchVisibility && (
                                    <SearchOptions
                                        callBack={(params: any) => getBranches({...params, page: currentPage})}
                                        searchOptions={searchOptions}
                                    />
                                )}
                                <DataTable
                                    pagination
                                    paginationServer
                                    columns={branchTableColumns}
                                    data={branchData}
                                    highlightOnHover={true}
                                    striped={true}
                                    responsive={true}
                                    paginationTotalRows={totalRows}
                                    onChangePage={handlePageChange}
                                    paginationPerPage={10}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={branchModalVisibility} toggle={branchModalToggle} centered>
                <ModalHeader>Create Branch</ModalHeader>
                <ModalBody>
                    <BranchForm callBack={(branch: BranchType) => {
                        addBranch(branch);
                        branchModalToggle();
                    }}/>
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{color: "primary", onClick: branchModalToggle}}>
                        Close
                    </Btn>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default BranchIndex;
