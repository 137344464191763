import React, {useEffect, useState} from 'react';
import axiosConfig from '../../../../Config/axios';
import {useParams} from "react-router";
import {useForm} from "react-hook-form";
import {ErrorHandler} from "../../../../_helper/SystemCore/SystemCoreHelpers";
import {Col, Form, Row} from "reactstrap";
import TextInput from "../../../../CommonElements/Input/TextInput";
import {Btn} from "../../../../AbstractElements";
import Loader from "../../../../Layout/Loader";

interface updateUserFormProps {
    callBack?: (data: any) => void
    userData?: any
}

const UpdateUserForm = ({callBack, userData = {}}: updateUserFormProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [user, setUser] = useState<any>(userData);
    const params = useParams();

    const {
        handleSubmit,
        register,
        setError,
        clearErrors,
        formState: {errors},
        setValue
    } = useForm();

    useEffect(() => {
        if (user) {
            setValue('name', user.name);
            setValue('email', user.email);
        }
    }, [user, setValue]);

    useEffect(() => {
        if (!userData?.id) {
            setLoaderVisibility(true);
            const config = {};
            axiosConfig
                .get(`/privilege/user/${params.user}`, config)
                .then((response: any) => {
                    if (response?.data?.data) {
                        setUser(response.data.data);
                    }
                    setLoaderVisibility(false);
                })
                .catch((exception: any) => {
                    ErrorHandler(exception);
                    setLoaderVisibility(false);
                });
        }
    }, [params.user, userData?.id]);

    const onSubmit = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        axiosConfig
            .put(`/privilege/user/${user.id}`, data)
            .then((response: any) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    }

    return (
        <div>
            <Loader visibility={loaderVisibility}/>
            <Row className={'justify-content-center'}>
                <Col md={6}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <TextInput name={'name'} type={'text'} label={'Name'} register={register}
                                   errors={errors} placeholder={'Name'} options={{required: true}}/>
                        <TextInput name={'email'} type={'email'} label={'Email'} register={register}
                                   errors={errors} placeholder={'Test@email.com'} options={{required: true}}/>

                        <Btn attrBtn={{
                            className: 'd-block w-100 mt-4',
                            color: 'primary',
                            type: 'submit',
                        }}>{'Update'}</Btn>
                    </Form>
                </Col>
            </Row>
        </div>
    );

}
export default UpdateUserForm;
