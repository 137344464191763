import React, {Fragment, useEffect, useState} from "react";
import {Form, FormGroup} from "reactstrap";
import {useForm} from "react-hook-form";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {Btn} from "../../../../../AbstractElements";
import TextInput from "../../../../../CommonElements/Input/TextInput";
import Loader from "../../../../../Layout/Loader";
import axiosConfig from "../../../../../Config/axios";
import BackendDropDown from "../../../../../CommonElements/DropDown/BackendDropDown";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import {GroupType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";

interface groupFormProps {
    callBack?: (group: GroupType) => void;
    fixedValues?: any,
    group?: GroupType;
}

const GroupForm = ({callBack, group, fixedValues}: groupFormProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        formState: {errors},
    } = useForm();

    useEffect(() => {
        if (group) {
            setValue("name", group.name);
            setValue("center_id", group.center?.id);
        }
    }, [group]);

    const onSubmit = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        group ? updateGroup(data) : createGroup(data);
    };

    const createGroup = (data: any) => {
        axiosConfig
            .post(getApplicationRoute("group"), {...data, ...fixedValues})
            .then((response: { data: { data: GroupType } }) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    };

    const updateGroup = (data: any) => {
        if (group) {
            axiosConfig
                .put(`${getApplicationRoute("group")}/${group.id}`, data)
                .then((response: { data: { data: GroupType } }) => {
                    if (response?.data?.data) {
                        callBack && callBack(response?.data?.data);
                    }
                    setLoaderVisibility(false);
                })
                .catch((exception: any) => {
                    ErrorHandler(exception, setError);
                    setLoaderVisibility(false);
                });
        }
    };

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Loader visibility={loaderVisibility}/>
                <BackendDropDown
                    fixedValues={fixedValues}
                    name={"center_id"}
                    type={"select-url"}
                    label={"Center"}
                    setValue={setValue}
                    nameColumn={"name"}
                    errors={errors}
                    url={"/application/center"}
                    options={{required: true}}
                />
                <TextInput
                    fixedValues={fixedValues}
                    name={"name"}
                    type={"text"}
                    label={"Group Name"}
                    register={register}
                    errors={errors}
                    placeholder={"Group Name"}
                    options={{required: true}}
                />
                <FormGroup>
                    <Btn
                        attrBtn={{
                            className: "d-block w-100 mt-4",
                            color: "primary",
                            type: "submit",
                        }}>
                        {group ? "Update" : "Create"}
                    </Btn>
                </FormGroup>
            </Form>
        </Fragment>
    );
};

export default GroupForm;
