import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Breadcrumbs, Btn, H5} from '../../../../AbstractElements';
import DataTable from "react-data-table-component";
import axiosConfig from "../../../../Config/axios";
import IconBtn from "../../../../CommonElements/IconButton";
import ButtonIcon from "../../../../CommonElements/ButtonIcon";
import {ErrorHandler} from "../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../Layout/Loader";
import RoleForm from "../Component/Form";
import SearchOptions from "../../../CommonComponents/Search";

const RoleIndex = () => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [roles, setRoles] = useState([]);
    const [roleModalVisibility, setRoleModalVisibility] = useState(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const searchOptions = [
        {name: 'name', label: 'Key'},
        {name: 'display_name', label: 'Name'},
        {name: 'description'}
    ];

    const roleModalToggle = () => {
        setRoleModalVisibility(!roleModalVisibility);
    }

    const getRoles = (params: any = {}) => {
        setLoaderVisibility(true);
        const config = {
            params: {
                ...params,
                page: params.page || currentPage,
                perPage: 10,
            }
        };
        axiosConfig
            .get(`/privilege/role`, config)
            .then((response: { data: { data: any; total: number; }; }) => {
                if (response?.data?.data) {
                    setRoles(response.data.data);
                    setTotalRows(response.data.total);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }
    const deleteRole = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`/privilege/role/${id}`)
            .then((response: { data: { data:  any}; }) => {
                if (response?.data?.data) {
                    setRoles(current => current.filter(role => role.id !== response.data.data.id));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const addRole = (role: any) => {
        setRoles(current => [...current, role]);
        roleModalToggle();
    }

    useEffect(() => {
        getRoles();
    }, [currentPage]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        getRoles({page});
    };

    const roleTableColumns = [{
        name: 'Key', selector: (row: { name: any; }) => row.name, sortable: false,
    }, {
        name: 'Name', selector: (row: { display_name: any; }) => row.display_name, sortable: true,
    }, {
        name: 'Description', selector: (row: { description: any; }) => row.description, sortable: true, center: true,
    }, {
        name: 'Option', selector: (row: { id: any; system_defined: any; }) => (
            <div className='d-flex align-items-right gap-2'>
                <IconBtn link={`/privilege/role/${row.id}`} type={'view'}/>
                {!row.system_defined && <IconBtn confirm={true} onclick={() => deleteRole(row.id)} type={'delete'}/>}
            </div>
        ), sortable: false, right: true,
    }];

    return (
        <div>
            <Breadcrumbs mainTitle="Roles" parent="Privilege" title="Roles"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Loader visibility={loaderVisibility}/>
                            <CardHeader className='d-flex align-items-center justify-content-between'>
                                <H5>Roles</H5>
                                <div>
                                    <div className='d-flex align-items-center gap-2'>
                                        <ButtonIcon attrBtn={{onClick: () => setRoleModalVisibility(true)}}
                                                    type={'add'}/>
                                        <ButtonIcon attrBtn={{onClick: () => getRoles({page: currentPage})}}
                                                    type={'sync'}/>
                                        <ButtonIcon attrBtn={{onClick: () => setSearchVisibility(!searchVisibility)}}
                                                    type={'search'}/>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {searchVisibility &&
                                    <SearchOptions callBack={(params: any) => getRoles({...params, page: currentPage})}
                                                   searchOptions={searchOptions}/>}
                                <DataTable
                                    pagination
                                    paginationServer
                                    columns={roleTableColumns}
                                    data={roles}
                                    highlightOnHover
                                    striped
                                    responsive
                                    paginationTotalRows={totalRows}
                                    onChangePage={handlePageChange}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={roleModalVisibility} toggle={roleModalToggle} centered>
                <ModalHeader>
                    Create Role
                </ModalHeader>
                <ModalBody>
                    <RoleForm callBack={addRole}/>
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{color: 'primary', onClick: roleModalToggle}}>Close</Btn>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default RoleIndex;
