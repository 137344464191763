import {Breadcrumbs, H5, H6, LI, P, UL} from "../../../../../AbstractElements";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import Loader from "../../../../../Layout/Loader";
import {BootstrapTabContainer, BootstrapTabContent} from "../../../../../CommonElements/BootstrapTabs";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import MemberForm from "../Component/Form";
import ContactList from "../../../../SystemCore/Contact/Component/ContactList";
import {MemberType} from "../../../../../Data/Types/Application/ApplicationEmployeeDataTypes";
import {Map, Phone} from "react-feather";
import ProfilePicture from "../../../../CommonComponents/MultiMedia/ProfilePicture";
import LoanTable from "../../../Loan/Loan/Component/LoanTable";
import MemberLoan from "../Component/MemberLoan";
import InstallmentCollectionsTable from "../../../Loan/InstallmentCollection/Component/InstallmentCollectionTable";

const MemberShow = () => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [member, setMember] = useState<MemberType>();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setLoaderVisibility(true);
        const config = {};
        axiosConfig
            .get(`${getApplicationRoute('member')}/${params.member}`, {config})
            .then((response: { data: { data: MemberType; }; }) => {
                if (response?.data?.data) {
                    setMember(response.data.data);
                } else {
                    navigate(getApplicationRoute('member'));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
                navigate(getApplicationRoute('member'));
            });
    }, [params.member]);

    const deleteMember = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`${getApplicationRoute('member')}/${id}`, {})
            .then((response: { data: { data: MemberType }; }) => {
                if (response?.data?.data) {
                    navigate(getApplicationRoute('member'));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    return (<div>
        <Breadcrumbs mainTitle="Customer Profile" parent="Application"
                     subParentLink={`${getApplicationRoute('customer')}`}
                     subParent={'Customers'} title={member?.user?.name}/>
        <Container fluid={true}>
            <div className="user-profile">
                <Loader visibility={loaderVisibility}/>
                <Row>
                    <Col sm='12' lg='3'>
                        <Card className='hovercard text-center'>
                            <CardHeader className='social-profile'>
                                <div className='d-flex align-items-center gap-2'>
                                </div>
                            </CardHeader>
                            <ProfilePicture/>
                            <div className='info'>
                                <Row className='step3' data-intro='This is the your details'>
                                    <Col sm='12' lg='12' className='order-sm-0 order-xl-1'>
                                        <div className='user-designation'>
                                            <div className='title'>
                                                <a>
                                                    {member?.user?.name}
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='12' lg='12' className='order-sm-1 order-xl-0'>
                                        <Row>
                                            <Col>
                                                <div className='ttl-info text-center'>
                                                    <H6>
                                                        {member?.member_type?.name}
                                                    </H6>
                                                    <H6>
                                                        {member?.center?.name}
                                                    </H6>
                                                    <H6>
                                                        {member?.nic}
                                                    </H6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <CardBody>
                                <div className='social-network theme-form'>
                                    <span className='f-w-600 text-danger'>Danger Zone</span>
                                    <ButtonIcon confirm={true} onclick={() => deleteMember()} type={'delete'}/></div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <div className='collection-filter-block'>
                                    <UL attrUL={{className: 'pro-services'}}>
                                        <LI attrLI={{className: 'border-0'}}>
                                            <div className='media'>
                                                <Phone/>
                                                <div className='media-body'>
                                                    <H5>{'Phone'}</H5>
                                                    <P>{member?.phone ? member?.phone : '-'}</P>
                                                </div>
                                            </div>
                                        </LI>
                                        <LI attrLI={{className: 'border-0'}}>
                                            <div className='media'>
                                                <Map/>
                                                <div className='media-body'>
                                                    <H5>{'Address'}</H5>
                                                    <P>{member?.address ? member?.address : '-'}</P>
                                                </div>
                                            </div>
                                        </LI>
                                    </UL>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm='12' lg='9'>
                        <Card className='hovercard'>
                            <CardBody>
                                <BootstrapTabContainer>
                                    <BootstrapTabContent title={'Dashboard'} icon={"icofont icofont-dashboard"}>
                                        This is Dashboard
                                    </BootstrapTabContent>

                                    <BootstrapTabContent title={'Loan'} icon={'icofont icofont-money'}>
                                        <LoanTable actionsToHide={{delete: true}} columnsToHide={{member: true}}
                                                   requestParams={{'member_id': member?.id}}/>
                                    </BootstrapTabContent>

                                    <BootstrapTabContent title={'Contact'} icon={'icofont icofont-contacts'}>
                                        <ContactList relatedKey={"member"} relatedID={member?.id}/>
                                    </BootstrapTabContent>

                                    <BootstrapTabContent title={'Update'} icon={'icofont icofont-ui-edit'}>
                                        <Row className={'justify-content-center'}>
                                            <Col md={6}> <MemberForm member={member}
                                                                     callBack={(member: MemberType) => {
                                                                         setMember(member)
                                                                     }}/>
                                            </Col>
                                        </Row>
                                    </BootstrapTabContent>
                                    <BootstrapTabContent title={'Activity Log'} icon={'icofont icofont-list'}>
                                        <h6>Activity log</h6>
                                    </BootstrapTabContent>

                                </BootstrapTabContainer>
                            </CardBody>
                        </Card>
                        <Row className={'justify-content-center'}>
                            <Col md={12}>
                                <Card>
                                    <CardBody>
                                        <BootstrapTabContainer>
                                            <BootstrapTabContent title={'Loan Dashboard'}
                                                                 icon={"icofont icofont-dashboard"}>
                                                <MemberLoan memberID={member?.id}/>
                                            </BootstrapTabContent>
                                            <BootstrapTabContent title={'Loan Installments'} icon={'icofont icofont-money'}>
                                                <InstallmentCollectionsTable actionsToHide={{delete: true}} columnsToHide={{member: true}}
                                                                             requestParams={{'member_id': member?.id}}/>
                                            </BootstrapTabContent>
                                        </BootstrapTabContainer>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
    </div>);
}

export default MemberShow;