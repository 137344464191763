import {Breadcrumbs, H5, H6, LI, P, UL} from "../../../../../AbstractElements";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import Loader from "../../../../../Layout/Loader";
import {BootstrapTabContainer, BootstrapTabContent} from "../../../../../CommonElements/BootstrapTabs";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import EmployeeForm from "../Component/Form";
import {EmployeeType} from "../../../../../Data/Types/Application/ApplicationEmployeeDataTypes";
import ContactList from "../../../../SystemCore/Contact/Component/ContactList";
import {Map, Phone} from "react-feather";
import ProfilePicture from "../../../../CommonComponents/MultiMedia/ProfilePicture";
import InstallmentTable from "../../../Loan/Installment/Component/InstallmentTable";
import EmployeeCreditOfficer from "../Component/EmployeeCreditOfficer";

const EmployeeShow = () => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [employee, setEmployee] = useState<EmployeeType>();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setLoaderVisibility(true);
        const config = {};
        axiosConfig
            .get(`${getApplicationRoute('employee')}/${params.employee}`, {config})
            .then((response: { data: { data: EmployeeType }; }) => {
                if (response?.data?.data) {
                    setEmployee(response.data.data);
                } else {
                    navigate(getApplicationRoute('employee'));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
                navigate(getApplicationRoute('employee'));
            });
    }, [params.employee]);


    const deleteEmployee = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`${getApplicationRoute('employee')}/${id}`, {})
            .then((response: { data: { data: EmployeeType }; }) => {
                if (response?.data?.data) {
                    navigate(getApplicationRoute('employee'));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    return (<div>
        <Breadcrumbs mainTitle="Employee Profile" parent="Application"
                     subParentLink={`${getApplicationRoute('employee')}`}
                     subParent={'Employees'} title={employee?.name}/>
        <Container fluid={true}>
            <div className="user-profile">
                <Loader visibility={loaderVisibility}/>
                <Row>
                    <Col sm='12' lg='3'>
                        <Card className='hovercard text-center'>
                            <CardHeader className='social-profile'>
                                <div className='d-flex align-items-center gap-2'>
                                </div>
                            </CardHeader>
                            <ProfilePicture/>
                            <div className='info'>
                                <Row className='step3' data-intro='This is the your details'>
                                    <Col sm='12' lg='12' className='order-sm-0 order-xl-1'>
                                        <div className='user-designation'>
                                            <div className='title'>
                                                <a>
                                                    {employee?.user?.name}
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='12' lg='12' className='order-sm-1 order-xl-0'>
                                        <Row>
                                            <Col>
                                                <div className='ttl-info text-center'>
                                                    <H6>
                                                        {employee?.designation?.name}
                                                    </H6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6' lg='12' className='order-sm-1 order-xl-0'>
                                        <Row>
                                            <Col>
                                                <div className='ttl-info text-center'>
                                                    <H6>
                                                        {employee?.nic}
                                                    </H6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>

                            <CardBody>
                                <div className='social-network theme-form '>
                                    <span className='f-w-600 text-danger'>Danger Zone</span>
                                    <ButtonIcon confirm={true} onclick={() => deleteEmployee()} type={'delete'}/></div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <div className='collection-filter-block'>
                                    <UL attrUL={{className: 'pro-services'}}>
                                        <LI attrLI={{className: 'border-0'}}>
                                            <div className='media'>
                                                <Phone/>
                                                <div className='media-body'>
                                                    <H5>{'Phone'}</H5>
                                                    <P>{employee?.phone ? employee?.phone : '-'}</P>
                                                </div>
                                            </div>
                                        </LI>
                                        <LI attrLI={{className: 'border-0'}}>
                                            <div className='media'>
                                                <Map/>
                                                <div className='media-body'>
                                                    <H5>{'Address'}</H5>
                                                    <P>{employee?.address ? employee?.address : '-'}</P>
                                                </div>
                                            </div>
                                        </LI>
                                    </UL>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm='12' lg='9'>
                        <Card className='hovercard'>
                            <CardBody>
                                <BootstrapTabContainer>
                                    <BootstrapTabContent title={'Dashboard'} icon={"icofont icofont-dashboard"}>
                                        This is Dashboard
                                    </BootstrapTabContent>

                                    <BootstrapTabContent title={'Credit Officer'} icon={"icofont icofont-dashboard"}>
                                        <EmployeeCreditOfficer collector={employee}/>
                                    </BootstrapTabContent>

                                    <BootstrapTabContent title={'Contact'} icon={'icofont icofont-contacts'}>
                                        <ContactList relatedKey={"employee"} relatedID={employee?.id}/>
                                    </BootstrapTabContent>
                                    {
                                        employee?.collector ?
                                            <BootstrapTabContent title={'Collections'}
                                                                 icon={'icofont icofont-contacts'}>
                                                <InstallmentTable requestParams={{'collector_id': employee?.id}}/>
                                            </BootstrapTabContent> : null
                                    }

                                    <BootstrapTabContent title={'Update'} icon={'icofont icofont-ui-edit'}>
                                        <Row className={'justify-content-center'}>
                                            <Col md={6}>
                                                <EmployeeForm employee={employee}
                                                              callBack={(employee: EmployeeType) => {
                                                                  setEmployee(employee)
                                                              }}/>
                                            </Col>
                                        </Row>
                                    </BootstrapTabContent>

                                    <BootstrapTabContent title={'Activity Log'} icon={'icofont icofont-list'}>
                                        <h6>Activity log</h6>
                                    </BootstrapTabContent>

                                </BootstrapTabContainer>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    </div>);
}

export default EmployeeShow;