import React, {Fragment, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import DataTable from "react-data-table-component";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import IconBtn from "../../../../../CommonElements/IconButton";
import Loader from "../../../../../Layout/Loader";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import {Breadcrumbs, Btn, H5} from "../../../../../AbstractElements";
import SearchOptions from "../../../../CommonComponents/Search";
import InstallmentCollectorForm from "../Component/Form";
import {
    InstallmentCollectorType,
} from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";

const InstallmentCollectorIndex = () => {
    const [loaderVisibility, setLoaderVisibility] = useState<boolean>(false);
    const [installmentCollectors, setInstallmentCollectors] = useState<InstallmentCollectorType[]>([]);
    const [installmentCollectorModalVisibility, setInstallmentCollectorModalVisibility] = useState<boolean>(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const searchOptions = [
        {name: 'installment', label: 'Installment Amount'},
        {name: 'collector', label: 'Collector'},
    ];

    const installmentCollectorModalToggle = () => {
        setInstallmentCollectorModalVisibility(!installmentCollectorModalVisibility);
    }

    const getInstallmentCollectors = (params: any) => {
        setLoaderVisibility(true);
        const config = {params: params};
        axiosConfig
            .get(`/application/installment-collector`, config)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    setInstallmentCollectors(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    const addInstallmentCollector = (installmentCollector:InstallmentCollectorType) => {
        const installmentCollectorList = [...installmentCollectors];
        const existingInstallmentCollectorIndex = installmentCollectorList.findIndex((existingInstallmentCollector:InstallmentCollectorType) => {
            return existingInstallmentCollector.id === installmentCollector.id;
        });
        if (existingInstallmentCollectorIndex !== -1) {
            installmentCollectorList[existingInstallmentCollectorIndex] = installmentCollector;
        } else {
            installmentCollectorList.push(installmentCollector);
        }
        setInstallmentCollectors(installmentCollectorList);
        installmentCollectorModalToggle();
    }

    const deleteInstallmentCollector = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`/application/installment-collector/${id}`, {})
            .then((response: { data: { data: { id: any; }; }; }) => {
                if (response?.data?.data) {
                    setInstallmentCollectors(current => current.filter(user => {
                        return user.id !== response?.data?.data?.id;
                    }));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    useEffect(() => {
        getInstallmentCollectors({});
    }, [])


    const installmentCollectorTableColumns = [{
        name: 'Installment',
        selector: (installmentCollector: { installment: { installment_amount: any; }; }) => installmentCollector?.installment?.installment_amount,
        sortable: false,
    },
        {
            name: 'Collector',
            selector: (installmentCollector: { collector: { nic: any; }; }) => installmentCollector?.collector?.nic,
            sortable: false,
        },
        {
        name: 'Paid Date',
        selector: (row: { paid_date: any; }) => row.paid_date,
        sortable: false,
    },
        {
        name: 'Amount',
        selector: (row: { amount: any; }) => row.amount,
        sortable: false,
    },
        {
            name: 'Option',
            selector: (row: { id: any; }) =>
                <div className='d-flex align-items-right gap-2'>
                    <IconBtn confirm={true} onclick={() => deleteInstallmentCollector(row.id)} type={'delete'}/>
                </div>,
            sortable: false,
        },];

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Collectors" parent="Application" title="Installment Collectors"/>
            <Container fluid={true}>
                <Loader visibility={loaderVisibility}/>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className='d-flex align-items-center justify-content-between'>
                                <H5>Installment Collectors</H5>
                                <div>
                                    <div className='d-flex align-items-center gap-2'>
                                        <ButtonIcon attrBtn={{
                                            onClick: () => setInstallmentCollectorModalVisibility(true)
                                        }} type={'add'}/>
                                        <ButtonIcon attrBtn={{
                                            onClick: getInstallmentCollectors
                                        }} type={'sync'}/>
                                        <ButtonIcon attrBtn={{
                                            onClick: () => setSearchVisibility(!searchVisibility)
                                        }} type={'search'}/>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {searchVisibility && <SearchOptions callBack={(params: any) => getInstallmentCollectors(params)}
                                                                    searchOptions={searchOptions}/>}
                                <DataTable pagination paginationServer columns={installmentCollectorTableColumns}
                                           data={installmentCollectors}
                                           highlightOnHover={true}
                                           striped={true} responsive={true}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={installmentCollectorModalVisibility} toggle={installmentCollectorModalToggle} centered>
                <ModalHeader>
                    Create Installment Collector
                </ModalHeader>
                <ModalBody>
                    <InstallmentCollectorForm callBack={(installmentCollector: InstallmentCollectorType) => {
                        addInstallmentCollector(installmentCollector);
                        installmentCollectorModalToggle();
                    }}/>
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{color: 'primary', onClick: installmentCollectorModalToggle}}>Close</Btn>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default InstallmentCollectorIndex;