import React, {ForwardedRef, Fragment, useEffect, useState} from "react";
import {TableViewType} from "../../../../../Data/Types/UtilityTypes";
import DataTable from "react-data-table-component";
import {CreditOfficerType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";
import axiosConfig from "../../../..../../../../Config/axios";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import IconBtn from "../../../../../CommonElements/IconButton";
import Loader from "./.../../../../../../../Layout/Loader";
import {InstallmentCollectorType} from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";

const CreditOfficerTable = React.forwardRef(({
                                                   requestParams = {},
                                                   actionsToHide = {},
                                                   columnsToHide = {}
                                               }: TableViewType, ref: ForwardedRef<any>) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [creditOfficers, setCreditOfficers] = useState<CreditOfficerType[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    React.useImperativeHandle(ref, () => ({
        getCreditOfficers,
        addCreditOfficer
    }));

    const getCreditOfficers = (params: any) => {
        setLoaderVisibility(true);
        const config = { params: {...params, ...requestParams}};
        axiosConfig
            .get(getApplicationRoute("center-collector"), config)
            .then((response: { data: { data: Array<InstallmentCollectorType>; total: number } }) => {
                if (response?.data?.data) {
                    setCreditOfficers(response.data.data);
                    setTotalRows(response.data.total);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage: number) => {
        setPerPage(newPerPage);
        setCurrentPage(1);
    };

    const deleteCreditOfficer = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`${getApplicationRoute("center-collector")}/${id}`, {})
            .then((response: { data: { data: CreditOfficerType } }) => {
                if (response?.data?.data) {
                    setCreditOfficers((current) =>
                        current.filter((creditOfficer) => {
                            return creditOfficer.id !== response?.data?.data?.id;
                        })
                    );
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const addCreditOfficer = (creditOfficer: CreditOfficerType) => {
        const creditOfficerList = [...creditOfficers];
        const existingCreditOfficerIndex = creditOfficerList.findIndex((existingCreditOfficer: CreditOfficerType) => {
            return existingCreditOfficer.id === creditOfficer.id;
        });
        if (existingCreditOfficerIndex !== -1) {
            creditOfficerList[existingCreditOfficerIndex] = creditOfficer;
        } else {
            creditOfficerList.push(creditOfficer);
        }
        setCreditOfficers(creditOfficerList);
    };

    const creditOfficerTableColumns = [
        {
            name: "Center Name",
            selector: (creditOfficer: CreditOfficerType) => creditOfficer.center?.name,
            sortable: true,
        },
        {
            name: "Center Code",
            selector: (creditOfficer: CreditOfficerType) => creditOfficer.center?.code,
            sortable: true,
        },
        {
            name: "Credit Officer",
            selector: (creditOfficer: CreditOfficerType) => creditOfficer.collector?.name,
            sortable: true,
        },
        {
            name: "Credit Officer NIC",
            selector: (creditOfficer: CreditOfficerType) => creditOfficer.collector?.nic,
            sortable: true,
        },
        {
            name: "Option",
            cell: (creditOfficer: CreditOfficerType) => (
                <div className="d-flex align-items-right gap-2">
                    {!actionsToHide.show && <IconBtn
                        link={`${getApplicationRoute("center-collector")}/${creditOfficer.id}`}
                        type={"view"}
                    />}
                    {!actionsToHide.delete && (
                        <IconBtn
                            confirm={true}
                            onclick={() => deleteCreditOfficer(creditOfficer.id)}
                            type={"delete"}
                        />
                    )}
                </div>
            ),
        },
    ];

    useEffect(() => {
    getCreditOfficers({page: currentPage, perPage});
}, [currentPage, perPage]);

    const checkColumnVisibility = (columns: any[] = []) => {
        return columns.filter((column) => !columnsToHide[column.name.toLowerCase().replace(/\s+/g, '_')])
    }

    return (<Fragment>
        <Loader visibility={loaderVisibility}/>
        <DataTable
            pagination
            paginationServer
            highlightOnHover={true}
            striped={true}
            responsive={true}
            data={creditOfficers}
            columns={checkColumnVisibility(creditOfficerTableColumns)}
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationPerPage={perPage}
        />
    </Fragment>)
});
export default CreditOfficerTable

