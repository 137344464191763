import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Breadcrumbs, Btn, H5} from '../../../../../AbstractElements';

import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import SearchOptions from "../../../../CommonComponents/Search";
import EmployeeForm from "../Component/Form";
import EmployeeTable from "../Component/EmployeeTable";
import {EmployeeType} from "../../../../../Data/Types/Application/ApplicationEmployeeDataTypes";


const EmployeeIndex = () => {
    const [employeeModalVisibility, setEmployeeModalVisibility] = useState<boolean>(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const employeeTableRef = React.useRef();
    const searchOptions = [
        {name: 'name', label: 'Name'},
        {name: 'designation', label: 'Designation'},
        {name: 'branch', label: 'Branch'},
        {name: 'nic', label: 'NIC'},
        {name: 'contact', label: 'Contact'}
    ];

    const employeeModalToggle = () => {
        setEmployeeModalVisibility(!employeeModalVisibility);
    }

    const getEmployees = (params: any) => {
        if (employeeTableRef.current) {
            employeeTableRef.current.getEmployees(params);
        }
    }

    const addEmployee = (employee: EmployeeType) => {
        if (employeeTableRef.current) {
            employeeTableRef.current.addEmployee(employee);
        }
    }

    useEffect(() => {
        getEmployees({});
    }, [])


    return (<div>
            <Breadcrumbs mainTitle="Employees" parent="Application" title="Employees"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className='d-flex align-items-center justify-content-between'>
                                <H5>Employees</H5>
                                <div>
                                    <div className='d-flex align-items-center gap-2'>
                                        <ButtonIcon attrBtn={{
                                            onClick: () => setEmployeeModalVisibility(true)
                                        }} type={'add'}/>

                                        <ButtonIcon attrBtn={{
                                            onClick: getEmployees
                                        }} type={'sync'}/>
                                        <ButtonIcon attrBtn={{
                                            onClick: () => setSearchVisibility(!searchVisibility)
                                        }} type={'search'}/>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {searchVisibility &&
                                    <SearchOptions callBack={(params: any) => getEmployees(params)}
                                                   searchOptions={searchOptions}/>}
                                <EmployeeTable ref={employeeTableRef}/>
                            </CardBody>
                        </Card>
                    </Col>
                    <Modal isOpen={employeeModalVisibility} toggle={employeeModalToggle} centered>
                        <ModalHeader>
                            Create Employee
                        </ModalHeader>
                        <ModalBody>
                            <EmployeeForm callBack={(employee: EmployeeType) => {
                                addEmployee(employee);
                                employeeModalToggle();
                            }}/>
                        </ModalBody>
                        <ModalFooter>
                            <Btn attrBtn={{color: 'primary', onClick: employeeModalToggle}}>Close</Btn>
                        </ModalFooter>
                    </Modal>
                </Row>
            </Container>
        </div>
    );
};

export default EmployeeIndex;