import React, {Fragment, useEffect, useState} from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import {useForm} from 'react-hook-form';
import {ErrorHandler} from '../../../../../_helper/SystemCore/SystemCoreHelpers';
import Loader from '../../../../../Layout/Loader';
import TextInput from '../../../../../CommonElements/Input/TextInput';
import {Breadcrumbs, Btn} from '../../../../../AbstractElements';
import BackendDropDown from '../../../../../CommonElements/DropDown/BackendDropDown';
import axiosConfig from '../../../../../Config/axios';
import {LoanType} from '../../../../../Data/Types/Application/ApplicationLoanDataTypes';
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import RadioButtonGroup from '../../../../../CommonElements/RadioButton/RadioButton';
import {ConfigurationType} from '../../../../../Data/Types/SystemCoreDataTypes';
import SimpleLoanView from "../Component/SimpleLoanView";
import SimpleMemberLoanView from "../Component/SimpleMemberLoanView";
import {GroupMemberType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";
import {MemberType} from "../../../../../Data/Types/Application/ApplicationEmployeeDataTypes";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import MemberForm from "../../../Employee/Member/Component/Form";

interface LoanFormProps {
    loan?: LoanType,
    fixedValues?: any,
    callBack?: (loan: LoanType) => void,
}

const LoanForm = ({callBack, loan}: LoanFormProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [formData, setFormData] = useState<any>({});
    const [selectedInstallmentType, setSelectedInstallmentType] = useState<ConfigurationType>();
    const [selectedInterestRateType, setSelectedInterestRateType] = useState<ConfigurationType>();
    const [selectedGroupMember, setSelectedGroupMember] = useState<GroupMemberType>();
    const [reload, setReload] = useState<boolean>(false);
    const [memberModalVisibility, setMemberModalVisibility] = useState(false);
    const [members, setMembers] = useState<Array<MemberType>>([]);
    const {register, handleSubmit, setError, clearErrors, formState: {errors}, setValue, watch, reset} = useForm();

    const memberModalToggle = () => {
        setMemberModalVisibility(!memberModalVisibility);
    };

    const addMember = (member: MemberType) => {
        const memberList = [...members];
        const existingMemberIndex = memberList.findIndex((existingMember: MemberType) => existingMember.id === member.id);
        if (existingMemberIndex !== -1) {
            memberList[existingMemberIndex] = member;
        } else {
            memberList.push(member);
        }
        setMembers(memberList);
    };

    const onSubmit = async (data: any) => {
        clearErrors();
        data.installment_type_id = selectedInstallmentType?.id;
        data.interest_rate_type_id = selectedInterestRateType?.id;
        setLoaderVisibility(true);
        loan ? updateLoan(data) : createLoan(data);
    };

    const createLoan = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        axiosConfig.post(`/application/loan`, data)
            .then((response: { data: { data: LoanType }; }) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                    reset();
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    };

    const updateLoan = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        axiosConfig.put(`/application/loan/${loan?.id}`, data)
            .then((response: { data: { data: LoanType }; }) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                    reset();
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    };

    useEffect(() => {
        setReload(!reload);
    }, [formData.center_id]);

    useEffect(() => {
        const subscriptions: any[] = [];
        subscriptions.push(watch((data: any) => {
            setFormData(data);
        }));
        subscriptions.push(watch(({interest_rate_type_key, interest_rate}: any) => {
            let interestRate = interest_rate;
            if (interest_rate_type_key === 'interest-rate-type-percentage' && interestRate > 99) {
                interestRate = 99;
            }
            interest_rate !== interestRate && setValue('interest_rate', interestRate);
        }));
        return () => subscriptions.map(unsubscribe => unsubscribe.unsubscribe());
    }, [watch]);

    return (
        <div>
            <Breadcrumbs mainTitle="Create a Loan" parent="Application" subParentLink={`${getApplicationRoute('loan')}`}
                         subParent={'Loans'} title={'Create Loan'}/>
            <Container fluid={true}>
                <div className="user-profile">
                    <Loader visibility={loaderVisibility}/>
                    <Row className={'justify-content-center'}>
                        <Col md={12}>
                            <Card className='hovercard'>
                                <CardBody>
                                    <Row className={'justify-content-center'}>
                                        <Col md={12}>
                                            <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
                                                <Row>
                                                    <Col md={6}>
                                                        <Card className='hovercard'>
                                                            <CardBody>
                                                                <h5>Customer Details</h5>
                                                                <BackendDropDown name={'center_id'}
                                                                                 type={'select-url'}
                                                                                 label={'Center'}
                                                                                 setValue={setValue}
                                                                                 errors={errors}
                                                                                 url={'application/center'}
                                                                                 options={{required: true}}
                                                                                 onChange={(option: any) => {
                                                                                     setFormData((prevData: any) => ({
                                                                                         ...prevData,
                                                                                         center_id: option?.value
                                                                                     }));
                                                                                 }}
                                                                />
                                                                <Row sm={12}>
                                                                    <Col sm={10}>
                                                                        <BackendDropDown name={'group_member_id'}
                                                                                         type={'select-url'}
                                                                                         label={'Customer'}
                                                                                         setValue={setValue}
                                                                                         errors={errors}
                                                                                         reload={reload}
                                                                                         params={{
                                                                                             member_type: 'customer',
                                                                                             center_id: formData?.center_id
                                                                                         }}
                                                                                         url={'application/group-member'}
                                                                                         options={{required: true}}
                                                                                         getSelectedValue={setSelectedGroupMember}
                                                                        />
                                                                    </Col>
                                                                    <Col sm='1'>{formData?.center_id && <div
                                                                        style={{marginTop: '32px' }}>
                                                                        <ButtonIcon
                                                                            attrBtn={{onClick: () => setMemberModalVisibility(true)}}
                                                                            type={'add'}/></div>}</Col>
                                                                </Row>
                                                                <Fragment>
                                                                    {selectedGroupMember && (
                                                                        <SimpleMemberLoanView
                                                                            groupMemberID={selectedGroupMember?.id}/>
                                                                    )}
                                                                </Fragment>
                                                            </CardBody>
                                                            <Modal isOpen={memberModalVisibility}
                                                                   toggle={memberModalToggle} centered>
                                                                <ModalHeader>
                                                                    Create Member
                                                                </ModalHeader>
                                                                <ModalBody>
                                                                    <MemberForm
                                                                        fixedValues={{
                                                                            member_type: 'customer',
                                                                            center_id: formData?.center_id
                                                                        }}
                                                                        callBack={addMember}
                                                                    />
                                                                </ModalBody>
                                                                <ModalFooter>
                                                                    <Btn attrBtn={{
                                                                        color: 'primary',
                                                                        onClick: memberModalToggle
                                                                    }}>Close</Btn>
                                                                </ModalFooter>
                                                            </Modal>
                                                        </Card>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Card className='hovercard'>
                                                            <CardBody>
                                                                <h5>Guarantors Details</h5>
                                                                <Row sm={12}>
                                                                    <Col sm={10}>
                                                                        <BackendDropDown name={'first_guarantor_id'}
                                                                                         type={'select-url'}
                                                                                         label={'First Guarantee'}
                                                                                         setValue={setValue}
                                                                                         errors={errors}
                                                                                         reload={reload}
                                                                                         params={{
                                                                                             member_type: 'guarantor',
                                                                                             center_id: formData?.center_id
                                                                                         }}
                                                                                         url={'application/member'}
                                                                                         options={{required: true}}/>
                                                                    </Col>
                                                                    <Col sm='1'>{formData?.center_id && <div
                                                                        style={{marginTop: '32px'}}>
                                                                        <ButtonIcon
                                                                            attrBtn={{onClick: () => setMemberModalVisibility(true)}}
                                                                            type={'add'}/></div>}</Col>
                                                                </Row>
                                                                <Row sm={12}>
                                                                    <Col sm={10}>
                                                                        <BackendDropDown name={'second_guarantor_id'}
                                                                                         type={'select-url'}
                                                                                         label={'Second Guarantee'}
                                                                                         setValue={setValue}
                                                                                         errors={errors}
                                                                                         reload={reload}
                                                                                         params={{
                                                                                             member_type: 'guarantor',
                                                                                             center_id: formData?.center_id
                                                                                         }}
                                                                                         url={'application/member'}
                                                                                         options={{required: true}}/>
                                                                    </Col>
                                                                    <Col sm='1'>{formData?.center_id && <div
                                                                        style={{marginTop: '32px' }}>
                                                                        <ButtonIcon
                                                                            attrBtn={{onClick: () => setMemberModalVisibility(true)}}
                                                                            type={'add'}/></div>}</Col>
                                                                </Row>
                                                            </CardBody>
                                                            <Modal isOpen={memberModalVisibility}
                                                                   toggle={memberModalToggle} centered>
                                                                <ModalHeader>
                                                                    Create Guarantor
                                                                </ModalHeader>
                                                                <ModalBody>
                                                                    <MemberForm
                                                                        fixedValues={{
                                                                            member_type: 'guarantor',
                                                                            center_id: formData?.center_id
                                                                        }}
                                                                        callBack={addMember}
                                                                    />
                                                                </ModalBody>
                                                                <ModalFooter>
                                                                    <Btn attrBtn={{
                                                                        color: 'primary',
                                                                        onClick: memberModalToggle
                                                                    }}>Close</Btn>
                                                                </ModalFooter>
                                                            </Modal>
                                                        </Card>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Card className='hovercard'>
                                                            <CardBody>
                                                                <h5>Loan Details</h5>
                                                                <Row>
                                                                    <Col ms={9}>
                                                                        <Row>
                                                                            <Col md={12}>
                                                                                <TextInput name={'amount'}
                                                                                           type={'number'}
                                                                                           label={'Loan amount'}
                                                                                           register={register}
                                                                                           errors={errors}
                                                                                           placeholder={'Amount'}
                                                                                           options={{required: true}}
                                                                                           elementProps={{min: 1}}/>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <RadioButtonGroup
                                                                                    setValue={setValue}
                                                                                    url={'/system-core/configuration'}
                                                                                    params={{setting_key: 'installment-type'}}
                                                                                    label="Installment Type"
                                                                                    name="installment_type_key"
                                                                                    idColumn={'key'}
                                                                                    register={register}
                                                                                    errors={errors}
                                                                                    required={true}
                                                                                    getSelectedValue={(selectedCheckbox: ConfigurationType) => setSelectedInstallmentType(selectedCheckbox)}
                                                                                />
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <TextInput name={'installment_range'}
                                                                                           type={'number'}
                                                                                           label={'Number of Installments'}
                                                                                           register={register}
                                                                                           errors={errors}
                                                                                           placeholder={'Installments'}
                                                                                           options={{required: true}}
                                                                                           elementProps={{min: 1}}/>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <RadioButtonGroup
                                                                                    setValue={setValue}
                                                                                    url={'/system-core/configuration'}
                                                                                    params={{setting_key: 'interest-rate-type'}}
                                                                                    label="Interest Rate Type"
                                                                                    name="interest_rate_type_key"
                                                                                    idColumn={'key'}
                                                                                    register={register}
                                                                                    errors={errors}
                                                                                    getSelectedValue={(selectedCheckbox: ConfigurationType) => setSelectedInterestRateType(selectedCheckbox)}
                                                                                    required={true}
                                                                                    value={selectedInterestRateType}
                                                                                />
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <TextInput name={'interest_rate'}
                                                                                           type={'number'}
                                                                                           label={`Interest ${selectedInterestRateType?.name ? selectedInterestRateType?.name : 'Rate'}`}
                                                                                           register={register}
                                                                                           errors={errors}
                                                                                           placeholder={'Interest Rate'}
                                                                                           options={{required: true}}
                                                                                           elementProps={{min: 0}}/>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <TextInput name={'request_date'}
                                                                                           type={'date'}
                                                                                           label={'Request Date'}
                                                                                           register={register}
                                                                                           errors={errors}
                                                                                           placeholder={'Request Date'}
                                                                                           options={{required: true}}/>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <TextInput name={'released_date'}
                                                                                           type={'date'}
                                                                                           label={'Released Date'}
                                                                                           register={register}
                                                                                           errors={errors}
                                                                                           placeholder={'Released Date'}
                                                                                           options={{required: true}}/>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <BackendDropDown
                                                                                    name={'center_collector_id'}
                                                                                    type={'select-url'}
                                                                                    label={'Collector'}
                                                                                    setValue={setValue}
                                                                                    errors={errors}
                                                                                    reload={reload}
                                                                                    params={{center_id: formData?.center_id}}
                                                                                    url={'application/center-collector'}
                                                                                    options={{required: true}}/>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <TextInput
                                                                                    name={'collection_start_date'}
                                                                                    type={'date'}
                                                                                    label={'Collection Start Date'}
                                                                                    register={register}
                                                                                    errors={errors}
                                                                                    placeholder={'Collection Start Date'}
                                                                                    options={{required: true}}/>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col ms={3}>
                                                                        <Fragment>
                                                                            <SimpleLoanView formData={formData}
                                                                                            installmentType={selectedInstallmentType?.name}/>
                                                                        </Fragment>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <FormGroup>
                                                    <Btn attrBtn={{
                                                        className: 'd-block w-100 mt-4',
                                                        color: 'primary',
                                                        type: 'submit'
                                                    }}>{loan ? 'Update' : 'Create'}</Btn>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default LoanForm;
