import React, {Fragment, useEffect, useState} from "react";
import IconBtn from "../../../../../CommonElements/IconButton";
import axiosConfig from "../../../../../Config/axios";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import DataTable from "react-data-table-component";
import {
    InstallmentCollectorType,
    InstallmentType
} from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";
import {TableViewType} from "../../../../../Data/Types/UtilityTypes";
import {currencyConfig} from "../../../../../Config/ApplicationConfig";
import InstallmentCollectorForm from "../../InstallmentCollector/Component/Form";
import {Btn} from "../../../../../AbstractElements";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import moment from "moment";
import {FaBan} from "react-icons/fa";

const InstallmentsTable = React.forwardRef(({
                                                requestParams = {},
                                                actionsToHide = {},
                                                columnsToHide = {}
                                            }: TableViewType, ref) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [installments, setInstallments] = useState<Array<InstallmentType>>([])
    const [installmentCollectorModalVisibility, setInstallmentCollectorModalVisibility] = useState<boolean>(false);
    const [selectedInstallment, setSelectedInstallment] = useState<InstallmentType>();

    React.useImperativeHandle(ref, () => ({
        getInstallments,
        addInstallment
    }))

    const getInstallments = (params: any) => {
        setLoaderVisibility(true);
        const config = {params: {...params, ...requestParams}};
        axiosConfig
            .get(getApplicationRoute('installment'), config)
            .then((response: { data: { data: Array<InstallmentType>; }; }) => {
                if (response?.data?.data) {
                    setInstallments(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    const bypassInstallment = (id: number) => {
        setLoaderVisibility(true);
        axiosConfig
            .get(`${getApplicationRoute('installment')}/bypass/${id}`, {})
            .then((response: { data: { data: InstallmentType; }; }) => {
                if (response?.data?.data) {
                    addInstallment(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    const addInstallment = (installment: InstallmentType) => {
        const installmentList = [...installments];
        const existingInstallmentIndex = installmentList.findIndex((existingInstallment: InstallmentType) => {
            return existingInstallment.id === installment.id;
        });
        if (existingInstallmentIndex !== -1) {
            installmentList[existingInstallmentIndex] = installment;
        } else {
            installmentList.push(installment);
        }
        setInstallments(installmentList);
    }

    useEffect(() => {
        getInstallments({});
    }, []);

    const installmentTableColumns = [
        {
            name: 'Installment',
            selector: (installment: InstallmentType) => <div>{installment?.installment ? <i className="fa fa-circle"
                                                                                            style={{color: '#FC4438FF'}}/> : null} {installment?.installment_amount.toLocaleString('en-LK', currencyConfig)}
            </div>,
            sortable: true,
            center: true,
        },
        {
            name: 'Interest',
            selector: (installment: InstallmentType) => installment?.interest_amount.toLocaleString('en-LK', currencyConfig),
            sortable: true,
            center: true,
        },
        {
            name: 'Payment Date',
            selector: (installment: InstallmentType) => installment?.payment_date,
            sortable: true,
            center: true,
        },
        {
            name: 'Paid Date',
            selector: (installment: InstallmentType) => {
                const status = getStatus(installment);
                return (installment?.installment_collections?.length ? installment?.installment_collections?.map((installmentCollection, key) =>
                        <div key={key}>{moment(installmentCollection.paid_date).format('YYYY-MM-DD')}</div>) :
                    <span className={`badge badge-${status.badge}`}>{status.text}</span>)
            },
            sortable: true,
            center: true,
        },
        {
            name: 'Paid amount',
            selector: (installment: InstallmentType) => {
                const status = getStatus(installment);
                return (installment?.installment_collections?.length ? installment?.installment_collections?.map((installmentCollection, key) =>
                        <div key={key}>{installmentCollection.amount?.toLocaleString('en-LK', currencyConfig)}</div>) :
                    <span className={`badge badge-${status.badge}`}>{status.text}</span>)
            },
            sortable: true,
            center: true,
        },
        {
            name: 'Status',
            selector: (installment: InstallmentType) => {
                let badgeColor = 'info';
                if (installment.status === 'paid') {
                    badgeColor = 'success';
                } else if (installment.status === 'bypassed') {
                    badgeColor = 'warning';
                } else if (moment() > moment(installment.payment_date)) {
                    badgeColor = 'danger';
                }
                return (
                    <span className={`badge badge-${badgeColor}`}>{installment.status}</span>
                )
            },
            sortable: true,
            center: true,
        },
        {
            name: 'Option', selector: (installment: InstallmentType) => <div className='d-flex align-items-right gap-2'>
                {!actionsToHide.view ?
                    <IconBtn link={`${getApplicationRoute('installment')}/${installment.id}`} type={'view'}/> : null}
                {!actionsToHide.pay && installment.status == 'pending' ?
                    <IconBtn attrBtn={{
                        onClick: () => {
                            setInstallmentCollectorModalVisibility(true);
                            setSelectedInstallment(installment);
                        }
                    }} type={'add'}/> : null}
                {!actionsToHide.pay && installment.status == 'pending' && moment() > moment(installment.payment_date) ?
                    <IconBtn icon={<FaBan style={{cursor: "pointer", color: '#FC4438FF'}}/>}
                             confirm={{text: 'You want ti bypass this installment'}}
                             onclick={() => {
                                 bypassInstallment(installment.id);
                             }}/> : null}
            </div>, sortable: false, right: true,
        }]

    const getStatus = (installment: InstallmentType) => {
        let status = {text: 'Pending', badge: 'info'};
        if (moment() === moment(installment.payment_date)) {
            status = {text: 'Today', badge: 'warning'};
        } else if (moment() > moment(installment.payment_date)) {
            status = {text: 'Overdue', badge: 'danger'};
        }
        return status;
    }

    const checkColumnVisibility = (columns: any[] = []) => {
        return columns.filter((column) => !columnsToHide[column.name.toLowerCase().replace(/\s+/g, '_')])
    }

    const installmentCollectorModalToggle = () => {
        setInstallmentCollectorModalVisibility(!installmentCollectorModalVisibility);
    }

    return <Fragment>
        <Loader visibility={loaderVisibility}/>
        <DataTable pagination paginationServer columns={checkColumnVisibility(installmentTableColumns)}
                   data={installments}
                   highlightOnHover={true}
                   striped={true} responsive={true}/>
        <Modal isOpen={installmentCollectorModalVisibility} toggle={installmentCollectorModalToggle} centered>
            <ModalHeader>
                Create Installment Collector
            </ModalHeader>
            <ModalBody>
                <InstallmentCollectorForm
                    installment={selectedInstallment}
                    fixedValues={{installment_id: selectedInstallment?.id, ...requestParams}}
                    callBack={(installmentCollector: InstallmentCollectorType) => {
                        addInstallment(installmentCollector?.installment);
                        installmentCollectorModalToggle();
                    }}/>
            </ModalBody>
            <ModalFooter>
                <Btn attrBtn={{color: 'primary', onClick: installmentCollectorModalToggle}}>Close</Btn>
            </ModalFooter>
        </Modal>
    </Fragment>
});

export default InstallmentsTable;