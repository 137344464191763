import React, {Fragment} from 'react';
import {Col, Container, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {Btn, H4, P} from '../../../../AbstractElements';
import {Link} from "react-router-dom";

const ForgetPwd = () => {

    const forgetPassword = () => {

    }
    return (
        <Fragment>
            <section>
                <Container className='p-0 login-page' fluid={true}>
                    <Row className='m-0'>
                        <Col className='p-0'>
                            <div className='login-card'>
                                <div>
                                    <div className='login-main'>
                                        <Form onSubmit={forgetPassword} className='theme-form login-form'>
                                            <H4>Forget Password</H4>
                                            <P>Enter your email address</P>
                                            <FormGroup>
                                                <Label className='col-form-label m-0'>Email Address</Label>
                                                <Input className='form-control' name={'email'} type='email' required
                                                       placeholder='Test@gmail.com'/>
                                            </FormGroup>
                                            <FormGroup>
                                                <Btn attrBtn={{
                                                    className: 'd-block w-100 mt-2',
                                                    color: 'primary',
                                                    type: 'submit'
                                                }}>Reset Password</Btn>
                                            </FormGroup>
                                            <P attrPara={{className: 'text-start'}}>
                                                Already have an password?
                                                <Link className='ms-2' to={`${process.env.PUBLIC_URL}/login`}>
                                                    Sign in
                                                </Link>
                                            </P>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
};

export default ForgetPwd;
