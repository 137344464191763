import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, ListGroup, Row} from 'reactstrap';
import {Breadcrumbs, H6} from '../../../../AbstractElements';
import {useParams} from "react-router";
import axiosConfig from "../../../../Config/axios";
import {ErrorHandler} from "../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../Layout/Loader";
import {BootstrapTabContainer, BootstrapTabContent} from "../../../../CommonElements/BootstrapTabs";
import RoleForm from "../Component/Form";
import RolePermissionListView from "../../Permission/Component/RolePermissionListView";
import {getPrivilegeRoute} from "../../../../Route/RouteIndex";
import ButtonIcon from "../../../../CommonElements/ButtonIcon";

const RoleShow = () => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [role, setRole] = useState('');
    const params = useParams();

    useEffect(() => {
        setLoaderVisibility(true);
        const config = {};
        axiosConfig
            .get(`/privilege/role/${params.role}`, config)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    setRole(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }, [params.role]);

    return (
        <div>
            <Breadcrumbs mainTitle="Roles" parent="Privilege" subParentLink={getPrivilegeRoute('role')}
                         subParent={'Roles'} title={role?.name}/>
            <Container fluid={true}>
                <div className="user-profile">
                    <Loader visibility={loaderVisibility}/>
                    <Row>
                        <Col sm='12' lg='3'>
                            <Card className='hovercard text-center'>
                                <CardHeader className='social-profile'>
                                </CardHeader>
                                <div className='info'>
                                    <Row className='step3' data-intro='This is the your details'>
                                        <Col sm='12' lg='12' className='order-sm-0 order-xl-1'>
                                            <div className='user-designation'>
                                                <div className='title'>
                                                    <a>
                                                        {role?.display_name}
                                                    </a>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm='6' lg='12' className='order-sm-1 order-xl-0'>
                                            <Row>
                                                <Col>
                                                    <div className='ttl-info text-center'>
                                                        <H6>
                                                            {role?.name}
                                                        </H6>
                                                        <H6>
                                                            {role?.description}
                                                        </H6>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody>
                                    <div className='social-network theme-form'>

                                        {!role.system_defined &&
                                            <>
                                                <span className='f-w-600 text-danger'>Danger Zone</span>
                                                <ButtonIcon attrBtn={{onClick: RoleShow}} type={'delete'}/>
                                            </>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm='12' lg='9'>
                            <Card className='hovercard'>
                                <CardBody>
                                    <BootstrapTabContainer>
                                        <BootstrapTabContent title={'Dashboard'}
                                                             icon={'icofont icofont-dashboard'}>
                                            This is Dashboard
                                        </BootstrapTabContent>
                                        <BootstrapTabContent title={'Users'} icon={'icofont icofont-ui-user'}>
                                            <ListGroup>
                                                <Row>
                                                    {role?.users?.map((user, key) => (
                                                        <Col sm="4" key={key}>
                                                            <Card>
                                                                <CardBody style={{height: '150px'}} className="shadow-lg p-25 shadow-showcase align-items-center text-center">
                                                                    <h6>
                                                                        {user.name}
                                                                    </h6>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </ListGroup>
                                        </BootstrapTabContent>
                                        <BootstrapTabContent title={'Permissions'} icon={'icofont icofont-key'}>
                                            <RolePermissionListView roleID={role?.id}/>
                                        </BootstrapTabContent>
                                        <BootstrapTabContent title={'Update'} icon={'icofont icofont-ui-edit'}>
                                            <Row className={'justify-content-center'}>
                                                <Col md={6}>
                                                    <RoleForm role={role} callBack={(role) => {
                                                        setRole(role)
                                                    }}/>
                                                </Col>
                                            </Row>
                                        </BootstrapTabContent>
                                    </BootstrapTabContainer>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default RoleShow;
