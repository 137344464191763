import React, { ForwardedRef, Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { getApplicationRoute } from "../../../../../Route/RouteIndex";
import IconBtn from "../../../../../CommonElements/IconButton";
import axiosConfig from "../../../../../Config/axios";
import { ErrorHandler } from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import { CenterType } from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";
import { TableViewType } from "../../../../../Data/Types/UtilityTypes";

const CenterTable = React.forwardRef(({
                                          requestParams = {},
                                          actionsToHide = {},
                                          columnsToHide = {},
                                      }: TableViewType & {}, ref: ForwardedRef<any>) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [centerData, setCenterData] = useState<CenterType[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);

    React.useImperativeHandle(ref, () => ({
        getCenters,
        addCenter,
    }));

    const getCenters = (params: any) => {
        const config = {
            params: {
                ...params,
                ...requestParams,
                page: params.page || currentPage,
                perPage: 10,
            }
        };
        setLoaderVisibility(true);
        axiosConfig
            .get(getApplicationRoute("center"), config)
            .then((response: { data: { data: CenterType[]; total: number } }) => {
                if (response?.data?.data) {
                    setCenterData(response.data.data);
                    setTotalRows(response.data.total);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const deleteCenter = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`${getApplicationRoute("center")}/${id}`, {})
            .then((response: { data: { data: CenterType } }) => {
                if (response?.data?.data) {
                    setCenterData((current: CenterType[]) =>
                        current.filter((center: CenterType) => center.id !== response?.data?.data?.id)
                    );
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const addCenter = (center: CenterType) => {
        const centerDataList = [...centerData];
        const existingCenterIndex = centerDataList.findIndex((existingCenter: CenterType) => existingCenter.id === center.id);
        if (existingCenterIndex !== -1) {
            centerDataList[existingCenterIndex] = center;
        } else {
            centerDataList.push(center);
        }
        setCenterData(centerDataList);
    };

    const centerTableColumns = [
        {
            name: "Branch Name",
            selector: (center: CenterType) => center?.branch?.branch_name,
            sortable: true,
        },
        {
            name: "Center Name",
            selector: (center: CenterType) => center.name,
            sortable: true,
        },
        {
            name: "Center Code",
            selector: (center: CenterType) => center.code,
            sortable: false,
        },
        {
            name: "Option",
            selector: (center: CenterType) => (
                <div className="d-flex align-items-right gap-2">
                    {!actionsToHide.view ? <IconBtn
                        link={`${getApplicationRoute("center")}/${center.id}`}
                        type={"view"}
                    /> : null}
                    {!actionsToHide.delete && (
                        <IconBtn
                            confirm={true}
                            onclick={() => deleteCenter(center.id)}
                            type={"delete"}
                        />
                    )}
                </div>
            ),
            sortable: false,
            right: true,
        },
    ];

    const checkColumnVisibility = (columns: any[] = []) => {
        return columns.filter((column) => !columnsToHide[column.name.toLowerCase().replace(/\s+/g, '_')]);
    }

    useEffect(() => {
        getCenters({ page: currentPage });
    }, [currentPage]);

    return (
        <Fragment>
            <Loader visibility={loaderVisibility} />
            <DataTable
                pagination
                paginationServer
                columns={checkColumnVisibility(centerTableColumns)}
                data={centerData}
                highlightOnHover={true}
                striped={true}
                responsive={true}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                paginationPerPage={10}
            />
        </Fragment>
    );
});

export default CenterTable;
