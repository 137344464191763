import React, {useEffect, useState} from "react";
import axiosConfig from "../../../../../Config/axios";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import {Col, Row} from "reactstrap";
import {LoanDashboardType} from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";
import LoanChart from "./LoanChart";

interface MemberLoanProps {
    memberID: number;
}

const MemberLoan = ({memberID}: MemberLoanProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [loans, setLoans] = useState<LoanDashboardType[]>([]);

    useEffect(() => {
        if (memberID) {
            setLoaderVisibility(true);
            axiosConfig
                .get(getApplicationRoute(`loan`), {params: {member_id: memberID, resource: 'dashboard'}})
                .then((response: { data: { data: LoanDashboardType; }; }) => {
                    if (response?.data?.data) {
                        setLoans(response.data.data);
                    }
                    setLoaderVisibility(false);
                })
                .catch((exception: any) => {
                    ErrorHandler(exception);
                    setLoaderVisibility(false);
                });
        }
    }, [memberID]);

    return (
        <div>
            <Loader visibility={loaderVisibility}/>
            <Row>
                {loans.map((loan, key) => (
                    <Col md={6} sm={6} key={key}>
                        <LoanChart loan={loan}/>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default MemberLoan;
