import React from 'react';
import {Button} from 'reactstrap';
import {
    FaDownload,
    FaEdit,
    FaExpandArrowsAlt,
    FaPlus,
    FaSearch,
    FaStepBackward,
    FaSync,
    FaTrashAlt
} from "react-icons/fa";
import SweetAlert from "sweetalert2";

interface btnIconProps {
    type: string,
    attrBtn?: any,
    icon?: any,
    size?: string
    confirm?: any
    onclick?: () => void
}

const BtnIcon = ({type, attrBtn, size, icon, confirm, onclick}: btnIconProps) => {

    const openConfirmAlert = () => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: confirm.text ? confirm.text : 'Once deleted, you will not be able to recover this!',
            icon: confirm.icon ? confirm.icon : 'warning',
            showCancelButton: confirm.showCancelButton ? confirm.showCancelButton : true,
            reverseButtons: confirm.reverseButtons ? confirm.reverseButtons : true,
            confirmButtonText: confirm.confirmButtonText ? confirm.confirmButtonText : 'Ok',
            cancelButtonText: confirm.cancelButtonText ? confirm.cancelButtonText : 'cancel',
        })
            .then((result: { value: any; }) => {
                if (result.value) {
                    onclick && onclick();
                }
            })
    }

    switch (type) {
        case 'delete':
            return <Button {...attrBtn}
                           onClick={() => confirm ? openConfirmAlert() : onclick && onclick()}
                           color={`btn ${size ? size : 'sm'} icon btn-outline-danger`}
                           style={{padding: '0.375rem 0.75rem'}}><FaTrashAlt style={{marginBottom: -2}}/></Button>;
        case 'edit':
            return <Button {...attrBtn} style={{padding: '0.375rem 0.75rem'}}
                           color={`btn ${size ? size : 'sm'} icon btn-outline-info`}><FaEdit
                style={{marginBottom: -2}}/></Button>;
        case 'add':
            return <Button {...attrBtn}
                           color={`btn ${size ? size : 'sm'} icon btn-outline-primary`}
                           style={{padding: '0.375rem 0.75rem'}}><FaPlus style={{marginBottom: -2}}/></Button>;
        case 'sync':
            return <Button {...attrBtn}
                           color={`btn ${size ? size : 'sm'} icon btn-outline-light`}
                           style={{padding: '0.375rem 0.75rem'}}><FaSync style={{marginBottom: -2}}/></Button>;
        case 'view':
            return <Button {...attrBtn}
                           color={`btn ${size ? size : 'sm'} icon btn-outline-success`}
                           style={{padding: '0.375rem 0.75rem'}}><FaExpandArrowsAlt
                style={{marginBottom: -2}}/></Button>;
        case 'search':
            return <Button {...attrBtn}
                           color={`btn ${size ? size : 'sm'} icon btn-outline-success`}
                           style={{padding: '0.375rem 0.75rem'}}><FaSearch
                style={{marginBottom: -2}}/></Button>;
        case 'back':
            return <Button {...attrBtn}
                           color={`btn ${size ? size : 'sm'} icon btn-outline-success`}
                           style={{padding: '0.375rem 0.75rem'}}><FaStepBackward
                style={{marginBottom: -2}}/></Button>;
        case 'download':
            return <Button {...attrBtn}
                           color={`btn ${size ? size : 'sm'} icon btn-outline-success`}
                           style={{padding: '0.375rem 0.75rem'}}><FaDownload
                style={{marginBottom: -2}}/></Button>;
        default:
            return <Button {...attrBtn}
                           color={`btn ${size ? size : 'sm'} icon btn-outline-${attrBtn?.color ? attrBtn?.color : 'success'}`}
                           style={{padding: '0.375rem 0.75rem'}}>{icon}</Button>;
    }

};

export default BtnIcon;