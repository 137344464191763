import React, {Fragment, useEffect, useState} from "react";
import Loader from "../../../../../Layout/Loader";
import {TableViewType} from "../../../../../Data/Types/UtilityTypes";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import {currencyConfig} from "../../../../../Config/ApplicationConfig";
import DataTable from "react-data-table-component";
import {InstallmentType} from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";

const UnpaidLoanReportTable = React.forwardRef(({
                                        requestParams = {},
                                        columnsToHide = {}
                                    }: TableViewType, ref) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [installments, setInstallments] = useState<InstallmentType[]>([]);

    React.useImperativeHandle(ref, () => ({
        getInstallments
    }));

    const getInstallments = (searchParams = {}) => {
        setLoaderVisibility(true);

        const updatedSearchParams = {
            ...searchParams,
            status: 'bypassed',
            ...requestParams
        };

        const config = { params: updatedSearchParams };

        axiosConfig
            .get(getApplicationRoute('installment'), config)
            .then((response) => {
                if (response?.data?.data) {
                    setInstallments(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    useEffect(() => {
        getInstallments({});
    }, []);

    const installmentTableColumns = [
        {
            name: 'Member Details',
            selector: (installment: InstallmentType) => installment?.loan?.member?.name,
            sortable: false,
        },
        {
            name: 'Collector',
            selector: (installment: InstallmentType) => installment?.loan?.center_collector?.name,
            sortable: false,
        },
        {
            name: 'Installment ID',
            selector: (installment: InstallmentType) => installment?.id,
            sortable: false,
        },
        {
            name: 'Installment Amount',
            selector: (installment: InstallmentType) => (installment?.installment_amount)?.toLocaleString('en-LK', currencyConfig),
            sortable: false,
        },
        {
            name: 'Interest Amount',
            selector: (installment: InstallmentType) => (installment?.interest_amount)?.toLocaleString('en-LK', currencyConfig),
            sortable: false,
        },
        {
            name: 'Payment Date',
            selector: (installment: InstallmentType) => installment?.payment_date,
            sortable: false,
        },
        {
            name: 'Status',
            selector: (installment: InstallmentType) => installment?.status,
            sortable: true,
            center: true,
        },

    ];

    const checkColumnVisibility = (columns: any[] = []) => {
        return columns.filter((column) => !columnsToHide[column.name.toLowerCase().replace(/\s+/g, '_')])
    }
    return (
        <Fragment>
            <Loader visibility={loaderVisibility}/>
            <DataTable pagination paginationServer columns={checkColumnVisibility(installmentTableColumns)} data={installments}
                       highlightOnHover={true}
                       striped={true} responsive={true}/>
        </Fragment>
    );
})

export default UnpaidLoanReportTable;