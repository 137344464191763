import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {Breadcrumbs, H6} from '../../../../AbstractElements';
import {useParams} from "react-router";
import axiosConfig from "../../../../Config/axios";
import {ErrorHandler} from "../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../Layout/Loader";
import {BootstrapTabContainer, BootstrapTabContent} from "../../../../CommonElements/BootstrapTabs";
import RoleListView from "../../Role/Component/RoleListView";
import PermissionListView from "../../Permission/Component/PermissionListView";
import ButtonIcon from "../../../../CommonElements/ButtonIcon";
import UpdateUserForm from "../Component/UpdateUserForm";
import UpdatePasswordForm from "../Component/UpdatePasswordForm";
import {getPrivilegeRoute} from "../../../../Route/RouteIndex";
import {useNavigate} from "react-router-dom";
import ProfilePicture from "../../../CommonComponents/MultiMedia/ProfilePicture";


const UserShow = () => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [user, setUser] = useState('');
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setLoaderVisibility(true);
        const config = {};
        axiosConfig
            .get(`${getPrivilegeRoute('user')}/${params.user}`, {config})
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    setUser(response.data.data);
                } else {
                    navigate(getPrivilegeRoute('user'));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
                navigate(getPrivilegeRoute('user'));
            });
    }, [params.user]);

    const deleteUser = () => {
        setLoaderVisibility(true);
        const config = {};
        axiosConfig
            .delete(`${getPrivilegeRoute('user')}/${user.id}`, config)
            .then((response: { data: { data: any }; }) => {
                setLoaderVisibility(false);
                if (response?.data?.data) {
                    navigate(getPrivilegeRoute('user'));
                }
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }


    return (
        <div>
            <Breadcrumbs mainTitle="User Profile" parent="Privilege" subParentLink={`${getPrivilegeRoute('user')}`}
                         subParent={'Users'} title={user?.name}/>
            <Container fluid={true}>
                <Loader visibility={loaderVisibility}/>
                <div className="user-profile">
                    <Row>
                        <Col sm='12' lg='3'>
                            <Card className='hovercard text-center'>
                                <CardHeader className='social-profile'>
                                    <div className='d-flex align-items-center gap-2'>
                                    </div>
                                </CardHeader>
                                <ProfilePicture/>
                                <div className='info'>
                                    <Row className='step3' data-intro='This is the your details'>
                                        <Col sm='12' lg='12' className='order-sm-0 order-xl-1'>
                                            <div className='user-designation'>
                                                <div className='title'>
                                                    <a>
                                                        {user?.name}
                                                    </a>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm='6' lg='12' className='order-sm-1 order-xl-0'>
                                            <Row>
                                                <Col>
                                                    <div className='ttl-info text-center'>
                                                        <H6>
                                                            {user?.email}
                                                        </H6>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody>
                                    <div className='social-network theme-form'>
                                        {!user.system_defined && (
                                            <>
                                                <span className='f-w-600 text-danger'>Danger Zone</span>
                                                <ButtonIcon confirm={true} onclick={() => deleteUser()}
                                                            type={'delete'}/>
                                            </>
                                        )}
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm='12' lg='9'>
                            <Card className='hovercard'>
                                <CardBody>
                                    <BootstrapTabContainer>
                                        <BootstrapTabContent title={'Dashboard'} icon={"icofont icofont-dashboard"}>
                                            This is Dashboard
                                        </BootstrapTabContent>

                                        <BootstrapTabContent title={'Role'} icon={'icofont icofont-ui-user'}>
                                            <RoleListView userID={user?.id}/>
                                        </BootstrapTabContent>

                                        <BootstrapTabContent title={'Permission'}
                                                             icon={'icofont icofont-shield-alt'}>
                                            <PermissionListView userID={user?.id}/>
                                        </BootstrapTabContent>

                                        <BootstrapTabContent title={'Update'} icon={'icofont icofont-ui-edit'}>
                                            <UpdateUserForm/>
                                        </BootstrapTabContent>

                                        <BootstrapTabContent title={'Password'} icon={'icofont icofont-key'}>
                                            <UpdatePasswordForm userID={user.id}/>
                                        </BootstrapTabContent>

                                        <BootstrapTabContent title={'Activity Log'} icon={'icofont icofont-list'}>
                                            <h6>Activity log</h6>
                                        </BootstrapTabContent>

                                    </BootstrapTabContainer>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default UserShow;
