import React, {Fragment, useEffect, useState} from "react";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import DataTable from "react-data-table-component";
import {TableViewType} from "../../../../../Data/Types/UtilityTypes";
import {MemberType} from "../../../../../Data/Types/Application/ApplicationEmployeeDataTypes";

const MemberReportTable = React.forwardRef(({
                                          requestParams = {},
                                          columnsToHide = {}
                                      }: TableViewType, ref) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [members, setMembers] = useState<Array<MemberType>>([]);

    React.useImperativeHandle(ref, () => ({
        getMembers,
    }));

    const getMembers = (params: any) => {
        setLoaderVisibility(true);
        const config = {params: {...params, ...requestParams}};
        axiosConfig
            .get(getApplicationRoute('member'), config)
            .then((response: { data: { data: Array<MemberType>; }; }) => {
                if (response?.data?.data) {
                    setMembers(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }


    useEffect(() => {
        getMembers({});
    }, [])

    const memberTableColumns = [
        {
            name: 'Name',
            selector: (member: { user: { name: any; }; }) => member?.user?.name,
            sortable: false,
        },
        {
            name: 'Customer Type',
            selector: (member: { member_type: { name: any; }; }) => member?.member_type?.name,
            sortable: false,
        },
        {
            name: 'Contact Number',
            selector: (member: { phone: any;}) => member?.phone,
            sortable: false,
        },
        {
            name: 'Address',
            selector: (member: { address:any;}) => member?.address,
            sortable: false,
        },
        {
            name: 'Center',
            selector: (member: { center: { name: any; }; }) => member?.center?.name,
            sortable: false,
        },
        {
            name: 'Gender',
            selector: (member: { gender: { name: any; }; }) => member?.gender?.name,
            sortable: false,
        },
        {
            name: 'NIC',
            selector: (member: { nic: any; }) => member?.nic,
            sortable: true,
            center: true,
        },
    ];

    const checkColumnVisibility = (columns: any[] = []) => {
        return columns.filter((column) => !columnsToHide[column.name.toLowerCase().replace(/\s+/g, '_')])
    }

    return (
        <Fragment>
            <Loader visibility={loaderVisibility}/>
            <DataTable pagination paginationServer columns={checkColumnVisibility(memberTableColumns)} data={members}
                       highlightOnHover={true}
                       striped={true} responsive={true}/>
        </Fragment>
    )
});

export default MemberReportTable;