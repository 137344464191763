import React, {Fragment, useState} from "react"
import DataTable from "react-data-table-component";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import {CenterType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";
import {TableViewType} from "../../../../../Data/Types/UtilityTypes";

const CenterReportTable = React.forwardRef(({
                                                requestParams = {},
                                                columnsToHide = {}
                                            }: TableViewType, ref) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [centerData, setCenterData] = useState<CenterType[]>([]);

    React.useImperativeHandle(ref, () => ({
        getCenters,
    }))

    const getCenters = (params: any) => {
        setLoaderVisibility(true);
        const config = {params: {...params, ...requestParams}};
        axiosConfig
            .get(getApplicationRoute("center"), config)
            .then((response: { data: { data: CenterType[] } }) => {
                if (response?.data?.data) {
                    setCenterData(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const centerTableColumns = [
        {
            name: "Branch Name",
            selector: (center: CenterType) => center?.branch?.branch_name,
            sortable: true,
        },
        {
            name: "Center Name",
            selector: (center: CenterType) => center.name,
            sortable: true,
        },
        {
            name: "Center Code",
            selector: (center: CenterType) => center.code,
            sortable: false,
        },
        {
            name: "Leader",
            selector: (center: CenterType) => center?.leader?.name,
            sortable: true,
        },
        {
            name: "Number of Group",
            selector: (center: CenterType) => center?.group_count,
            sortable: true,
        },
        {
            name: "Number of Customers",
            selector: (center: CenterType) => center?.member_count,
            sortable: true,
        },
        {
            name: "Number of Collector",
            selector: (center: CenterType) => center?.collector_count,
            sortable: true,
        },
    ];

    const checkColumnVisibility = (columns: any[] = []) => {
        return columns.filter((column) => !columnsToHide[column.name.toLowerCase().replace(/\s+/g, '_')])
    }

    return (
        <Fragment>
            <Loader visibility={loaderVisibility}/>
            <DataTable
                pagination paginationServer columns={checkColumnVisibility(centerTableColumns)} data={centerData}
                highlightOnHover={true}
                striped={true} responsive={true}
            />
        </Fragment>
    );
});

export default CenterReportTable;