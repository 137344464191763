import React, {useRef, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {Breadcrumbs, H5} from "../../../../../AbstractElements";
import Loader from "../../../../../Layout/Loader";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import SearchOptions from "../../../../CommonComponents/Search";
import MemberReportTable from "../Component/MemberReportTable";


const MemberReportIndex = () => {
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const memberTableRef = useRef<{ addMember: (member: any) => void; getMembers: (params: any) => void }>(null);
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const searchOptions = [
        {name: 'name', label: 'Name'},
        {name: 'memberType', label: 'Member Type'},
        {name: 'center', label: 'Center'},
        {name: 'nic', label: 'NIC'}
    ];
    const getMembers = (params: any) => {
        if (memberTableRef.current) {
            memberTableRef.current.getMembers(params);
        }
    };
    const downloadMemberExcel = (params: any) => {
        setLoaderVisibility(true);
        const config = {params: params, responseType: 'blob'};
        axiosConfig
            .get("/report/member-download", config)
            .then((response: { data: BlobPart; }) => {
                const blob = new Blob([response.data],
                    {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                );
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'members.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    return (
        <div>
            <Breadcrumbs mainTitle="Report" parent="Application" title="Member Report"/>
            <Container fluid={true}>
                <Loader visibility={loaderVisibility} />
                <Row>
                    <Col sm="12">
                        <Card onClick={() => downloadMemberExcel({ /* params here */ })} style={{ cursor: 'pointer' }}>
                            <CardBody>
                                <CardHeader className='d-flex align-items-center justify-content-between'>
                                    <H5>Customers</H5>
                                    <div>
                                        <div className='d-flex align-items-center gap-2'>
                                            <ButtonIcon attrBtn={{
                                                onClick: getMembers
                                            }} type={'sync'}/>

                                            <ButtonIcon attrBtn={{
                                                onClick: () => setSearchVisibility(!searchVisibility)
                                            }} type={'search'}/>

                                            <ButtonIcon attrBtn={{
                                                onClick: downloadMemberExcel
                                            }} type={'download'}/>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    {searchVisibility && <SearchOptions callBack={(params: any) => getMembers(params)}
                                                                        searchOptions={searchOptions}/>}
                                    <MemberReportTable actionsToHide={{delete: true}} columnsToHide={{option: true}}/>
                                </CardBody>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MemberReportIndex;