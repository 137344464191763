import React, {useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {Btn, H5} from "../../../../../AbstractElements";
import EmployeeTable from "../../../Employee/Employee/Component/EmployeeTable";
import EmployeeForm from "../../../Employee/Employee/Component/Form";
import {EmployeeType} from "../../../../../Data/Types/Application/ApplicationEmployeeDataTypes";
import {BranchType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import SearchOptions from "../../../../CommonComponents/Search";

interface branchEmployeeProps {
    branch: BranchType
}

const BranchEmployees = ({branch}: branchEmployeeProps) => {
    const [employeeModalVisibility, setEmployeeModalVisibility] = useState<boolean>(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const employeeTableRef = React.useRef();
    const searchOptions = [
        {name: 'name', label: 'Name'},
        {name: 'designation', label: 'Designation'},
        {name: 'nic', label: 'NIC'},
        {name: 'contact', label: 'Contact'}
    ];

    const employeeModalToggle = () => {
        setEmployeeModalVisibility(!employeeModalVisibility);
    }

    const getEmployees = (params: any) => {
        if (employeeTableRef.current) {
            employeeTableRef.current.getEmployees(params);
        }
    }

    const addEmployee = (employee: EmployeeType) => {
        if (employeeTableRef.current) {
            employeeTableRef.current.addEmployee(employee);
        }
    }

    useEffect(() => {
        getEmployees({});
    }, [])

    return (<Row>
        <Col sm="12" className='d-flex align-items-center justify-content-between'>
            <H5>{branch.branch_name} branch employees</H5>
            <div>
                <div className='d-flex align-items-center gap-2'>
                    <ButtonIcon attrBtn={{
                        onClick: () => setEmployeeModalVisibility(true)
                    }} type={'add'}/>

                    <ButtonIcon attrBtn={{
                        onClick: getEmployees
                    }} type={'sync'}/>
                    <ButtonIcon attrBtn={{
                        onClick: () => setSearchVisibility(!searchVisibility)
                    }} type={'search'}/>
                </div>
            </div>
        </Col>
        <Col md={12}>
            {searchVisibility && <SearchOptions callBack={(params: any) => getEmployees(params)}
                                                searchOptions={searchOptions}/>}
            <EmployeeTable ref={employeeTableRef} actionsToHide={{delete: true}} columnsToHide={{branch: true}}
                           requestParams={{branch_id: branch?.id}}/>
        </Col>
        <Modal isOpen={employeeModalVisibility} toggle={employeeModalToggle} centered>
            <ModalHeader>
                Create employee of {branch.branch_name} branch
            </ModalHeader>
            <ModalBody>
                <EmployeeForm fixedValues={{branch_id: branch?.id}} callBack={(employee: EmployeeType) => {
                    addEmployee(employee);
                    employeeModalToggle();
                }}/>
            </ModalBody>
            <ModalFooter>
                <Btn attrBtn={{color: 'primary', onClick: employeeModalToggle}}>Close</Btn>
            </ModalFooter>
        </Modal>
    </Row>)
}

export default BranchEmployees;