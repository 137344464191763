import {FormGroup, Input, Label} from 'reactstrap';
import React, {Fragment} from "react";

interface textInputProps {
    label: string,
    name: string,
    elementProps?: any,
    type?: string,
    placeholder?: string,
    disabled?: boolean,
    readOnly?: boolean,
    register?: any,
    options?: any,
    errors?: any,
    fixedValues?: any,
}

const TextInput = ({
                       label,
                       name,
                       type,
                       placeholder,
                       register,
                       options,
                       fixedValues,
                       disabled = false,
                       readOnly = false,
                       errors = null,
                       elementProps = {}
                   }: textInputProps) => {

    const {ref, ...rest} = register(name);
    const getInput = () => {
        switch (type) {
            case 'checkbox':
                return <Fragment>
                    <div className='checkbox checkbox-primary'>
                        <Input name={name} id={name} type='checkbox'
                               innerRef={ref} {...rest} {...elementProps}
                        />
                        <Label className={`${options?.required ? 'required' : null}`}
                               for={name}>{label ? label : 'Label'}</Label>
                    </div>
                </Fragment>
            default:
                return <Fragment>
                    <Label
                        className={`col-form-label m-0 ${options?.required ? 'required' : null}`}>{label ? label : 'Label'}</Label>
                    <input className='form-control' name={name} type={type ? type : 'text'}
                           placeholder={placeholder ? placeholder : name} disabled={disabled}
                           readOnly={readOnly} {...register(name, options)} {...elementProps}/>
                </Fragment>
        }
    }
    return (
        fixedValues && fixedValues[name] ? <Fragment/> :
            <FormGroup>
                {getInput()}
                {errors && <span
                    className='d-block invalid-feedback'>{errors[name] && (errors[name].message ? errors[name].message : `Please provide a valid ${name}.`)}</span>}
            </FormGroup>
    )
}


export default TextInput;