import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {CenterType, CreditOfficerType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";
import {Btn, H5} from "../../../../../AbstractElements";
import React, {useEffect, useRef, useState} from "react";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import SearchOptions from "../../../../CommonComponents/Search";
import CreditOfficerTable from "../../CreditOfficer/Components/CreditOfficerTable";
import CreditOfficerForm from "../../CreditOfficer/Components/Form";

interface creditOfficersProps {
    center: CenterType
}

const CreditOfficer = ({center}: creditOfficersProps) => {
    const [creditOfficerModalVisibility, setCreditOfficerModalVisibility] = useState(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const searchOptions = [
        {name: "name", label: "Credit Officer Name"},
    ];
    const creditOfficerTableRef = useRef();

    const getCreditOfficers = (params: any) => {
        if (creditOfficerTableRef.current) {
            creditOfficerTableRef.current.getCreditOfficers(params);
        }
    }

    const addCreditOfficer = (creditOfficer: CreditOfficerType) => {
        if (creditOfficerTableRef.current) {
            creditOfficerTableRef.current.addCreditOfficer(creditOfficer);
        }
    }

    const creditOfficerModalToggle = () => {
        setCreditOfficerModalVisibility(!creditOfficerModalVisibility);
    };

    useEffect(() => {
        getCreditOfficers({});
    }, []);

    return (
        <Row>
            <Col md={12} className="d-flex align-items-center justify-content-between">
                <H5>{center?.name} Credit Officers</H5>
                <div>
                    <div className="d-flex align-items-center gap-2">
                        <ButtonIcon
                            attrBtn={{onClick: () => setCreditOfficerModalVisibility(true)}}
                            type={"add"}
                        />
                        <ButtonIcon
                            attrBtn={{
                                onClick: getCreditOfficers,
                            }}
                            type={"sync"}
                        />
                        <ButtonIcon
                            attrBtn={{
                                onClick: () => setSearchVisibility(!searchVisibility),
                            }}
                            type={"search"}
                        />
                    </div>
                </div>
            </Col>
            <Col md={12}>
                {searchVisibility && (
                    <SearchOptions
                        callBack={(params: any) => getCreditOfficers(params)}
                        searchOptions={searchOptions}
                    />
                )}
                <CreditOfficerTable
                    ref={creditOfficerTableRef}
                    columnsToHide={{center_code: true, center_name: true}}
                    requestParams={{center_id: center?.id}}
                />
            </Col>
            <Modal isOpen={creditOfficerModalVisibility} toggle={creditOfficerModalToggle} centered>
                <ModalHeader>Create credit officers of {center?.name} center</ModalHeader>
                <ModalBody>
                    <CreditOfficerForm fixedValues={{center_id: center?.id}}
                                         callBack={(creditOfficer: CreditOfficerType) => {
                                             addCreditOfficer(creditOfficer);
                                             creditOfficerModalToggle();
                                         }}/>
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{color: "primary", onClick: creditOfficerModalToggle}}>
                        Close
                    </Btn>
                </ModalFooter>
            </Modal>
        </Row>
    );
}

export default CreditOfficer;
