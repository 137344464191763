import React, {Fragment, useEffect, useState} from 'react';
import {Btn} from '../../../../AbstractElements';
import {Form, FormGroup, Label} from 'reactstrap';
import axiosConfig from '../../../../Config/axios';
import {useForm} from "react-hook-form";
import TextInput from "../../../../CommonElements/Input/TextInput";
import {ErrorHandler} from "../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../Layout/Loader";
import {getPrivilegeRoute} from "../../../../Route/RouteIndex";

const RoleForm = ({callBack, role}) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        formState: {errors},
        watch
    } = useForm();

    useEffect(() => {
        if (role) {
            setValue('name', role.name);
            setValue('display_name', role.display_name);
            setValue('description', role.description);
        }
    }, [role])

    const onSubmit = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        role ? updateRole(data) : createRole(data);
    }

    const createRole = (data: any) => {
        axiosConfig
            .post(getPrivilegeRoute('role'), data)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    }
    const updateRole = (data: any) => {
        if (role) {
            axiosConfig
                .put(`${getPrivilegeRoute('role')}/${role.id}`, data)
                .then((response: { data: { data: any; }; }) => {
                    if (response?.data?.data) {
                        callBack && callBack(response?.data?.data);
                    }
                    setLoaderVisibility(false);
                })
                .catch((exception: any) => {
                    ErrorHandler(exception, setError);
                    setLoaderVisibility(false);
                });
        }
    }

    useEffect(() => {
        setValue('name', watch('display_name').replace(/ /g, '-').toLowerCase());
    }, [watch('display_name')])

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Loader visibility={loaderVisibility}/>
                <FormGroup>
                    <Label className='col-form-label m-0 required'>Key </Label>
                    <input className='form-control' name={'name'}
                           type={'text'}
                           placeholder={'admin'} readOnly={true} {...register('name', {required: true})}/>
                    {errors && <span
                        className='d-block invalid-feedback'>{errors.name && (errors?.name?.message ? errors?.name?.message : `Please provide a valid name.`)}</span>}
                </FormGroup>
                <TextInput name={'display_name'} type={'text'}
                           label={'Name'} register={register}
                           errors={errors} placeholder={'Admin'} options={{required: true}}/>
                <TextInput name={'description'} type={'text'} label={'Description'} register={register}
                           errors={errors} placeholder={'This is Admin'}/>
                <FormGroup>
                    <Btn attrBtn={{
                        className: 'd-block w-100 mt-4',
                        color: 'primary',
                        type: 'submit'
                    }}>{role ? 'Update' : 'Create'}</Btn>
                </FormGroup>
            </Form>
        </Fragment>
    );
};

export default RoleForm;
