import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Row,} from "reactstrap";
import {Breadcrumbs, H5, H6} from "../../../../../AbstractElements";
import {useParams} from "react-router";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import {BootstrapTabContainer, BootstrapTabContent,} from "../../../../../CommonElements/BootstrapTabs";
import CenterForm from "../Components/Form";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import {useNavigate} from "react-router-dom";
import ProfilePicture from "../../../../CommonComponents/MultiMedia/ProfilePicture";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import CenterMembers from "../Components/CenterMembers";
import CreditOfficer from "../Components/CreditOfficer";
import CenterGroups from "../Components/CenterGroups";
import {CenterType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";
import CenterDashboard from "../Components/CenterDashboard";

interface centerShowProps {
}

const CenterShow = ({}: centerShowProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [center, setCenter] = useState<CenterType>();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setLoaderVisibility(true);
        const config = {};
        axiosConfig
            .get(`${getApplicationRoute("center")}/${params.center}`, {
                config,
            })
            .then((response: { data: { data: CenterType } }) => {
                if (response?.data?.data) {
                    setCenter(response.data.data);
                } else {
                    navigate(getApplicationRoute("center"));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
                navigate(getApplicationRoute("center"));
            });
    }, []);

    const deleteCenter = () => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`${getApplicationRoute("center")}/${center.id}`, {})
            .then((response: { data: { data: CenterType } }) => {
                if (response?.data?.data) {
                    navigate(getApplicationRoute("center"));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    return (
        <div>
            <Breadcrumbs
                mainTitle="Center"
                parent="Applications"
                subParentLink={getApplicationRoute("center")}
                subParent={"Centers"}
                title={center?.name}
            />
            <Container fluid={true}>
                <div className="user-profile">
                    <Loader visibility={loaderVisibility}/>
                    <CardHeader className="d-flex align-items-center justify-content-between m-b-25">
                        <H5>Center</H5>
                    </CardHeader>
                    <Row>
                        <Col sm="12" lg="3">
                            <Card className="hovercard text-center">
                                <CardHeader className="social-profile"></CardHeader>
                                <div className="info">
                                    <Row className="step3" data-intro="This is the your details">
                                        <Col sm="12" lg="12" className="order-sm-0 order-xl-1">
                                            <div className="user-designation">
                                                <div className="title">
                                                    <a>{center?.name}</a>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" lg="12" className="order-sm-1 order-xl-0">
                                            <Row>
                                                <Col>
                                                    <div className="ttl-info text-center">
                                                        <H6>{center?.code}</H6>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody>
                                    <div className='social-network theme-form'>
                                        <span className='f-w-600 text-danger'>Danger Zone</span>
                                        <ButtonIcon confirm={true} onclick={() => deleteCenter()} type={'delete'}/>
                                    </div>
                                </CardBody>
                            </Card>
                            {
                                center?.leader ? <Card className='hovercard text-center'>
                                    <CardHeader className='social-profile'>
                                        <div className='d-flex align-items-center gap-2'>
                                        </div>
                                    </CardHeader>
                                    <ProfilePicture/>
                                    <div className='info'>
                                        <Row>
                                            <Col sm='12' lg='12' className='order-sm-1 order-xl-0'>
                                                <Row>
                                                    <Col>
                                                        <div className='ttl-info text-center'>
                                                            <H6>
                                                                {center?.leader?.name}
                                                            </H6>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card> : null
                            }

                        </Col>
                        <Col sm="12" lg="9">
                            <Card className="hovercard">
                                <CardBody>
                                    <BootstrapTabContainer>
                                        <BootstrapTabContent title={'Dashboard'} icon={"icofont icofont-dashboard"}>
                                            <CenterDashboard center={center}/>
                                        </BootstrapTabContent>
                                        <BootstrapTabContent title={'Groups'} icon={"icofont icofont-ui-user"}>
                                            <CenterGroups center={center}/>
                                        </BootstrapTabContent>
                                        <BootstrapTabContent title={'Collectors'} icon={"icofont icofont-ui-user"}>
                                            <CreditOfficer center={center}/>
                                        </BootstrapTabContent>
                                        <BootstrapTabContent title={'Customers'} icon={"icofont icofont-ui-user"}>
                                            <CenterMembers center={center}/>
                                        </BootstrapTabContent>
                                        <BootstrapTabContent
                                            title={"Update"}
                                            icon={"icofont icofont-ui-edit"}
                                        >
                                            <Row className={"justify-content-center"}>
                                                <Col md={6}>
                                                    <CardBody>
                                                        <CenterForm
                                                            center={center}
                                                            callBack={(center: any) => {
                                                                setCenter(center);
                                                            }}
                                                        />
                                                    </CardBody>
                                                </Col>
                                            </Row>
                                        </BootstrapTabContent>

                                        <BootstrapTabContent title={'Activity Log'} icon={'icofont icofont-list'}>
                                            <h6>Activity log</h6>
                                        </BootstrapTabContent>
                                    </BootstrapTabContainer>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default CenterShow;
