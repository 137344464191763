export const PRIVILEGE_ROUTE = 'privilege';
export const SYSTEM_CORE_ROUTE = 'system-core';
export const APPLICATION_ROUTE = 'application';

export const getPrivilegeRoute = (route: string, fullURL: boolean = false) => {
    if (fullURL) {
        return `${process.env.PUBLIC_URL}/${PRIVILEGE_ROUTE}/${route}`;
    } else {
        return `/${PRIVILEGE_ROUTE}/${route}`;
    }
}

export const getSystemCoreRoute = (route: string, fullURL: boolean = false) => {
    if (fullURL) {
        return `${process.env.PUBLIC_URL}/${SYSTEM_CORE_ROUTE}/${route}`;
    } else {
        return `/${SYSTEM_CORE_ROUTE}/${route}`;
    }
}

export const getApplicationRoute = (route: string, fullURL: boolean = false) => {
    if (fullURL) {
        return `${process.env.PUBLIC_URL}/${APPLICATION_ROUTE}/${route}`;
    } else {
        return `/${APPLICATION_ROUTE}/${route}`;
    }
}
