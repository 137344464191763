import {Btn, H5} from "../../../../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import React, {Fragment, useEffect, useState} from "react";
import IconBtn from "../../../../../CommonElements/IconButton";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import ConfigurationForm from "../../Configuration/Component/Form";
import DataTable from "react-data-table-component";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";

const SettingShow = ({setting}) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [configurations, setConfigurations] = useState([]);
    const [selectedConfiguration, setSelectedConfiguration] = useState();
    const [configurationModalVisibility, setConfigurationModalVisibility] = useState(false);

    const configurationModalToggle = () => {
        setConfigurationModalVisibility(!configurationModalVisibility);
    }

    const getConfigurations = () => {
        setLoaderVisibility(true);
        const config = {};
        config.params = {setting_id: setting.id};
        axiosConfig
            .get(`/system-core/configuration`, config)
            .then((response: { data: { data: any; }; }) => {
                if (response?.data?.data) {
                    setConfigurations(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    const addConfiguration = (configuration: { id: any; }) => {
        const configurationList = [...configurations];
        const existingConfigurationIndex = configurationList.findIndex(existingConfiguration => {
            return existingConfiguration.id === configuration.id;
        });
        if (existingConfigurationIndex !== -1) {
            configurationList[existingConfigurationIndex] = configuration;
        } else {
            configurationList.push(configuration);
        }
        setConfigurations(configurationList);
        configurationModalToggle();
    }

    const handleConfigurationEdit = (configuration: any) => {
        setSelectedConfiguration(configuration);
        configurationModalToggle();
    }

    const deleteConfiguration = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`/system-core/configuration/${id}`, {})
            .then((response: { data: { data: { id: any; }; }; }) => {
                if (response?.data?.data) {
                    setConfigurations(current => current.filter(user => {
                        return user.id !== response?.data?.data?.id;
                    }));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    }

    useEffect(() => {
        getConfigurations();
    }, [setting.id])


    const configurationTableColumns = [
        {
            name: 'name', selector: (row: { name: any; }) => row?.name, sortable: false,
        },
        {
            name: 'Key', selector: (row: { key: any; }) => row?.key, sortable: true,
        },
        {
            name: 'description', selector: (row: { description: any; }) => row?.description, sortable: true,
        },
        {
            name: 'Option', selector: (row: { id: any; }) => (<div className='d-flex align-items-right gap-2'>
                {
                    row?.system_defined ? null : <Fragment>
                        <IconBtn attrBtn={{onClick: () => handleConfigurationEdit(row)}} type={'edit'}/>
                        <IconBtn confirm={true} onclick={() => deleteConfiguration(row?.id)} type={'delete'}/>
                    </Fragment>
                }
            </div>), sortable: false, right: true,
        }];

    return (<Fragment>
        <Loader visibility={loaderVisibility}/>

        <Container fluid={true}>
            <div className="email-wrap bookmark-wrap">
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardHeader className='d-flex align-items-center justify-content-between'>
                                    <H5>{setting.name}</H5>
                                    <div>
                                        <div className='d-flex align-items-center gap-2'>
                                            <ButtonIcon attrBtn={{
                                                onClick: () => setConfigurationModalVisibility(true)
                                            }} type={'add'}/>
                                        </div>
                                    </div>
                                </CardHeader>
                                <Row>
                                    <Col md='3' sm='6' xsm='12' className='box-col-3 pe-0 file-spacing'>
                                        <div className='md-sidebar job-sidebar'>
                                            <div className={`md-sidebar-aside job-left-aside custom-scrollbar`}>
                                                <div className='file-sidebar'>
                                                    <Card>
                                                        <CardBody>
                                                            <h5 className="board-title">Key</h5>
                                                            <p className="board-text">{setting.key}</p>
                                                            <h5 className="board-title">Name</h5>
                                                            <p className="board-text">{setting.name}</p>
                                                            <h5 className="board-title">Description</h5>
                                                            <p className="board-text">{setting.description}</p>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md='9' sm='6' xsm='12'>
                                        <Card>
                                            <CardBody>
                                                <DataTable pagination paginationServer
                                                           columns={configurationTableColumns}
                                                           data={configurations}
                                                           highlightOnHover={true}
                                                           striped={true} responsive={true}/>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal isOpen={configurationModalVisibility} toggle={configurationModalToggle} centered>
                <ModalHeader>
                    {selectedConfiguration ? 'Update' : 'Create'} Configuration
                </ModalHeader>
                <ModalBody>
                    <ConfigurationForm setting={setting} callBack={addConfiguration}
                                       configuration={selectedConfiguration}/>
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{color: 'primary', onClick: configurationModalToggle}}>Close</Btn>
                </ModalFooter>
            </Modal>
        </Container>
    </Fragment>)
}

export default SettingShow;