import React, {useEffect, useState} from 'react';
import {FormGroup, Label, Input} from 'reactstrap';
import axiosConfig from "../../Config/axios";
import {ErrorHandler} from "../../_helper/SystemCore/SystemCoreHelpers";
import {ConfigurationType} from "../../Data/Types/SystemCoreDataTypes";

interface RadioButtonGroupProps {
    url: string;
    label: string;
    name: string;
    register: (name: string, options?: any) => any;
    setValue: (name: string, value?: any) => any;
    idColumn?: string,
    nameColumn?: string,
    params?: any;
    errors?: any;
    onChange?: (event: any) => void;
    getSelectedValue?: (selectedValue: any) => void;
    required?: boolean;
    fixedValues?: any;
}

const RadioButtonGroup = ({
                              url,
                              label,
                              name,
                              setValue,
                              register,
                              onChange,
                              fixedValues,
                              getSelectedValue,
                              params = {},
                              idColumn = "id",
                              nameColumn = "name",
                              errors = null,
                              required = false
                          }: RadioButtonGroupProps) => {
    const [options, setOptions] = useState<Array<any>>([]);
    const [checkboxData, setCheckboxData] = useState<ConfigurationType[]>([]);
    const {ref, ...rest} = register(name);

    useEffect(() => {
        const config = {
            params: params,
        };
        axiosConfig
            .get(url, config)
            .then((response: { data: { data: ConfigurationType[] }; }) => {
                if (response?.data?.data) {
                    setCheckboxData(response?.data?.data);
                    setOptions(
                        response.data.data.map((data: any) => ({
                            value: data[idColumn],
                            label: nameColumn.split('.').reduce((o, i) => (o ? o[i] : ""), data),
                        }))
                    );
                }
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
            });
    }, [url, idColumn, nameColumn]);

    const selectSelectedValue = (value: any) => {
        const selectedValues = checkboxData.filter((object: any) => object[idColumn] == value);
        getSelectedValue && getSelectedValue(selectedValues.length ? selectedValues[0] : undefined);
    }

    const onChangeMethod = ({target}: any) => {
        const value = target.value;
        onChange && onChange(value);
        getSelectedValue && selectSelectedValue(value);
        setValue && setValue('name', value);
    };

    return (
        fixedValues && fixedValues[name] ? null :
            <FormGroup>
                <Label className={`col-form-label m-0 ${required ? 'required' : ''}`}>
                    {label ? label : 'Label'}
                </Label>
                <div>
                    {options.map((option) => (
                        <div key={option.value} className="form-check form-check-inline">
                            <Input
                                type="radio"
                                id={`${name}_${option.value}`}
                                name={name}
                                value={option.value}
                                innerRef={ref}
                                {...rest}
                                onChange={onChangeMethod}
                            />
                            <Label for={`${name}_${option.value}`} className="form-check-label">
                                {option.label}
                            </Label>
                        </div>
                    ))}
                </div>
                {errors && errors[name] && (
                    <span className='d-block invalid-feedback'>
                        {errors[name].message ? errors[name].message : `Please select a ${label.toLowerCase()}.`}
                    </span>
                )}
            </FormGroup>
    );
};

export default RadioButtonGroup;
