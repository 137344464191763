import React, {Fragment} from 'react';
import {Col, Container, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import H3 from '../Headings/H3Element';
import SvgIcon from '../../Components/Common/Component/SvgIcon';

interface breadcrumbsProps {
    mainTitle: string,
    parent: string,
    parentLink?: string,
    subParent?: string,
    subParentLink?: string,
    title: string,
    titleLink?: string,
}

const Breadcrumbs = ({mainTitle, parent, parentLink, subParent, subParentLink, title, titleLink}: breadcrumbsProps) => {
    return (
        <Fragment>
            <Container fluid={true}>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'>
                            <H3>{mainTitle}</H3>
                        </Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                                        <SvgIcon iconId='stroke-home'/>
                                    </Link>
                                </li>
                                {parent ? <li className='breadcrumb-item'>{parentLink ?
                                    <Link to={`${process.env.PUBLIC_URL}${parentLink}`}>
                                        {parent}
                                    </Link> : parent}</li> : ''}
                                {subParent ? <li className='breadcrumb-item'>{subParentLink ?
                                    <Link to={`${process.env.PUBLIC_URL}${subParentLink}`}>
                                        {subParent}
                                    </Link> : subParent}</li> : ''}
                                {title ? <li className='breadcrumb-item active'>{titleLink ?
                                    <Link to={`${process.env.PUBLIC_URL}${titleLink}`}>
                                        {title}
                                    </Link> : title}</li> : ''}
                            </ol>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};

export default Breadcrumbs;
