import React, {useEffect, useRef, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row,} from "reactstrap";
import {Breadcrumbs, Btn, H5} from "../../../../../AbstractElements";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import SearchOptions from "../../../../CommonComponents/Search";
import {CreditOfficerType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";
import CreditOfficerTable from "../Components/CreditOfficerTable";
import CreditOfficerForm from "../Components/Form";

const CreditOfficerIndex = () => {
    const [groupModalVisibility, setGroupModalVisibility] = useState(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const creditOfficerTableRef = useRef();
    const searchOptions = [
        {name: "name", label: "Center Name"},
        {name: "name", label: "Group Name"},
    ];

    const groupModalToggle = () => {
        setGroupModalVisibility(!groupModalVisibility);
    };

    const getCreditOfficers = (params: any) => {
        if (creditOfficerTableRef.current) {
            creditOfficerTableRef.current.getCreditOfficers(params);
        }
    }
    const addGroup = (creditOfficer: CreditOfficerType) => {
        if (creditOfficerTableRef.current) {
            creditOfficerTableRef.current.addCreditOfficers(creditOfficer);
        }
    }

    useEffect(() => {
        getCreditOfficers({});
    }, []);

    return (
        <div>
            <Breadcrumbs mainTitle="Groups" parent="Applications" title="Groups"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                <H5>Credit Officer</H5>
                                <div>
                                    <div className="d-flex align-items-center gap-2">
                                        <ButtonIcon
                                            attrBtn={{onClick: () => setGroupModalVisibility(true)}}
                                            type={"add"}
                                        />
                                        <ButtonIcon
                                            attrBtn={{
                                                onClick: getCreditOfficers,
                                            }}
                                            type={"sync"}
                                        />
                                        <ButtonIcon
                                            attrBtn={{
                                                onClick: () => setSearchVisibility(!searchVisibility),
                                            }}
                                            type={"search"}
                                        />
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {searchVisibility && (
                                    <SearchOptions
                                        callBack={(params: any) => getCreditOfficers(params)}
                                        searchOptions={searchOptions}
                                    />
                                )}
                                <CreditOfficerTable ref={creditOfficerTableRef}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal isOpen={groupModalVisibility} toggle={groupModalToggle} centered>
                <ModalHeader>Create Credit Officer</ModalHeader>
                <ModalBody>
                    <CreditOfficerForm callBack={(creditOfficer: CreditOfficerType) => {
                        getCreditOfficers(creditOfficer);
                        groupModalToggle();
                    }}/>
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{color: "primary", onClick: groupModalToggle}}>
                        Close
                    </Btn>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default CreditOfficerIndex;
