import React, {Fragment, useEffect, useState} from "react";
import Loader from "../../../../../Layout/Loader";
import {TableViewType} from "../../../../../Data/Types/UtilityTypes";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import {LoanType} from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";
import {currencyConfig} from "../../../../../Config/ApplicationConfig";
import DataTable from "react-data-table-component";

const LoanReportTable = React.forwardRef(({
                                        requestParams = {},
                                        columnsToHide = {}
                                    }: TableViewType, ref) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [loans, setLoans] = useState<LoanType[]>([]);

    React.useImperativeHandle(ref, () => ({
        getLoans
    }));

    const getLoans = (searchParams = {}) => {
        setLoaderVisibility(true);
        const config = {params: {...searchParams, ...requestParams}};
        axiosConfig
            .get(getApplicationRoute('loan'), config)
            .then((response: { data: { data: Array<LoanType>; }; }) => {
                if (response?.data?.data) {
                    setLoans(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    useEffect(() => {
        getLoans({});
    }, []);

    const loanTableColumns = [
        {
            name: 'Customer',
            selector: (loan: LoanType) => loan?.member?.member?.name,
            sortable: false,
        },
        {
            name: 'Group',
            selector: (loan: LoanType) => loan?.member?.group?.name,
            sortable: false,
        },
        {
            name: 'Center',
            selector: (loan: LoanType) => loan?.center_collector?.center?.name,
            sortable: false,
        },
        {
            name: 'Credit Officer',
            selector: (loan: LoanType) => loan?.center_collector?.collector?.user?.name,
            sortable: false,
        },
        {
            name: 'Loan Amount',
            selector: (loan: LoanType) => (loan?.amount)?.toLocaleString('en-LK', currencyConfig),
            sortable: false,
        },
        {
            name: 'Loan With Interest',
            selector: (loan: LoanType) => (loan?.amount + loan?.interest)?.toLocaleString('en-LK', currencyConfig),
            sortable: false,
        },
        {
            name: 'Installment Type',
            selector: (loan: LoanType) => loan?.installment_type?.name,
            sortable: false,
        },
        {
            name: 'Installment Range',
            selector: (loan: LoanType) => loan?.installment_range,
            sortable: false,
        },
        {
            name: 'Interest Rate Type',
            selector: (loan: LoanType) => loan?.interest_rate_type?.name,
            sortable: false,
        },
        {
            name: 'Interest Rate',
            selector: (loan: LoanType) => loan?.interest_rate,
            sortable: false,
        },
        {
            name: 'Collection Start Date',
            selector: (loan: LoanType) => loan?.collection_start_date,
            sortable: false,
        },
        {
            name: 'Request Date',
            selector: (loan: LoanType) => loan?.request_date,
            sortable: false,
        },
        {
            name: 'Approved Date',
            selector: (loan: LoanType) => loan?.approved_date,
            sortable: false,
        },
        {
            name: 'Released Date',
            selector: (loan: LoanType) => loan?.released_date,
            sortable: false,
        },
        {
            name: 'Status',
            selector: (loan: LoanType) => loan?.status,
            sortable: true,
            center: true,
        },

    ];

    const checkColumnVisibility = (columns: any[] = []) => {
        return columns.filter((column) => !columnsToHide[column.name.toLowerCase().replace(/\s+/g, '_')])
    }
    return (
        <Fragment>
            <Loader visibility={loaderVisibility}/>
            <DataTable pagination paginationServer columns={checkColumnVisibility(loanTableColumns)} data={loans}
                       highlightOnHover={true}
                       striped={true} responsive={true}/>
        </Fragment>
    );
})

export default LoanReportTable;