import React, {Suspense, useEffect} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Loader from "../Layout/Loader";
import {authRoutes} from "./AuthRoutes";
import PrivateRoute from "./PrivateRoute";
import {routes} from "./Routes";
import AppLayout from "../Layout/Layout";
import Error404 from "../Application/SystemCore/Common/Screen/ErrorPage404";

const Routers = () => {
    const authenticated = !!localStorage.getItem("authenticated_user") && !!localStorage.getItem('authenticated_user');

    const removeAuth = () => {
        localStorage.removeItem("authenticated_user");
        localStorage.removeItem("authenticated_token");
    }

    useEffect(() => {
        if (authenticated) {
            if (!localStorage.getItem('authenticated_token') || !localStorage.getItem('authenticated_user')) {
                removeAuth();
            }
        } else {
            removeAuth();
        }
    }, [authenticated]);

    return (<BrowserRouter basename={"/"}>
        <Suspense fallback={<Loader/>}>
            <Routes>
                {authenticated ? (<Route path={"/"} element={<PrivateRoute/>}>
                    <Route exact path={`/`} element={<Navigate
                        to={`${process.env.PUBLIC_URL}/dashboard`}/>}/>
                    {routes.map(({path, Component}, i) => <Route key={i} element={<AppLayout/>}>
                        <Route path={path} element={Component}/>
                    </Route>)}
                    <Route path={`/*`} element={<Error404/>}/>
                </Route>) : (<Route>
                    {authRoutes.map(({path, Component}, i) => <Route path={path} element={Component} key={i}/>)}
                    <Route exact path={`/*`} element={<Navigate
                        to={`${process.env.PUBLIC_URL}/login`}/>}/>
                </Route>)}
            </Routes>
        </Suspense>
    </BrowserRouter>);
};

export default Routers;
