import MemberTable from "../../../Employee/Member/Component/MemberTable";
import React, {useRef, useState} from "react";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import SearchOptions from "../../../../CommonComponents/Search";
import MemberForm from "../../../Employee/Member/Component/Form";
import {Btn, H5} from "../../../../../AbstractElements";

interface centerMembersProps {
    center: any
}

const CenterMembers = ({center}: centerMembersProps) => {
    const [memberModalVisibility, setMemberModalVisibility] = useState(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const memberTableRef = useRef();

    const searchOptions = [{name: 'name'}, {name: 'memberType', label: 'Member Type'}, {name: 'nic', label: 'NIC'}];

    const getMembers = (params: any) => {
        if (memberTableRef.current) {
            memberTableRef.current.getMembers(params)
        }
    }

    const addMember = (member: any) => {
        if (memberTableRef.current) {
            memberTableRef.current.addMember(member)
        }
    }

    const memberModalToggle = () => {
        setMemberModalVisibility(!memberModalVisibility);
    }

    return (<Row>
        <Col md={12} className='d-flex align-items-center justify-content-between'>
            <H5>{center.name} Customers</H5>
            <div>
                <div className='d-flex align-items-center gap-2'>
                    <ButtonIcon attrBtn={{
                        onClick: () => setMemberModalVisibility(true)
                    }} type={'add'}/>

                    <ButtonIcon attrBtn={{
                        onClick: () => getMembers({})
                    }} type={'sync'}/>
                    <ButtonIcon attrBtn={{
                        onClick: () => setSearchVisibility(!searchVisibility)
                    }} type={'search'}/>
                </div>
            </div>
        </Col>
        <Col md={12}>
            {searchVisibility && <SearchOptions callBack={(params: any) => getMembers(params)}
                                                searchOptions={searchOptions}/>}
            <MemberTable ref={memberTableRef} actionsToHide={{delete: true}}
                         columnsToHide={{center: true}}
                         requestParams={{center_id: center?.id}}/>
        </Col>
        <Modal isOpen={memberModalVisibility} toggle={memberModalToggle} centered>
            <ModalHeader>
                Create customer of {center.name} center
            </ModalHeader>
            <ModalBody>
                <MemberForm fixedValues={{center_id: center?.id}} callBack={(member: any) => {
                    addMember(member);
                    memberModalToggle()
                }}/>
            </ModalBody>
            <ModalFooter>
                <Btn attrBtn={{color: 'primary', onClick: memberModalToggle}}>Close</Btn>
            </ModalFooter>
        </Modal>
    </Row>);
}

export default CenterMembers;