import {toast} from "react-toastify";

export const capitalizeFirstLetter = (stringToCapitalize) => {
    const words = stringToCapitalize.split(" ");
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
}

export const ErrorHandler = (exception, setError = null) => {
    if (exception?.response?.status) {
        switch (exception.response.status) {
            case 422:
                if (setError) {
                    Object.keys(exception.response.data?.errors).map(((name) => {
                        setError(name, {message: exception.response.data?.errors[name].map(error => error)});
                    }))
                } else {
                    toast.error(`${Object.keys(exception.response.data?.errors).length} Validation Error(s)`, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
                break;
            case 500:
                toast.error(exception?.response.data?.status?.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                break;
            default:
                toast.error(`Oops! Something went wrong! Please try again`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
        }
    } else {
        toast.error(exception, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
        });
    }
}