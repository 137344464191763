import React, { Fragment, useEffect, useState } from "react";
import { getApplicationRoute } from "../../../../../Route/RouteIndex";
import IconBtn from "../../../../../CommonElements/IconButton";
import axiosConfig from "../../../../../Config/axios";
import { ErrorHandler } from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import DataTable from "react-data-table-component";
import { TableViewType } from "../../../../../Data/Types/UtilityTypes";
import { MemberType } from "../../../../../Data/Types/Application/ApplicationEmployeeDataTypes";

const MemberTable = React.forwardRef(({
                                          requestParams = {},
                                          actionsToHide = {},
                                          columnsToHide = {}
                                      }: TableViewType, ref) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [members, setMembers] = useState<Array<MemberType>>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    React.useImperativeHandle(ref, () => ({
        getMembers,
        addMember
    }));

    useEffect(() => {
        getMembers({ page: currentPage, perPage });
    }, [currentPage, perPage]);

    const getMembers = (params: any) => {
        setLoaderVisibility(true);
        const config = {
            params: { ...params, ...requestParams }
        };
        axiosConfig
            .get(getApplicationRoute('member'), config)
            .then((response: { data: { data: Array<MemberType>; total: number } }) => {
                if (response?.data?.data) {
                    setMembers(response.data.data);
                    setTotalRows(response.data.total);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage: number) => {
        setPerPage(newPerPage);
        setCurrentPage(1);
    };

    const deleteMember = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`${getApplicationRoute('member')}/${id}`, {})
            .then((response: { data: { data: MemberType } }) => {
                if (response?.data?.data) {
                    setMembers((current: MemberType[]) => current.filter((member: MemberType) => member.id !== response?.data?.data?.id));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const addMember = (member: MemberType) => {
        const memberList = [...members];
        const existingMemberIndex = memberList.findIndex((existingMember: MemberType) => existingMember.id === member.id);
        if (existingMemberIndex !== -1) {
            memberList[existingMemberIndex] = member;
        } else {
            memberList.push(member);
        }
        setMembers(memberList);
    };

    const memberTableColumns = [
        {
            name: 'Name',
            selector: (member: { user: { name: any; }; }) => member?.user?.name,
            sortable: false,
        },
        {
            name: 'Customer Type',
            selector: (member: { member_type: { name: any; }; }) => member?.member_type?.name,
            sortable: false,
        },
        {
            name: 'Center',
            selector: (member: { center: { name: any; }; }) => member?.center?.name,
            sortable: false,
        },
        {
            name: 'Gender',
            selector: (member: { gender: { name: any; }; }) => member?.gender?.name,
            sortable: false,
        },
        {
            name: 'NIC',
            selector: (member: { nic: any; }) => member?.nic,
            sortable: true,
            center: true,
        },
        {
            name: 'Option',
            selector: (row: { id: any; system_defined: any; }) =>
                <div className='d-flex align-items-right gap-2'>
                    {!actionsToHide.view ?
                        <IconBtn link={`${getApplicationRoute('customer')}/${row.id}`} type={'view'}/> : null}
                    {!actionsToHide.delete && !row.system_defined &&
                        <IconBtn confirm={true} onclick={() => deleteMember(row.id)} type={'delete'}/>}
                </div>
            , sortable: false, right: true,
        }
    ];

    const checkColumnVisibility = (columns: any[] = []) => {
        return columns.filter((column) => !columnsToHide[column.name.toLowerCase().replace(/\s+/g, '_')]);
    };

    return (
        <Fragment>
            <Loader visibility={loaderVisibility}/>
            <DataTable
                pagination
                paginationServer
                columns={checkColumnVisibility(memberTableColumns)}
                data={members}
                highlightOnHover={true}
                striped={true}
                responsive={true}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationPerPage={perPage}
            />
        </Fragment>
    );
});

export default MemberTable;
