import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Row,} from "reactstrap";
import {Breadcrumbs, H5, H6} from "../../../../../AbstractElements";
import {useParams} from "react-router";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import {BootstrapTabContainer, BootstrapTabContent,} from "../../../../../CommonElements/BootstrapTabs";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import {useNavigate} from "react-router-dom";
import {CreditOfficerType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";
import CreditOfficerForm from "../Components/Form";

interface creditOfficerShowProps {
}

const CreditOfficerShow = ({}: creditOfficerShowProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [creditOfficer, setCreditOfficer] = useState<CreditOfficerType>();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setLoaderVisibility(true);
        const config = {};
        axiosConfig
            .get(`${getApplicationRoute("center-collector")}/${params.creditOfficer}`, {
                config,
            })
            .then((response: { data: { data: CreditOfficerType } }) => {
                if (response?.data?.data) {
                    setCreditOfficer(response.data.data);
                } else {
                    navigate(getApplicationRoute("center-collector"));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
                navigate(getApplicationRoute("center-collector"));
            });
    }, []);

    const deleteEntity = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`${getApplicationRoute("center-collector")}/${id}`, {})
            .then((response: { data: { data: CreditOfficerType } }) => {
                if (response?.data?.data) {
                    navigate(getApplicationRoute("center-collector"));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    return (
        <div>
            <Breadcrumbs
                mainTitle="Credit Officers-"
                parent="Applications"
                subParentLink={getApplicationRoute("center-collector")}
                subParent={"Credit Officers"}
                title={creditOfficer?.id}
            />
            <Container fluid={true}>
                <div className="user-profile">
                    <Loader visibility={loaderVisibility}/>
                    <CardHeader className="d-flex align-items-center justify-content-between m-b-25">
                        <H5>Center Credit Officers</H5>
                        <div>
                            {/* <div className='d-flex align-items-center gap-2'>
                                {!role.system_defined && <ButtonIcon attrBtn={{
                                    onClick: RoleShow
                                }} type={'delete'}/>}
                            </div> */}
                        </div>
                    </CardHeader>
                    <Row>
                        <Col sm="12" lg="3">
                            <Card className="hovercard text-center">
                                <CardHeader className="social-profile"></CardHeader>
                                <div className="info" style={{height: "150px"}}>
                                    <Row className="step3" data-intro="This is the your details">
                                        <Col sm="12" lg="12" className="order-sm-0 order-xl-1">
                                            <div className="user-designation">
                                                <div className="title">
                                                    <a>{creditOfficer?.center_id}</a>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6" lg="12" className="order-sm-1 order-xl-0">
                                            <Row>
                                                <Col>
                                                    <div className="ttl-info text-start">
                                                        <H6>
                                                            Key{" "}
                                                            <span className={"m-l-10"}>
                                {creditOfficer?.collector_id}
                              </span>
                                                        </H6>
                                                        <H6>
                                                            Description{" "}
                                                            <span className={"m-l-10"}>
                                {creditOfficer?.status}
                              </span>
                                                        </H6>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="9">
                            <Card className="hovercard">
                                <BootstrapTabContainer>
                                    <BootstrapTabContent
                                        title={"Dashboard"}
                                        icon={"icofont icofont-dashboard"}
                                    >
                                        <h2>This is Dashboarddd</h2>
                                    </BootstrapTabContent>
                                    {/* <BootstrapTabContent title={'Users'} icon={'icofont icofont-ui-user'}>
                                        <CardBody>
                                            <ListGroup>
                                                <Row>
                                                    {role?.users?.map((user, key) => (
                                                        <Col sm="4" key={key}>
                                                            <Card>
                                                                <CardBody style={{height: '150px'}}
                                                                          className="shadow-lg p-25 shadow-showcase align-items-center text-center">
                                                                    <h6>
                                                                        {user.name}
                                                                    </h6>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </ListGroup>
                                        </CardBody>
                                    </BootstrapTabContent> */}
                                    {/* <BootstrapTabContent title={'Permissions'} icon={'icofont icofont-key'}>
                                        <RolePermissionListView roleID={collector?.id}/>
                                    </BootstrapTabContent> */}
                                    <BootstrapTabContent
                                        title={"Update"}
                                        icon={"icofont icofont-ui-edit"}
                                    >
                                        <Row className={"justify-content-center"}>
                                            <Col md={6}>
                                                <CardBody>
                                                    {/* <RoleForm role={role} callBack={(role) => {
                                                        setRole(role)
                                                    }}/> */}
                                                    <CreditOfficerForm
                                                        creditOfficer={creditOfficer}
                                                        callBack={(creditOfficer) => {
                                                            setCreditOfficer(creditOfficer);
                                                        }}
                                                    />
                                                </CardBody>
                                            </Col>
                                        </Row>
                                    </BootstrapTabContent>
                                </BootstrapTabContainer>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default CreditOfficerShow;
