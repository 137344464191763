import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {useForm} from "react-hook-form";
import {Col, Form, Row} from "reactstrap";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import TextInput from "../../../../../CommonElements/Input/TextInput";
import Loader from "../../../../../Layout/Loader";
import {Btn} from "../../../../../AbstractElements";
import BackendDropDown from "../../../../../CommonElements/DropDown/BackendDropDown";

interface updateLoanFormProps {
    callBack?: (data: any) => void
    loanData?: any
}

const UpdateLoanForm = ({callBack, loanData = {}}: updateLoanFormProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [loan, setLoan] = useState<any>(loanData);
    const params = useParams();

    const {
        handleSubmit,
        register,
        setError,
        clearErrors,
        formState: {errors},
        setValue
    } = useForm();

    useEffect(() => {
        if (loan) {
            setValue('group_member_id', loan.group_member?.id);
            setValue('center_collector_id', loan.center_collector?.id);
            setValue('configuration_id', loan.configuration?.id);
            setValue('amount', loan.amount);
            setValue('interest_rate', loan.interest_rate);
            setValue('start_date', loan.start_date);
            setValue('end_date', loan.end_date);
            setValue('status', loan.status);
        }
    }, [loan,setValue])

    useEffect(() => {
        if (!loanData?.id) {
            setLoaderVisibility(true);
            const config = {};
            axiosConfig
                .get(`/application/loan/${params.loan}`, config)
                .then((response: any) => {
                    if (response?.data?.data) {
                        setLoan(response.data.data);
                    }
                    setLoaderVisibility(false);
                })
                .catch((exception: any) => {
                    ErrorHandler(exception);
                    setLoaderVisibility(false);
                });
        }
    }, [params.loan, loanData?.id]);

    const onSubmit = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        axiosConfig
            .put(`/application/loan/${loan.id}`, data)
            .then((response: any) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    }

    return (
        <div>
            <Loader visibility={loaderVisibility}/>
            <Row className={'justify-content-center'}>
                <Col md={6}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <BackendDropDown name={'group_member_id'} type={'select-url'} label={'Group-Member'}
                                         setValue={setValue}
                                         errors={errors} url={'application/group-member'} options={{required: true}}/>

                        <BackendDropDown name={'center_collector_id'} type={'select-url'} label={'Center-Collector'}
                                         setValue={setValue}
                                         errors={errors} url={'application/center-collector'} options={{required: true}}/>

                        <BackendDropDown name={'configuration_id'} type={'select-url'} label={'Interest Rate Type'}
                                         params={{setting_key: 'interest-rate-type'}}
                                         setValue={setValue}
                                         errors={errors} url={'system-core/configuration'} options={{required: true}}/>

                        <BackendDropDown name={'configuration_id'} type={'select-url'} label={'Installment Type'}
                                         params={{setting_key: 'installment-type'}}
                                         setValue={setValue}
                                         errors={errors} url={'system-core/configuration'} options={{required: true}}/>

                        <TextInput name={'installment_range'} type={'text'} label={'Installment Range'} register={register}
                                   errors={errors} placeholder={'Installment Range'} options={{required: true}}/>
                        <TextInput name={'amount'} type={'text'} label={'Amount'} register={register}
                                   errors={errors} placeholder={'Amount'} options={{required: true}}/>
                        <TextInput name={'interest_rate'} type={'text'} label={'Interest Rate'} register={register}
                                   errors={errors} placeholder={'Interest Rate'} options={{required: true}}/>
                        <TextInput name={'start_date'} type={'text'} label={'Start Date'} register={register}
                                   errors={errors} placeholder={'Start Date'} options={{required: true}}/>
                        <TextInput name={'end_date'} type={'text'} label={'End Date'} register={register}
                                   errors={errors} placeholder={'End Date'} options={{required: true}}/>
                        <TextInput name={'status'} type={'text'} label={'Status'} register={register}
                                   errors={errors} placeholder={'Status'} options={{required: true}}/>

                        <Btn attrBtn={{
                            className: 'd-block w-100 mt-4',
                            color: 'primary',
                            type: 'submit',
                        }}>{'Update'}</Btn>
                    </Form>
                </Col>
            </Row>
        </div>
    );

}
export default UpdateLoanForm;
