import React, {useRef, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {useNavigate} from 'react-router-dom';
import Loader from '../../../../../Layout/Loader';
import axiosConfig from '../../../../../Config/axios';
import {Breadcrumbs, H5} from '../../../../../AbstractElements';
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import SearchOptions from "../../../../CommonComponents/Search";
import {LoanType} from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import LoanTable from "../Component/LoanTable";

const LoanIndex = () => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
    const navigate = useNavigate();
    const loanTableRef = useRef<any>(null);
    const searchOptions = [
        {name: 'group_member', label: 'Group Member'},
        {name: 'center_collector', label: 'Credit Officer'},
        {name: 'nic', label: 'NIC'},
        {name: 'branch_name', label: 'Branch'},
        {name: 'center_name', label: 'Center'},
        {name: 'status', label: 'Status'},
    ];

    const getLoans = (params: any) => {
        if (loanTableRef.current) {
            loanTableRef.current.getLoans(params);
        }
    };

    return (
        <div>
            <Breadcrumbs mainTitle="Loans" parent="Application" title="Loans"/>
            <Container fluid={true}>
                <Loader visibility={loaderVisibility}/>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className='d-flex align-items-center justify-content-between'>
                                <H5>Loans</H5>
                                <div>
                                    <div className='d-flex gap-2'>
                                        <ButtonIcon attrBtn={{
                                            onClick: () => navigate('/application/loan/create')
                                        }} type={'add'}/>

                                        <ButtonIcon attrBtn={{
                                            onClick: () => setSearchVisibility(!searchVisibility)
                                        }} type={'search'}/>

                                        <ButtonIcon attrBtn={{
                                            onClick: getLoans
                                        }} type={'sync'}/>

                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {searchVisibility && <SearchOptions callBack={(loan: LoanType) => getLoans(loan)}
                                                                    searchOptions={searchOptions}/>}

                                <LoanTable ref={loanTableRef}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


export default LoanIndex;