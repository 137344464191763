import React, {Fragment} from 'react';
import {H6, Spinner} from "../../AbstractElements";
import {Col} from "reactstrap";

const Loader = ({visibility}) => {
    return (
        <Fragment>
            <div className={`loader-wrapper ${visibility ? '' : 'loderhide'}`}>
                <Col md="3" >
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-17' }} />
                    </div>
                </Col>
            </div>
        </Fragment>
    );
};

export default Loader;
