import React, {Fragment, useEffect, useState} from "react";
import Loader from "../../../../../Layout/Loader";
import {TableViewType} from "../../../../../Data/Types/UtilityTypes";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import DataTable from "react-data-table-component";
import {InstallmentType} from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";

const CompletedLoanReportTable = React.forwardRef(({
                                                requestParams = {},
                                                columnsToHide = {}
                                            }: TableViewType, ref) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [installments, setInstallments] = useState<InstallmentType[]>([]);


    React.useImperativeHandle(ref, () => ({
        getInstallments,
    }))

    const getInstallments = (searchParams = {}) => {
        setLoaderVisibility(true);

        const updatedSearchParams = {
            ...searchParams,
            status: 'paid',
            ...requestParams
        };

        const config = { params: updatedSearchParams };

        axiosConfig
            .get(getApplicationRoute('installment'), config)
            .then((response) => {
                if (response?.data?.data) {
                    setInstallments(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    useEffect(() => {
        getInstallments({});
    }, []);

    const completedInstallmentTableColumns = [
        {
            name: "Member",
            selector: (installment: InstallmentType) => installment?.loan?.member?.member?.name,
            sortable: true,
        },
        {
            name: "Group",
            selector: (installment: InstallmentType) => installment?.loan?.member?.group?.name,
            sortable: true,
        },
        {
            name: "Center",
            selector: (installment: InstallmentType) => installment.loan?.center_collector?.center?.name,
            sortable: false,
        },
        {
            name: "Collector",
            selector: (installment: InstallmentType) => installment.loan?.center_collector?.collector?.name,
            sortable: true,
        },
        {
            name: "Installment Type",
            selector: (installment: InstallmentType) => installment.loan?.installment_type?.key,
            sortable: false,
        },
        {
            name: "Interest Rate Type",
            selector: (installment: InstallmentType) => installment.loan?.interest_rate_type?.key,
            sortable: false,
        },
        {
            name: "Installment Range",
            selector: (installment: InstallmentType) => installment.loan?.installment_range,
            sortable: false,
        },
        {
            name: "Amount",
            selector: (installment: InstallmentType) => installment.loan?.amount,
            sortable: false,
        },
        {
            name: "Interest Rate",
            selector: (installment: InstallmentType) => installment.loan?.interest_rate,
            sortable: false,
        },
        {
            name: "Collection Start Date",
            selector: (installment: InstallmentType) => installment.loan?.collection_start_date,
            sortable: false,
        },
        {
            name: "Request Date",
            selector: (installment: InstallmentType) => installment.loan?.request_date,
            sortable: false,
        },
        {
            name: "Approved Date",
            selector: (installment: InstallmentType) => installment.loan?.approved_date,
            sortable: false,
        },
        {
            name: "Released Date",
            selector: (installment: InstallmentType) => installment.loan?.released_date,
            sortable: false,
        },
        {
            name: "Status",
            selector: (installment: InstallmentType) => installment.status,
            sortable: false,
        },

    ];

    const checkColumnVisibility = (columns: any[] = []) => {
        return columns.filter((column) => !columnsToHide[column.name.toLowerCase().replace(/\s+/g, '_')])
    }
    return (
        <Fragment>
            <Loader visibility={loaderVisibility}/>
            <DataTable pagination paginationServer columns={checkColumnVisibility(completedInstallmentTableColumns)} data={installments}
                       highlightOnHover={true}
                       striped={true} responsive={true}/>
        </Fragment>
    );
});

export default CompletedLoanReportTable;