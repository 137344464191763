import React, {ForwardedRef, Fragment, useState} from "react";
import {TableViewType} from "../../../../../Data/Types/UtilityTypes";
import DataTable from "react-data-table-component";
import {GroupType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";
import axiosConfig from "../../../../../Config/axios";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import IconBtn from "../../../../../CommonElements/IconButton";
import Loader from "../../../../../Layout/Loader";

const GroupTable = React.forwardRef(({
                                         requestParams = {},
                                         actionsToHide = {},
                                         columnsToHide = {}
                                     }: TableViewType, ref: ForwardedRef<any>) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [groupData, setGroupData] = useState<GroupType[]>([]);

    React.useImperativeHandle(ref, () => ({
        getGroups,
        addGroup
    }));

    const getGroups = (params: any) => {
        setLoaderVisibility(true);
        const config = {params: {...params, ...requestParams}};
        axiosConfig
            .get(getApplicationRoute("group"), config)
            .then((response: { data: { data: GroupType[] } }) => {
                if (response?.data?.data) {
                    setGroupData(response.data.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const deleteGroup = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`${getApplicationRoute("group")}/${id}`, {})
            .then((response: { data: { data: GroupType } }) => {
                if (response?.data?.data) {
                    setGroupData((current: GroupType[]) =>
                        current.filter((group: GroupType) => {
                            return group.id !== response?.data?.data?.id;
                        })
                    );
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const addGroup = (group: GroupType) => {
        const groupDataList = [...groupData];
        const existingGroupIndex = groupDataList.findIndex((existingGroup: GroupType) => {
            return existingGroup.id === group.id;
        });
        if (existingGroupIndex !== -1) {
            groupDataList[existingGroupIndex] = group;
        } else {
            groupDataList.push(group);
        }
        setGroupData(groupDataList);
    };

    const groupTableColumns = [
        {
            name: "Center",
            selector: (group: GroupType) => group.center.name,
            sortable: true,
        },
        {
            name: "Group Name",
            selector: (group: GroupType) => group.name,
            sortable: false,
        },
        {
            name: "Customers",
            selector: (group: GroupType) => group.members?.length,
            sortable: false,
        },
        {
            name: "Option",
            cell: (group: GroupType) => (
                <div className="d-flex align-items-right gap-2">
                    {!actionsToHide.delete && <IconBtn
                        link={`${getApplicationRoute("group")}/${group.id}`}
                        type={"view"}
                    />}
                    {!actionsToHide.delete && (
                        <IconBtn
                            confirm={true}
                            onclick={() => deleteGroup(group.id)}
                            type={"delete"}
                        />
                    )}
                </div>
            ),
        },
    ];

    const checkColumnVisibility = (columns: any[] = []) => {
        return columns.filter((column) => !columnsToHide[column.name.toLowerCase().replace(/\s+/g, '_')])
    }

    return (<Fragment>
        <Loader visibility={loaderVisibility}/>
        <DataTable
            pagination
            paginationServer
            highlightOnHover={true}
            striped={true}
            responsive={true}
            data={groupData}
            columns={checkColumnVisibility(groupTableColumns)}
        />
    </Fragment>)
});
export default GroupTable