import React, {Fragment, useEffect, useState} from "react";
import {Form, FormGroup} from "reactstrap";
import {useForm} from "react-hook-form";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {Btn} from "../../../../../AbstractElements";
import TextInput from "../../../../../CommonElements/Input/TextInput";
import Loader from "../../../../../Layout/Loader";
import axiosConfig from "../../../../../Config/axios";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import BackendDropDown from "../../../../../CommonElements/DropDown/BackendDropDown";
import {BranchType, CenterType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";

interface centerFormProps {
    center?: CenterType;
    fixedValues?: any,
    callBack?: (center:CenterType) => void;
}

const CenterForm = ({callBack, center, fixedValues}: centerFormProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState<BranchType>();
    const [reload, setReload] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        watch,
        clearErrors,
        formState: {errors},
    } = useForm();

    useEffect(() => {
        if (center) {
            setValue("branch_id", center?.branch?.id);
            setValue("leader_id", center?.leader?.id);
            setValue("name", center.name);
            setValue("code", center.code);
        }
    }, [center]);

    const onSubmit = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        if (center) {
            updateCenter(data);
        } else {
            createCenter(data);
        }
    };

    const createCenter = (data: any) => {
        axiosConfig
            .post(getApplicationRoute("center"), {...data, ...fixedValues})
            .then((response: { data: { data: CenterType } }) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    };

    const updateCenter = (data: any) => {
        if (center) {
            axiosConfig
                .put(`${getApplicationRoute("center")}/${center.id}`, data)
                .then((response: { data: { data: CenterType } }) => {
                    if (response?.data?.data) {
                        callBack && callBack(response?.data?.data);
                    }
                    setLoaderVisibility(false);
                })
                .catch((exception: any) => {
                    ErrorHandler(exception, setError);
                    setLoaderVisibility(false);
                });
        }
    };

    useEffect(() => {
        if (!watch("code").includes(`${selectedBranch?.code ? selectedBranch?.code : ''}-`) && selectedBranch) {
            setValue("code", `${selectedBranch?.code ? selectedBranch?.code : ''}-`);
        }
    }, [watch("code"), selectedBranch])

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Loader visibility={loaderVisibility}/>

                <BackendDropDown
                    fixedValues={fixedValues}
                    getSelectedValue={setSelectedBranch}
                    name={"branch_id"}
                    type={"select-url"}
                    label={"Branches"}
                    setValue={setValue}
                    nameColumn={"branch_name"}
                    value={center?.branch?.id}
                    errors={errors}
                    url={"/application/branch"}
                    options={{required: true}}
                />
                <TextInput
                    fixedValues={fixedValues}
                    name={"name"}
                    type={"text"}
                    label={"Center Name"}
                    register={register}
                    errors={errors}
                    placeholder={"center name"}
                    options={{required: true}}
                />
                <TextInput
                    fixedValues={fixedValues}
                    name={"code"}
                    type={"text"}
                    label={"Code"}
                    register={register}
                    errors={errors}
                    placeholder={"Code"}
                    options={{required: true}}
                />
                <BackendDropDown
                    fixedValues={fixedValues}
                    name={"leader_id"}
                    type={"select-url"}
                    label={"Leader"}
                    setValue={setValue}
                    value={center?.leader?.id}
                    errors={errors}
                    reload={reload}
                    params={{
                        designation : 'manager'
                    }}
                    url={"/application/employee"}
                    options={{required: true}}
                />
                <FormGroup>
                    <Btn
                        attrBtn={{
                            className: "d-block w-100 mt-4",
                            color: "primary",
                            type: "submit",
                        }}
                    >
                        {center ? "Update" : "Create"}
                    </Btn>
                </FormGroup>
            </Form>
        </Fragment>
    );
};

export default CenterForm;
