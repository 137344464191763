import React, {Fragment, useEffect, useState} from 'react';
import {Btn} from '../../../../../AbstractElements';
import {Form, FormGroup} from 'reactstrap';
import axiosConfig from '../../../../../Config/axios';
import {useForm} from "react-hook-form";
import TextInput from "../../../../../CommonElements/Input/TextInput";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import Loader from "../../../../../Layout/Loader";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import BackendDropDown from "../../../../../CommonElements/DropDown/BackendDropDown";
import {EmployeeType} from "../../../../../Data/Types/Application/ApplicationEmployeeDataTypes";

interface employeeFormProps {
    employee?: EmployeeType,
    fixedValues?: any,
    callBack?: (employee: EmployeeType) => void,
}

const EmployeeForm = ({callBack, employee, fixedValues}: employeeFormProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        formState: {errors},
    } = useForm();

    useEffect(() => {
        if (employee) {
            setValue('user_id', employee.user?.id);
            setValue('designation_id', employee.designation?.id);
            setValue('branch_id', employee.branch?.id);
            setValue('nic', employee.nic);
            setValue('name', employee.user?.name);
            setValue('contact_number', employee.phone);
            setValue('address', employee.address);
        }
    }, [employee])

    const onSubmit = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        employee ? updateEmployee(data) : createEmployee(data);
    }

    const createEmployee = (data: any) => {
        axiosConfig
            .post(getApplicationRoute('employee'), {...data, ...fixedValues})
            .then((response: { data: { data: EmployeeType }; }) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    }


    const updateEmployee = (data: any) => {
        if (employee) {
            axiosConfig
                .put(`${getApplicationRoute('employee')}/${employee.id}`, data)
                .then((response: { data: { data: EmployeeType }; }) => {
                    if (response?.data?.data) {
                        callBack && callBack(response?.data?.data);
                    }
                    setLoaderVisibility(false);
                })
                .catch((exception: any) => {
                    ErrorHandler(exception, setError);
                    setLoaderVisibility(false);
                });
        }
    }

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Loader visibility={loaderVisibility}/>
                <FormGroup>
                    <BackendDropDown fixedValues={fixedValues} name={'designation_id'}
                                     params={{setting_key: 'designation-type'}}
                                     type={'select-url'} label={'Designation'} nameColumn={'name'}
                                     value={employee?.designation?.id}
                                     setValue={setValue}
                                     errors={errors} url={'system-core/configuration'}
                                     options={{required: true}}/>

                    <BackendDropDown fixedValues={fixedValues} name={'branch_id'} type={'select-url'} label={'Branch'}
                                     nameColumn={'branch_name'}
                                     value={employee?.branch?.id}
                                     setValue={setValue}
                                     errors={errors} url={'application/branch'} options={{required: true}}/>

                    <TextInput fixedValues={fixedValues} name={'name'} type={'text'} label={'Name'} register={register}
                               errors={errors} placeholder={'Employee Name'} options={{required: true}}/>

                    <TextInput fixedValues={fixedValues} name={'nic'} type={'text'} label={'NIC'} register={register}
                               errors={errors} placeholder={'NIC'} options={{required: true}}/>

                    <TextInput fixedValues={fixedValues} name={'contact_number'} type={'text'} label={'Contact Number'}
                               register={register}
                               errors={errors} placeholder={'Contact Number'} options={{required: true}}/>

                    <TextInput fixedValues={fixedValues} name={'address'} type={'text'} label={'Address'}
                               register={register}
                               errors={errors} placeholder={'Address'} options={{required: true}}/>
                </FormGroup>
                <FormGroup>
                    <Btn attrBtn={{
                        className: 'd-block w-100 mt-4',
                        color: 'primary',
                        type: 'submit'
                    }}>{employee ? 'Update' : 'Create'}</Btn>
                </FormGroup>
            </Form>
        </Fragment>
    );
};

export default EmployeeForm;
