import {MemberType} from "../../../../../Data/Types/Application/ApplicationEmployeeDataTypes";
import {Card, CardBody, Col, Row} from "reactstrap";
import {H5, H6, Image} from "../../../../../AbstractElements";
import SvgIcon from "../../../../../Components/Common/Component/SvgIcon";
import {Link} from "react-router-dom";
import React from "react";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";

interface simpleMemberViewProps {
    member: MemberType
}

const SimpleMemberView = ({member}: simpleMemberViewProps) => {
    return (<Card className='social-profile'>
        <CardBody>
            <div className='social-img-wrap'>
                <div className='social-img'>
                    <Image attrImage={{src: require(`../../../../../assets/images/avtar/3.jpg`), alt: 'profile'}}/>
                </div>
                <div className='edit-icon'>
                    <SvgIcon iconId={'profile-check'}/>
                </div>
            </div>
            <div className='social-details'>
                <H5 attrH5={{className: 'mb-1'}}>
                    <Link to={`${getApplicationRoute('customer')}/${member.id}`}>{member.name}</Link>
                </H5>
                <div className='info'>
                    <Row className='step3' data-intro='This is the your details'>
                        <Col sm='12' lg='12' className='order-sm-1 order-xl-0'>
                            <Row>
                                <Col md='12' sm='12' className={'mb-4'}>
                                    <div className='ttl-info text-center ttl-sm-mb-0'>
                                        <H6>
                                            <i className='fa fa-id-card me-2'></i>
                                            NIC
                                        </H6>
                                        <span>{member.nic}</span>
                                    </div>
                                </Col>
                                <Col md='12' sm='12' className={'mb-4'}>
                                    <div className='ttl-info text-center'>
                                        <H6>
                                            <i className='fa fa-envelope me-2'></i> Email
                                        </H6>
                                        <span>{member?.user?.email}</span>
                                    </div>
                                </Col>
                                <Col md='12' sm='12' className={'mb-4'}>
                                    <div className='ttl-info text-center ttl-xs-mt'>
                                        <H6>
                                            <i className='fa fa-phone me-2'></i>
                                            Phone
                                        </H6>
                                        <span>{member?.phone ? member?.phone : '-'}</span>
                                    </div>
                                </Col>
                                <Col md='12' sm='12' className={'mb-4'}>
                                    <div className='ttl-info text-center ttl-sm-mb-0'>
                                        <H6>
                                            <i className='fa fa-location-arrow me-2'></i>
                                            Address
                                        </H6>
                                        <span>{member?.address ? member?.address : '-'}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </CardBody>
    </Card>);
}
export default SimpleMemberView;