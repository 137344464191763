import React, {useEffect, useRef, useState} from 'react';
import {CreditOfficerType} from "../../../../../Data/Types/Application/ApplicationOrganizationDataTypes";
import {EmployeeType} from "../../../../../Data/Types/Application/ApplicationEmployeeDataTypes";
import {TableViewType} from "../../../../../Data/Types/UtilityTypes";
import {Btn, H5} from "../../../../../AbstractElements";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import ButtonIcon from "../../../../../CommonElements/ButtonIcon";
import SearchOptions from "../../../../CommonComponents/Search";
import CreditOfficerTable from "../../../Organization/CreditOfficer/Components/CreditOfficerTable";
import CreditOfficerForm from "../../../Organization/CreditOfficer/Components/Form";

interface creditOfficerProps extends TableViewType {
    collector: EmployeeType
}

const EmployeeCreditOfficer = ({collector}: creditOfficerProps) => {
    const [creditOfficerModalVisibility, setCreditOfficerModalVisibility] = useState(false);
    const [searchVisibility, setSearchVisibility] = useState<boolean>(false);

    const searchOptions = [
        {name: "center_name", label: "Center Name"}
    ];
    const creditOfficerTableRef = useRef();

    const creditOfficerModalToggle = () => {
        setCreditOfficerModalVisibility(!creditOfficerModalVisibility);
    };

    const getCreditOfficers = (params: any) => {
        if (creditOfficerTableRef.current) {
            creditOfficerTableRef.current?.getCreditOfficers(params);
        }
    };

    const addCreditOfficer = (creditOfficer: CreditOfficerType) => {
        if (creditOfficerTableRef.current) {
            creditOfficerTableRef.current?.addCreditOfficer(creditOfficer);
        }
    };

    useEffect(() => {
        getCreditOfficers({});
    }, []);

    return (
        <div>
            <Row>
                <Col md={12} className="d-flex align-items-center justify-content-between">
                    <H5>{collector?.name}'s Centers</H5>
                    <div>
                        <div className="d-flex align-items-center gap-2">
                            <ButtonIcon
                                attrBtn={{onClick: () => setCreditOfficerModalVisibility(true)}}
                                type={"add"}
                            />
                            <ButtonIcon
                                attrBtn={{onClick: getCreditOfficers}}
                                type={"sync"}
                            />
                            <ButtonIcon
                                attrBtn={{onClick: () => setSearchVisibility(!searchVisibility)}}
                                type={"search"}
                            />
                        </div>
                    </div>
                </Col>
                <Col md={12}>
                    {searchVisibility && (
                        <SearchOptions
                            callBack={(params: any) => getCreditOfficers(params)}
                            searchOptions={searchOptions}
                        />
                    )}
                    <CreditOfficerTable
                        ref={creditOfficerTableRef}
                        requestParams={{collector_id: collector?.id}}
                        columnsToHide={{credit_officer: true, credit_officer_nic: true}}
                    />
                </Col>
            </Row>
            <Modal isOpen={creditOfficerModalVisibility} toggle={creditOfficerModalToggle} centered>
                <ModalHeader>Assign {collector?.name} to a Center</ModalHeader>
                <ModalBody>
                    <CreditOfficerForm
                        fixedValues={{collector_id: collector?.id}}
                        callBack={(creditOfficer: CreditOfficerType) => {
                            addCreditOfficer(creditOfficer);
                            creditOfficerModalToggle();
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{color: "primary", onClick: creditOfficerModalToggle}}>
                        Close
                    </Btn>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EmployeeCreditOfficer;
