import React, {Fragment, useEffect, useState} from 'react';
import {Form, FormGroup} from 'reactstrap';
import {useForm} from "react-hook-form";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {Btn} from "../../../../../AbstractElements";
import TextInput from "../../../../../CommonElements/Input/TextInput";
import Loader from "../../../../../Layout/Loader";
import axiosConfig from "../../../../../Config/axios";
import BackendDropDown from "../../../../../CommonElements/DropDown/BackendDropDown";
import {
    InstallmentCollectorType,
    InstallmentType
} from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";

interface installmentCollectorFormProps {
    installmentCollector?: InstallmentCollectorType,
    installment?: InstallmentType,
    fixedValues?: any,
    callBack?: (installmentCollector: InstallmentCollectorType) => void,
}

const InstallmentCollectorForm = ({
                                      callBack,
                                      installmentCollector,
                                      fixedValues,
                                      installment
                                  }: installmentCollectorFormProps) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: {errors},
        setValue,
    } = useForm();

    useEffect(() => {
        if (installmentCollector) {
            setValue('installment_id', installmentCollector.installment?.id);
            setValue('collector_id', installmentCollector.collector?.id);
            setValue('paid_date', installmentCollector.paid_date);
            setValue('amount', installmentCollector.amount);
        }
    }, [installmentCollector]);

    useEffect(() => {
        if (installment) {
            setValue('installment_id', installment.id);
            setValue('paid_date', installment.payment_date);
            setValue('amount', (installment.installment_amount + installment.interest_amount));
        }
    }, [installment]);

    const onSubmit = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        installmentCollector ? updateInstallmentCollector(data) : createInstallmentCollector(data);
    };

    const createInstallmentCollector = (data: any) => {
        clearErrors();
        axiosConfig
            .post(`/application/installment-collector`, {...data, ...fixedValues})
            .then((response: { data: { data: InstallmentCollectorType }; }) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    };

    const updateInstallmentCollector = (data: any) => {
        clearErrors();
        setLoaderVisibility(true);
        axiosConfig
            .put(`/application/installment-collector/${installmentCollector?.id}`, {...data, ...fixedValues})
            .then((response: { data: { data: InstallmentCollectorType }; }) => {
                if (response?.data?.data) {
                    callBack && callBack(response?.data?.data);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception, setError);
                setLoaderVisibility(false);
            });
    };

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Loader visibility={loaderVisibility}/>
                <BackendDropDown fixedValues={fixedValues} name={'installment_id'} type={'select-url'}
                                 label={'Installment Amount'}
                                 nameColumn={'installment_amount'}
                                 setValue={setValue}
                                 errors={errors} url={'application/installment'} options={{required: true}}/>
                <BackendDropDown fixedValues={fixedValues} name={'collector_id'} type={'select-url'} label={'Collector'}
                                 nameColumn={'nic'}
                                 setValue={setValue}
                                 errors={errors} url={'application/employee'} options={{required: true}}/>
                <TextInput fixedValues={fixedValues} name={'paid_date'} type={'date'} label={'Paid Date'}
                           register={register}
                           errors={errors} placeholder={'Paid Date'} options={{required: true}}/>
                <TextInput fixedValues={fixedValues} name={'amount'} type={'number'} label={'Amount'}
                           register={register}
                           errors={errors} placeholder={'amount'} options={{required: true}}/>
                <FormGroup>
                    <Btn attrBtn={{
                        className: 'd-block w-100 mt-4',
                        color: 'primary',
                        type: 'submit'
                    }}>{installmentCollector ? 'Update' : 'Create'}</Btn>
                </FormGroup>
            </Form>
        </Fragment>
    );
};

export default InstallmentCollectorForm;
