import React, {Fragment, useEffect, useState} from "react";
import Loader from "../../../../../Layout/Loader";
import {TableViewType} from "../../../../../Data/Types/UtilityTypes";
import axiosConfig from "../../../../../Config/axios";
import {ErrorHandler} from "../../../../../_helper/SystemCore/SystemCoreHelpers";
import {getApplicationRoute} from "../../../../../Route/RouteIndex";
import {LoanType} from "../../../../../Data/Types/Application/ApplicationLoanDataTypes";
import {currencyConfig} from "../../../../../Config/ApplicationConfig";
import IconBtn from "../../../../../CommonElements/IconButton";
import DataTable from "react-data-table-component";

const LoanTable = React.forwardRef(({
                                        requestParams = {},
                                        actionsToHide = {},
                                        columnsToHide = {}
                                    }: TableViewType, ref) => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [loans, setLoans] = useState<LoanType[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    React.useImperativeHandle(ref, () => ({
        getLoans
    }));

    useEffect(() => {
        getLoans({page: currentPage, perPage});
    }, [currentPage, perPage]);

    const getLoans = (searchParams = {}) => {
        setLoaderVisibility(true);
        const config = {params: {...searchParams, ...requestParams, page: currentPage, perPage}};
        axiosConfig
            .get(getApplicationRoute('loan'), config)
            .then((response: { data: { data: Array<LoanType>; total: number } }) => {
                if (response?.data?.data) {
                    setLoans(response.data.data);
                    setTotalRows(response.data.total);
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage: number) => {
        setPerPage(newPerPage);
        setCurrentPage(1);
    };

    const deleteLoan = (id: any) => {
        setLoaderVisibility(true);
        axiosConfig
            .delete(`/application/loan/${id}`, {})
            .then((response: { data: { data: { id: any; } } }) => {
                if (response?.data?.data) {
                    setLoans(current => current.filter(user => user.id !== response?.data?.data?.id));
                }
                setLoaderVisibility(false);
            })
            .catch((exception: any) => {
                ErrorHandler(exception);
                setLoaderVisibility(false);
            });
    };

    const loanTableColumns = [
        {
            name: 'Customer',
            selector: (loan: LoanType) => loan?.member?.name,
            sortable: false,
        },
        {
            name: 'Credit Officer',
            selector: (loan: LoanType) => loan?.center_collector?.name,
            sortable: false,
        },
        {
            name: 'Loan',
            selector: (loan: LoanType) => (loan?.amount + loan?.interest)?.toLocaleString('en-LK', currencyConfig),
            sortable: false,
        },
        {
            name: 'Status',
            selector: (loan: LoanType) => loan?.status,
            sortable: true,
            center: true,
        },
        {
            name: 'Option',
            selector: (loan: LoanType) => (
                <div className='d-flex align-items-right gap-2'>
                    {!actionsToHide.view ?
                        <IconBtn link={`${getApplicationRoute('loan')}/${loan.id}`} type={'view'}/> : null}
                    {
                        !actionsToHide.delete ?
                            <IconBtn confirm={true} onClick={() => deleteLoan(loan?.id)} type={'delete'}/> : null
                    }
                </div>
            ),
            sortable: false,
            right: true,
        },
    ];

    const checkColumnVisibility = (columns: any[] = []) => {
        return columns.filter((column) => !columnsToHide[column.name.toLowerCase().replace(/\s+/g, '_')]);
    };

    return (
        <Fragment>
            <Loader visibility={loaderVisibility}/>
            <DataTable
                pagination
                paginationServer
                columns={checkColumnVisibility(loanTableColumns)}
                data={loans}
                highlightOnHover={true}
                striped={true}
                responsive={true}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationPerPage={perPage}
            />
        </Fragment>
    );
});

export default LoanTable;
