import React, {Fragment, useState} from 'react';
import {Btn, H4, P} from '../../../../AbstractElements';
import {Form, FormGroup, Label} from 'reactstrap';
import {Link} from 'react-router-dom';
import axiosConfig from '../../../../Config/axios';
import {useForm} from "react-hook-form";
import TextInput from "../../../../CommonElements/Input/TextInput";
import Loader from "../../../../Layout/Loader";
import {ErrorHandler} from "../../../../_helper/SystemCore/SystemCoreHelpers";
import {ToastContainer} from "react-toastify";

const LoginForm = () => {
    const [togglePassword, setTogglePassword] = useState(false);
    const [loaderVisibility, setLoaderVisibility] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors},
    } = useForm();

    const onSubmit = (data) => {
        setLoaderVisibility(true);
        localStorage.removeItem("authenticated");
        localStorage.removeItem("authenticated_user");
        localStorage.removeItem("authenticated_token");
        axiosConfig
            .post(`/authentication/login`, data)
            .then(response => {
                if (response?.data?.data) {
                    localStorage.setItem("authenticated_user", JSON.stringify(response.data.data.user));
                    localStorage.setItem("authenticated_token", response.data.data.token);
                    document.location.href = '/';
                }
                setLoaderVisibility(false);
            })
            .catch(exception => {
                setLoaderVisibility(false);
                ErrorHandler(exception, setError);
            });
    }
    return (
        <Fragment>
            <div className='login-card'>
                <div className='login-main'>
                    <Loader visibility={loaderVisibility}/>
                    <Form onSubmit={handleSubmit(onSubmit)}
                          className='theme-form login-form'>
                        <H4>Sign in to account</H4>
                        <P>Enter your email & password to login</P>
                        <TextInput name={'email'} type={'email'} label={'Email Address'} register={register}
                                   errors={errors} placeholder={'Test@gmail.com'} options={{required: true}}/>
                        <FormGroup className='position-relative'>
                            <Label className='col-form-label m-0'>Password</Label>
                            <div className='position-relative'>
                                <input className='form-control' type={togglePassword ? 'text' : 'password'}
                                       name={'password'} {...register('password', {required: true})}
                                       placeholder='*********'/>
                                <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                                    <span className={togglePassword ? '' : 'show'}></span>
                                </div>
                            </div>
                            <span className='d-block'>{errors.password && errors.password.message}</span>
                            <div className='invalid-feedback'>{'Please provide a valid password'}</div>
                        </FormGroup>
                        <FormGroup className='position-relative'>
                            <div className='checkbox'>
                                <input id='remember_me' type='checkbox'
                                       name={'remember_me'} {...register('remember_me')}/>
                                <Label className='text-muted' for='remember_me'>
                                    Remember password
                                </Label>
                            </div>
                            <Link className='link' to={`${process.env.PUBLIC_URL}/forget-password`}>
                                Forgot password?
                            </Link>
                        </FormGroup>
                        <FormGroup>
                            <Btn attrBtn={{className: 'd-block w-100 mt-2', color: 'primary', type: 'submit',}}>Sign
                                in</Btn>
                        </FormGroup>
                    </Form>
                </div>
                <ToastContainer/>
            </div>
        </Fragment>
    );
};

export default LoginForm;
